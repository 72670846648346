<template>
	<v-app id="app">
		<v-container style="margin-top: 140px" fluid>
			<v-row justify="center">
				<v-col cols="4">
					<v-card outlined shaped>
						<center class="mt-3">
							<v-img :width="250" :src="require('@/assets/flirttool.jpeg')"></v-img>
						</center>
						<center>
							<v-col lg="8" cols="12">
								<v-alert
									:type="messageType"
									v-if="showMsg"
									outlined
									style="border-radius: 0px"
									v-text="message"
								></v-alert>
							</v-col>
							<v-form v-model="valid" ref="form">
								<v-col lg="8" cols="12">
									<v-col lg="12" style="padding: 0px">
										<v-text-field
											color="#eb79fc"
											outlined
											:rules="usernameRules"
											v-model="authModel.username"
											filled
											class="my-3"
											label="Enter Username or PIN"
											@keydown.enter="validate"
										>
										</v-text-field>
									</v-col>
									<v-col style="padding: 0px">
										<v-text-field
											outlined
											filled
											class="my-3"
											type="password"
											:rules="passwordRules"
											v-model="authModel.password"
											label="Enter password"
											@keydown.enter="validate"
										>
										</v-text-field>
									</v-col>
									<v-col style="padding: 0px" class="mb-5">
										<v-btn
											class="my-5"
											@click="validate"
											block
											large
											:dark="valid"
											depressed
											:disabled="!valid"
											:loading="loadingLogin"
											color="#eb79fc"
											@keydown.enter="validate"
											>{{$t('Login')}}</v-btn
										>
									</v-col>
								</v-col>
							</v-form>
						</center>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-app>
</template>
<script>
import { MOD_QUERY } from '../queries/core'
import  { reconnectSocket } from '../v-apollo'
import axios from 'axios';
export default {
	async created() {
		let { data}  = await axios.post('https://mazutech.online/api/system/dating-communities-public')
		this.datingCommunities = data	
	},
	data: () => ({
		message: "asdf",
		messageType: "error",
		showMsg: false,
		valid: true,
		usernameRules: [
			(v) => !!v || "Username is required",
			(v) =>
				(v && v.length <= 20) || "Username must be less than 20 characters",
		],
		passwordRules: [
			(v) => !!v || "Password is required",
			(v) => (v && v.length >= 8) || "Name must be atleast 8 characters",
		],
		authModel: {
			username: "",
			password: "",
		},
		datingCommunities:[],
		loadingLogin: false,
	}),
	methods: {
		validate() {
			if (this.$refs.form.validate()) {
				this.runLogin();
			}
		},
		runLogin() {
			this.loadingLogin = true;
			this.$apollo.mutate({
				mutation: MOD_QUERY,
				variables: {
					password: this.authModel.password,
					pin: this.authModel.username,
					datingCommunity:'flirttool.com'
				}
			}).then(({ data }) => {
				let redirectRoute = "/dashboard";
				const { moderator: { active, accountType, pin ,id ,warningAck }, token , domain } = data.modLogin
				setTimeout(() => {
					// decicde the redirection route for the admins
					if (!active) {
						this.$router.push("/blocked");
						return;
					}
					// switch (accountType) {
					// 	case "agent":
					// 		redirectRoute = "/chat-page/";
					// 		break;
					// 	case "admin":
					// 		redirectRoute = "/chat-analysis/";
					// 		break;
					// 	case "pm":
					// 		redirectRoute = "/project_management/";
					// 		break;
					// 	case "fkm":
					// 		redirectRoute = "/fake-accounts/";
					// 		break;
					// 	case "tl":
					// 		redirectRoute = "/fake-accounts/";
					// 	break;

					// 	default:
					// 		redirectRoute = "/panel/";
					// 		break;
					// }
					this.$store.commit("setModId", id)
					this.$store.commit("setModPin", pin)
					this.$store.commit("warningAck", warningAck)
					this.$store.commit("newLogin", { active, accountType });
					this.$store.commit("setFlirtTok", token)
					this.$store.commit('setCommunityDomain',domain)
				reconnectSocket()
					// this.showMessage("success", data.message);
					// console.warn(data);
					// window.location.replace(redirectRoute)
					this.$router.push(redirectRoute);
				}, 300);

			}).catch((e) => {
				console.error(e)
				// const errorResponse = e["response"];
				// const errorMessage = errorResponse.data.message;
				this.showMessage("error", "Error logging you in");
			})
				.finally(() => {
					this.loadingLogin = false;
				});


			// axios
			// 	.post(authEndPt, this.authModel)
			// 	.then(({ data }) => {
			// 		let redirectRoute = "/panel/";
			// 		setTimeout(() => {
			// 			// decicde the redirection route for the admins
			// 			if (data.stat == "1") {
			// 				this.$router.push("/blocked");
			// 				return;
			// 			}
			// 			switch (data.payload) {
			// 				case "mod":
			// 					redirectRoute = "/chat-page/";
			// 					break;
			// 				case "adm":
			// 					redirectRoute = "/chat-analysis/";
			// 					break;
			// 				case "prm":
			// 					redirectRoute = "/project_management/";
			// 					break;

			// 				default:
			// 					redirectRoute = "/panel/";
			// 					break;
			// 			}
			// 			this.$store.commit("newLogin", data.payload);
			// 			this.showMessage("success", data.message);
			// 			console.warn(data);
			// 			localStorage.setItem("au123", atob(data.cookies.JWT));
			// 			this.$router.push(redirectRoute);
			// 		}, 300);
			// 	})
			// .catch((e) => {
			// 	const errorResponse = e["response"];
			// 	const errorMessage = errorResponse.data.message;
			// 	this.showMessage("error", errorMessage);
			// })
			// .finally(() => {
			// 	this.loadingLogin = false;
			// });
		},
		showMessage(type, message) {
			this.messageType = type;
			this.message = message;
			this.showMsg = true;
		},
	},
};
</script>
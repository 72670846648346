<template>
  <!-- App.vue -->

  <v-app>
    <!-- start dialog -->
    <!-- end of dlialog -->
    <v-navigation-drawer
      app
      :mini-variant="!drawer"
      floating
      class="sidebar"
      color="grey darken-4"
      dark
    >
      <v-card
        flat
        style="border-radius: 0px"
        color="blue darken-1"
        height="65"
        dark
      >
        <center v-if="drawer">
          <v-img :width="160" :src="require('@/assets/dmi.png')"></v-img>
        </center>
      </v-card>
      <v-sheet color="#3d3c3c">
        <v-row class="mx-2" dense>
          <v-avatar class="mt-7" :size="20" color="green"> </v-avatar>
          <div
            class="text-left px-4 pt-4"
            style="font-size: 10px"
            v-if="drawer"
          >
            <p style="color: white; font-size: 20px">
              12AmenAbe
              <br />
              <span style="font-size: 14px"> bdere12345@gmail.com </span>
            </p>
          </div>
        </v-row>
      </v-sheet>
      <v-list class="text-start mt-3" dense style="overflow-y: hidden" nav>
        <v-list-item-subtitle class="py-3" style="color: grey">
          Chats
        </v-list-item-subtitle>
        <v-list-item-group v-model="model" mandatory color="white">
          <v-list-item
            style="text-decoration: none"
            link
            :to="item.to"
            v-for="(item, i) in items"
            :ripple="false"
            :key="i"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title v-text="item.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>

      <!-- -->
    </v-navigation-drawer>

    <v-app-bar app height="65" flat color="blue darken-1" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-btn @click="dialog = true">Create Modertors</v-btn>
      <v-badge overlap color="green darken-2" content="6">
        <v-btn icon large class="mx-2">
          <v-icon>mdi-email-open</v-icon>
        </v-btn>
      </v-badge>
      <v-badge overlap color="green darken-2" content="1">
        <v-btn icon large class="mx-2">
          <v-icon>mdi-arrow-right-bold-circle</v-icon>
        </v-btn>
      </v-badge>
      <v-badge overlap color="green darken-2" content="16">
        <v-btn icon large class="mx-2">
          <v-icon>mdi-arrow-left-bold-circle</v-icon>
        </v-btn>
      </v-badge>
      <v-btn icon large class="mx-2">
        <v-icon> mdi-account-settings </v-icon>
      </v-btn>
      <v-btn icon large class="mx-2">
        <v-icon> mdi-power </v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <v-row justify="center">
        <v-dialog v-model="dialog" persistent max-width="600px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{$t('UserProfile')}}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      v-model="username"
                      label="Email*"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      outlined
                      v-model="pass"
                      label="Password*"
                      type="password"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialog = false">
                Close
              </v-btn>
              <v-btn @click="newModerator" color="blue darken-1" text>
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <!-- Provides the application the proper gutter -->
      <v-container fluid class="mt-4">
        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      dialog: false,
      drawer: true,
      pass: "",
      username: "",
      items: [
        {
          icon: "mdi-chat",
          text: this.$t('ModeratorsManagement'),
          to: "/chat-analysis/",
        },
        // {
        //   icon: "mdi-bluetooth",
        //   text: "Bluetooth",
        //   to: "/chat-analysis2/",
        // },
        // {
        //   icon: "mdi-chart-donut",
        //   text: "Data Usage",
        //   to: "/chat-analysis/",
        // },
      ],
      model: 1,
      model2: 2,
    };
  },
  methods: {
    newModerator() {
      const url = "https://flirttool.com/aj/moderation/generate_moderator_pin";
      axios
        .post(url, {
          username: this.username,
          password: this.pass,
          c_password: this.pass,
        })
        .then(({ data }) => {
          console.log(data);
          this.dialog = false;
        })
        .catch((e) => {
          console.error(e);
          this.dialog = false;
        });
    },
  },
};
</script>
<style scoped>
.v-navigation-drawer__content {
  overflow-y: hidden;
}
.v-badge .v-badge__badge::after {
  border-color: #ffffff;
}
</style>
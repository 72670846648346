<template>
  <v-container fluid>
    <PageLoaderVue
      v-if="$apollo.loading"
    />
    <p>Active VIP/Pools dialog : <b>{{getPoolsInformation.length}}</b></p>
    <v-btn @click="refreshPools()">Refresh</v-btn>

    <template>
      <v-data-table :items="getPoolsInformation" :items-per-page="50" :headers="headers">
        <template v-slot:[`item.locked_mod`]="{ item }">
          <span style="color:green" v-if="item.locked_mod"> {{item.locked_mod.pin}}</span>
          <span style="color:red" v-else>No mod locked</span>
        </template>
      </v-data-table>
    </template>
  </v-container>
</template>
<script>
/* eslint-disable */
import { GET_POOL_INFORMATION } from "../queries/core";
import PageLoaderVue from './PageLoader.vue';
export default {
  components:{
    PageLoaderVue
  },
  data() {
    return {
      getPoolsInformation: [],
      headers: [
        { text: "User1", value: "m_one.username" },
        { text: "User2", value: "m_two.username" },
        { text: "User2 lang", value: "m_one.language" },
        { text: "Locaked Moderator", value: "locked_mod" },

      ],
      pools: [
        {
          m_one: "Frozen Yogurt",
          calories: 159,
        },
        {
          m_one: "Ice cream sandwich",
          calories: 237,
        },
      ],
    };
  },
  apollo: {
    getPoolsInformation: {
      query: GET_POOL_INFORMATION,
    },
  },
  methods: {
    refreshPools() {
      this.$apollo.queries.getPoolsInformation.refetch()
    }
  }
};
</script>

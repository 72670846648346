<template>
  <v-row justify="center">
   
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="70%"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <!-- <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn> -->
         <v-icon
         color="primary"
          dark
          v-bind="attrs"
          v-on="on"
         > mdi-heart-box </v-icon>
      </template>
      <v-card>
        <v-card-title>Select Nude Category</v-card-title>
         
        <v-divider></v-divider>
        <v-card-text style="height: 500px;">
          <template>
            <v-row justify="center">
              <!-- <v-btn
                class="white--text"
                color="teal"
                @click="overlay = !overlay"
              >
                {{$t('ShowOverlay')}}
              </v-btn> -->

              <v-overlay
                :z-index="zIndex"
                :value="overlay"
                :opacity='.90'
              >
                <v-img
                v-if="selectedImage"
                :src="`${baseUrl}${selectedImage.url}`"
                :height = "500"
                :width="700"
                >
              </v-img>
               <v-card
                flat
                class="col chat-foot"
               
              >
                <v-row
                  :class="{
                    'mt-0': $vuetify.breakpoint.smAndUp,
                  }"
                >
                <v-text-field
                filled
                dense
                color="amber darken-2"
                v-model="message"
              ></v-text-field>
               <v-menu :close-on-content-click="false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      :disabled="
                        !($store.state.loadedTargetProfileStatus == 'loaded')
                      "
                      v-if="$vuetify.breakpoint.smAndUp"
                      icon
                      large
                      outlined
                      text
                      color="amber accent-4"
                      class="mx-"
                      :ripple="false"
                    >
                      <v-icon>mdi-emoticon</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <VEmojiPicker
                      v-show="showEmojis"
                      :style="{ width: '440px', height: '200' }"
                      labelSearch="Search"
                      lang="pt-BR"
                      @select="onSelectEmoji"
                    />
                  </v-list>
                </v-menu>
              </v-row>
            </v-card>
              <br/>
              <v-btn
                class="white--text"
                color="teal"
                @click="sendNude"
                :width="300"
              >
                Send nude
              </v-btn>
               <v-btn
                class="white--text float-right"
                color="teal"
                @click="overlay = false"
                :width="300"
              >
                {{$t('Cancel')}}
              </v-btn>
            </v-overlay>
            </v-row>
          </template>
           <v-row>
            <v-col>
              <v-radio-group
                v-model="dialogm1"
                @change = "selectCategory"
                column
              >
                <v-radio v-for="n in categories"
                :key="n.category.id"
                  :label="n.category.category"
                  :value="n.category"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col>
               <v-row style="height:500px;overflow:scroll">
                  <v-col
                    v-for="n in images"
                    :key="n.id"
                    class="d-flex child-flex"
                    cols="4"
                    >
                 <v-hover v-slot="{ hover }"   open-delay="200">
                <v-img
                 :src="`${baseUrl}${n.url}`"
                  :lazy-src="`${baseUrl}${n.url}`"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  :height = "150"
                  :width="300"
                  >
                  <v-overlay absolute v-if="hover">
                      <v-btn color="green" @click="selectNude(n)">
                        <v-icon>mdi-check-circle-outline</v-icon>
                      </v-btn>
                    </v-overlay>
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                      >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                        ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                </v-hover>
              </v-col>
            </v-row>
            </v-col>
        </v-row>
          
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            {{$t('Close')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {  GET_SELLING_PIC_CATEGORY ,GET_SELLING_PICS } from "../queries/core";
import { VEmojiPicker } from "v-emoji-picker";

  export default {
    components:{
      VEmojiPicker
    },
    props:['bus'],
    created(){
      this.bus.$on('messageSent', () => {
         this.overlay = false
         this.dialog = false
         this.message = ''
         this.selectedImage= null
      });
    },
    data () {
      return {
        dialogm1: '',
        dialog: false,
        baseUrl:"https://flirttool.com/",
        // baseUrl:"http://localhost:8080/",
        categories:[],
        activeCategory:null,
        images:[],
        overlay: false,
        selectedImage:null,
        zIndex: 99999999,
        showEmojis:true,
        message: ''
      }
    },
   watch: {
		dialog(val) {
			if(val){
        this.getAllCategories()
      }
		},
  },
  methods: {
    sendNude() {
      if(this.message.length < 30) { 
        this.showToaster("you need to write atleast 30 characters", "error");
        return
      }
      let imageData = this.selectedImage
      imageData.message = this.message
      this.$emit("sendNudeImage",imageData);
    },
    selectNude(image) {
      this.selectedImage = image
      this.overlay = true
    },
    selectCategory(data) {
      this.dialogm1 = data
     this.getCategoryImages(data)
    },
    async getCategoryImages(category) {
      // this.loadingTableData  =true
      const { data, errors } = await this.$apollo.query({
            query: GET_SELLING_PICS,
            variables: {
              category:category.id
            },
            fetchPolicy: 'no-cache'
        });
      this.loadingTableData  = false
      if (!errors) {
        let images = data.getSellingImages
        this.activeCategory = category
        this.images = images
      }
    },
     async getAllCategories() {
    // this.loadingTableData  =true
    const { data, errors } = await this.$apollo.query({
          query: GET_SELLING_PIC_CATEGORY,
          fetchPolicy: 'no-cache'
      });
    // this.loadingTableData  = false
    if (!errors) {
        let getSellingPicCategory = data.getSellingPicCategory
        this.categories = getSellingPicCategory
        if(getSellingPicCategory.length) {
          this.selectCategory(getSellingPicCategory[0].category)
        }
      }
    },
    onSelectEmoji(emoji) {
      this.message += " " + emoji.data;
      // Optional
      // this.toogleDialogEmoji();
    },
    showToaster(message, type = "info") {
      const data = {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      };
      switch (type) {
        case "info":
          this.$toast.info(message, data);
          break;
        case "success":
          this.$toast.success(message, data);
          break;
        case "warning":
          this.$toast.warning(message, data);
          break;
        case "error":
          this.$toast.error(message, data);
          break;

        default:
          this.$toast.info(message, data);
          break;
      }
    },
  },
 
  }
</script>
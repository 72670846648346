<template>
  <div id="inspire">
    <v-navigation-drawer
      app
      :mini-variant="!drawer"
      floating
      class="sidebar"
      color="grey darken-4"
      dark
    >
      <v-card
        flat
        style="border-radius: 0px"
        color="blue darken-1"
        height="65"
        dark
      >
        <center v-if="drawer">
          <v-img :width="200"  src="https://mazutech.online/uploads/vbqgA.jpeg"></v-img>
        </center>
      </v-card>
      <v-sheet color="#3d3c3c" v-if="drawer">
        <v-row class="mx-2" dense>
          <v-avatar class="mt-7" :size="17" :color="statusColor"> </v-avatar>
          <div
            class="px-2 pt-3 text-left"
            style="font-size: 10px"
            v-if="drawer"
          >
            <p style="color: white; font-size: 17px" class="pt-3">
              {{ $store.state.modPin }} ({{$store.state.authP.accountType}})
            </p>
          </div>
        </v-row>
      </v-sheet>
      <v-list class="mt-3" style="overflow-y: hidden" :nav="drawer">
        <v-list-item-subtitle class="py-3" style="color: grey">
          <!-- Chats -->
        </v-list-item-subtitle>
        <v-list-item-group v-model="model" mandatorys color="blue">
          <AppSidebar :drawer="drawer" />
          <!-- <v-list-item
            style="text-decoration: none"
            link
            dark
            :to="item.to"
            v-for="(item, i) in items"
            :ripple="false"
            :key="i"
          >
            <v-col>
              <v-row dense :class="{ 'mx-2': drawer }">
                <v-icon :size="25" v-text="item.icon"></v-icon>
                <h6
                  v-if="drawer"
                  style="font-size: 16"
                  class="px-2 pt-3 font-weight-regular"
                >
                  {{ item.text }}
                </h6>
              </v-row>
            </v-col>
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
      <!-- -->
    </v-navigation-drawer>

    <v-app-bar
      v-if="analysis_data[0]"
      app
      height="65"
      flat
      color="blue darken-1"
      dark
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-row class="m-3">
        <v-btn
          outlined
          class="mx-3"
          elevation="0"
          style="font-weight: bold"
          @click="startChat"
          >{{ $t("startChatting") }}</v-btn
        >
        <div class="p-6 space-y-6">
          <!-- <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.
                </p> -->
          <!-- <label for="countries" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white font-sans uppercase text-center">Select a language</label> -->
          <select
            @change="changeLang"
            v-model="$store.state.lang"
            id="countries"
            class="bg-gray-50 border px-2 overflow font-sans border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option :value="null">No language selected</option>
            <option value="en">English</option>
            <option value="Turkish">Turkish</option>
            <option value="france">France</option>
            <option value="Deutsch">German</option>
            <option value="polish">Polish</option>
            <option value="Spanish">Spanish</option>
            <option value="Italia">Italia</option>

            <option value="portuguese">Portuguese</option>
            <option value="Schwedisch">Schwedisch</option>
            <option value="Danish">Danish</option>
            <option value="finisch">Finisch</option>
            <option value="Serbisch">Serbisch</option>
            <option value="russisch">Russisch</option>
            <option value="japanese">Japanese</option>
            <option value="koreanisch">Koreanisch</option>
            <option value="Thailändische">Thailändische</option>
            <option value="ungarisch">Ungarisch</option>
            <option value="arabic">Arabic</option>
            <!-- <option value="arz">Ägyptisch</option> -->

            <option value="irn">Iran</option>
            <!-- <option value="irn">Iran</option> -->
        </select>
        </div>
        <!-- <v-btn
        class="mx-3"
          outlined
          elevation="0"
          style="font-weight: bold"
          @click="pauseChat"
          >Pause</v-btn
        > -->
        <v-select
        style="width:10px;margin-left:20px"
          v-model="currentStatus"
          :hint="`${currentStatus.state}, ${currentStatus.abbr}`"
          :items="modStatus"
          item-text="state"
          item-value="abbr"
          label="Status : Production"
          persistent-hint
          v-on:change="(data) => setCurrentModStatus(data)"
          return-object
          single-line
        >
        </v-select>
      </v-row>
      <v-row class="m-3">
        <v-spacer></v-spacer>
        <v-btn class="d-none" @click="dialog = true">Create Modertors</v-btn>
        <!-- <v-btn class="mx-2" x-large color="pink darken-4"
          ><span class="px-5">VIP</span></v-btn
        > -->
        <v-btn
          :loading="$store.state.loadedTargetProfileStatus == 'loading'"
          @click="switchToVip"
          x-large
          :color="
            $store.state.queryFromPools === 'vip' ? 'pink darken-4' : 'white'
          "
          :class="{ 'white--text': $store.state.queryFromPools == 'vip' }"
          style="
            color: black;
            font-weight: bold;
            font-size: 13px;
            font-family: sa;
          "
          class="mx-2"
          ><span class="px-2" v-if="analysis_data[0]">VIP</span>({{
            $store.state.vipCount
          }})</v-btn
        >
        <v-btn
          :loading="$store.state.loadedTargetProfileStatus == 'loading'"
          @click="switchToPools"
          x-large
          :color="$store.state.queryFromPools === 'pool' ? '#093B67' : 'white'"
          :class="{ 'white--text': $store.state.queryFromPools == 'pool' }"
          style="
            color: black;
            font-weight: bold;
            font-size: 13px;
            font-family: sa;
          "
          class="mx-2"
          ><span class="px-2" v-if="analysis_data[0]">POOLS</span>({{
            $store.state.poolcount
          }})</v-btn>

          <v-btn
         
          @click="releasePool"
          x-large
          style="
            color: white;
            font-weight: bold;
            font-size: 13px;
            font-family: sa;
          "
          class="mx-2"
          >Release pool</v-btn>

        <v-chip dark large color="#093B67" style="border-radius: 4px"
          ><span class="px-2">€</span>
          {{ ` ${(analysis_data[0].ins * rate * 10) / 10}` }}</v-chip
        >
        <!-- <v-badge overlap color="#093B67" :content="0">
          <v-btn outlined icon color="#093B67" large class="mx-2">
            <v-icon>mdi-email-open</v-icon>
          </v-btn>
        </v-badge>
        <v-badge overlap color="#093B67" :content="analysis_data[0].outs">
          <v-btn icon outlined large color="#093B67" class="mx-2">
            <v-icon>mdi-arrow-right-bold-circle</v-icon>
          </v-btn>
        </v-badge>
        <v-badge overlap color="#093B67" :content="analysis_data[0].ins">
          <v-btn icon outlined color="#093B67" large class="mx-2">
            <v-icon>mdi-arrow-left-bold-circle</v-icon>
          </v-btn>
        </v-badge>
        <v-btn icon outlined color="#093B67" large class="mx-2 d-none">
          <v-icon> mdi-account-settings </v-icon>
        </v-btn> -->
        <v-btn icon large class="mx-2" @click="logout">
          <v-icon> mdi-power </v-icon>
        </v-btn>
      </v-row>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
/* eslint-disable */
import {
  AGENT_MOD_MESSAGES,
  LOGOUT,
  ACTIVE_ONLINE_MODS,
  CHANGE_ONLINE_MOD_STATUS,
  PANEL_TABS_SETTINGS,
  RELEASE_MODERATOR_POOL,
  GET_POOL_COUNT
} from "../queries/core";
import { disconnectSocket } from "../v-apollo";
import { getTabs, allRoutes } from "../utils/helper";
import AppSidebar from "../components/AppSidebar.vue";
export default {
  components: {
    AppSidebar,
  },
  name: "Home",
  apollo: {
    agentModMessages: {
      query: AGENT_MOD_MESSAGES,
      variables() {
        return {
          input: {
            dateFilter: this.dateFilter,
          },
        };
      },
      result({ data: { agentModMessages } }) {
        this.analysis_data = [agentModMessages];
        this.$store.commit(
          "netPoolCount",
          this.analysis_data[0].vipPoolCount + this.analysis_data[0].poolsCount
        );
        // this.$store.commit("netPoolCount",0)
      },
    },
    $subscribe: {
      onlineModsSubscription: {
        query: ACTIVE_ONLINE_MODS,
        result({ data: { onlineModsSubscription } }) {
          this.$store.commit(
            "setOnlineMods",
            onlineModsSubscription.online_mods
          );
          this.setCurrentStatus();
        },
      },
    },
  },
  data: () => ({
    nov: false,
    drawer: true,
    group: null,
    rate: 0.06,
    dateFilter: "today",
    desserts: [
      {
        name: "Frozen Yogurt",
        calories: 159,
      },
      {
        name: "Ice cream sandwich",
        calories: 237,
      },
      {
        name: "Eclair",
        calories: 262,
      },
      {
        name: "Cupcake",
        calories: 305,
      },
    ],
    analysis_data: [],
    notficationCount: 0,
    authKey: "",
    model: null,
    modStatus: [
      {
        state: "Screen Break",
        abbr: "sb",
      },
      {
        state: "Break",
        abbr: "b",
      },
      {
        state: "Meeting",
        abbr: "m",
      },
      {
        state: "1zu1 Instruction",
        abbr: "zu",
      },
      {
        state: "Workshop",
        abbr: "w",
      },
      // {
      //   state: "Production",
      //   abbr: 'p',
      // },
    ],
    currentStatus: {
      state: "Screen Break",
      abbr: "sb",
    },
    statusColor: "orange",
    items: [
      {
        icon: "mdi-chat",
        text: "Chat Moderation",
        to: "/chat-page/",
      },
    ],
  }),
  methods: {
    async releasePool() {
      try {
        const { data, errors } = await this.$apollo.mutate({
          mutation: RELEASE_MODERATOR_POOL,
        });
        this.$router.push('/dashboard') 
      } catch(e) {
        console.log(e)
      } 
    },
    changeLang($event) {
      this.$store.commit("setCurrentLang", $event.target.value);
      this.$i18n.locale = $event.target.value;
    },
    setCurrentModStatus(status) {
      this.$store.commit("setChatStart", false);
      this.$store.commit("setCurrentStatus", status.abbr);
    },
    setCurrentStatus() {
      // alert('dfg')
      let onlineMods = this.$store.getters.getOnlineMods;
      let modId = this.$store.getters.getModId;
      let modStatus = onlineMods.find((m) => Number(m.id) == Number(modId));
      if (!modStatus) return "calc";
      modStatus = modStatus.status;

      let currentStatus = "Screen Break";
      switch (modStatus) {
        case "p":
          currentStatus = "Production";
          this.statusColor = "green";
          break;
        case "sb":
          currentStatus = "Screen Break";
          this.statusColor = "orange";
          break;
        case "b":
          currentStatus = "Break";
          this.statusColor = "red";
          break;
        case "m":
          currentStatus = "Meeting";
          this.statusColor = "#A6C634";
          break;
        case "zu":
          currentStatus = "1zu1 Instruction";
          this.statusColor = "#A82AEC";
          break;
        case "w":
          currentStatus = "Workshop";
          this.statusColor = "#0281FD";
          break;
        default:
          currentStatus = "offline";
          break;
      }
      this.currentStatus = {
        state: currentStatus,
        abbr: modStatus,
      };
    },
    async changeModOnlineStatus() {
      let mod_status = this.$store.getters.getCurrentStatus;
      // alert('online changing',mod_status)

      const { data, errors } = await this.$apollo.query({
        query: CHANGE_ONLINE_MOD_STATUS,
        fetchPolicy: "network-only",
        variables: {
          status: mod_status,
        },
      });
      if (!errors) {
        console.log(data, "datadata");
      }
    },
    //   pause chat method
    pauseChat() {
      this.$store.commit("setChatStart", false);
      this.$store.commit("setCurrentStatus", "sb");
    },
    //   start chat method
    async startChat() {
      if (this.$route.name == "ChatPage") {
        try {
          let {data} = await this.$apollo
          .query({
            query: GET_POOL_COUNT,
            fetchPolicy: 'no-cache'
          })
          if(data.getPoolInfo){
            if(data.getPoolInfo.length) {
              let pools = data.getPoolInfo.filter(p => p.pool_type == 'pool')
              let vips = data.getPoolInfo.filter(p => p.pool_type != 'pool')
              this.$store.commit("poolcount", pools.length)
              this.$store.commit("vipCount", vips.length)
              this.$store.commit("netPoolCount", data.getPoolInfo.length)
              this.$store.commit("setChatStart", true);
              this.$store.commit("setCurrentStatus", "p");
            }
          }
        }catch(e) {
          console.log(e)
        }
      }
    },
    switchToPools() {
      this.switchPoolType('pool')
    },
    switchToVip() {
     this.switchPoolType('vip')
    },
    async switchPoolType(poolType,showMessage=true) {
      try {
        let {data} = await this.$apollo
        .query({
          query: GET_POOL_COUNT,
          fetchPolicy: 'no-cache'
        })
        if(data.getPoolInfo){
          let pools = data.getPoolInfo.filter(p => p.pool_type == 'pool')
          let vips = data.getPoolInfo.filter(p => p.pool_type != 'pool')
          this.$store.commit("poolcount", pools.length)
          this.$store.commit("vipCount", vips.length)
          this.$store.commit("netPoolCount", data.getPoolInfo.length)
          if(poolType === 'pool') {
            if(pools.length){ 
              showMessage && this.showToaster("Pools Updated and switched to Pools",'info')
              this.$store.commit("setQueryTarget", "pool")
            }else {
              showMessage &&  this.showToaster("Pools not available",'error')
            }
          }
          if(poolType === 'vip') {
            if(vips.length){ 
              this.showToaster("Vips Updated and switched to Vips",'info')
              this.$store.commit("setQueryTarget", "vip")
            }else {
              this.showToaster("Vips not available",'error')
            }
          }
        }
      }catch(e) {
        return e
        console.log(e)
      }
    },
    showToaster(message, type = "info") {
      const data = {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      };
      switch (type) {
        case "info":
          this.$toast.info(message, data);
          break;
        case "success":
          this.$toast.success(message, data);
          break;
        case "warning":
          this.$toast.warning(message, data);
          break;
        case "error":
          this.$toast.error(message, data);
          break;

        default:
          this.$toast.info(message, data);
          break;
      }
    },
    modeChanged() {
      const requestedSet = this.$store.state.requestedSet;
      // this.vipMode = !this.vipMode;
      this.$store.commit("resetState");
      if (requestedSet == "vip") {
        this.$store.commit("setRequestedSet", "nor");
      } else if (requestedSet == "nor") {
        this.$store.commit("setRequestedSet", "vip");
      }
    },
    logout() {
      const confirmExit = confirm("Are you sure you wanna log out ?");
      if (confirmExit) {
        this.$apollo
          .mutate({
            mutation: LOGOUT,
          })
          .finally(() => {
            let userType = this.$store.state.authP.accountType;
            window.localStorage.clear();
            disconnectSocket();
            this.$store.commit("setFlirtTok", "");
            this.$store.commit("setCurrentStatus", "sb");
            this.$store.commit("warningAck", null);

            this.$store.commit("resetState");
            this.$store.commit("setCurrentAgent", {});
            if (userType == "model") {
              this.$router.push("/modellogin");
            } else {
              this.$router.push("/");
            }
          });
      }
    },
    async getTabsSettings() {
      try {
        const { data } = await this.$apollo.query({
          query: PANEL_TABS_SETTINGS,
          fetchPolicy: "no-cache",
        });
        if (data.getPanelTabSettings) {
          let tabSettings = data.getPanelTabSettings;
          return tabSettings;
        }
      } catch (e) {
        throw Error(
          "Tab settings not found, Please ask admin to add functions"
        );
      }
    },
  },
  async created() {
    this.switchPoolType('pool',false)
    this.$store.commit("setChatStart", false);
    this.$store.commit("setCurrentStatus", "sb");
    this.$apollo.queries.agentModMessages.refetch();
    if (this.$store.getters.getCurrentStatus != "cb") {
      setTimeout(() => {
        this.changeModOnlineStatus();
      }, 2000);
    }
  },
  computed: {
    saveMessageSent() {
      return this.$store.getters.saveMessageSent;
    },
  },
  watch: {
    saveMessageSent(value) {
      console.log(value);
      this.$apollo.queries.agentModMessages.refetch();
    },
    "$store.state.currentStatus"() {
      this.changeModOnlineStatus();
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name != "ChatPage") {
      this.$store.commit("setCurrentStatus", "sb");
    }
    let chatStatus = this.$store.getters.getstartedChatting;
    if (to.name == "ChatPage" && chatStatus) {
      this.$store.commit("setCurrentStatus", "p");
    }
    next();
  },
};
</script>
<style>
html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ff0000;
}
.v-sheet.v-card {
  border-radius: 0px;
}
.col {
  padding: 0px;
}
</style>

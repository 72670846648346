<template>
 <v-container fluid>
      <v-app-bar flat :height="100">
        <!-- from date selector -->
        <v-avatar tile :size="60" class="mx-2">
          <v-img :src="require('@/assets/filter.png')"> </v-img>
        </v-avatar>
      </v-app-bar>
      <template>
        <div class="text-center">
          <v-dialog
          persistent
          v-if="activeCategory"
            v-model="fileDialog"
            width="800"
            >
            <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="red lighten-2"
                dark
                v-bind="attrs"
                v-on="on"
                >
               <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672ZM12 2.25V4.5m5.834.166-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243-1.59-1.59" />
</svg>

              </v-btn>
            </template> -->
            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                + {{ activeCategory.category.category}}
              </v-card-title>
              <v-card-text>
               <template>
                 <br>
                  <v-file-input
                    v-model="files"
                    color="deep-purple accent-4"
                    counter
                    label="File input"
                    multiple
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    outlined
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>

                      <span
                        v-else-if="index === 2"
                        class="text-overline grey--text text--darken-3 mx-2"
                      >
                        +{{ files.length - 2 }} File(s)
                      </span>
                    </template>
                  </v-file-input>
                </template>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="primary"
                  @click="()=> fileDialog = false"
                  text
                  v-if="!uploadingFiles"
                >
                  <p 
                    class="mr-8"
                  >
                  {{$t('Close')}}
                  </p>
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="uploadFiles"
                  >
                  <p v-if="!uploadingFiles">
                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 7.5h-.75A2.25 2.25 0 0 0 4.5 9.75v7.5a2.25 2.25 0 0 0 2.25 2.25h7.5a2.25 2.25 0 0 0 2.25-2.25v-7.5a2.25 2.25 0 0 0-2.25-2.25h-.75m0-3-3-3m0 0-3 3m3-3v11.25m6-2.25h.75a2.25 2.25 0 0 1 2.25 2.25v7.5a2.25 2.25 0 0 1-2.25 2.25h-7.5a2.25 2.25 0 0 1-2.25-2.25v-.75" />
</svg>

                  </p>
                   <v-progress-circular
                   v-else
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>
      <v-spacer></v-spacer>
      <br>
      <!-- stop of filter button group -->
      <v-row>
        <v-col cols="6"
          dense fixed app>
          <template>
            <v-data-table
              :headers="headers"
              :items="categories"
              :loading="loadingTableData"
              fixed-header
              sort-by="calories"
              class="elevation-1"
              >
              <template v-slot:top>
                <v-toolbar
                  flat
                  >
                  <v-toolbar-title>{{$t('SellingImages')}}</v-toolbar-title>
                  <v-divider
                    class="mx-4"
                    inset
                    vertical
                    ></v-divider>
                  <v-spacer></v-spacer>
                  <v-dialog
                    v-model="dialog"
                    max-width="500px"
                    persistent
                    >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                        >
                        New Category
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">New Category</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              sm="12"
                              md="12"
                              >
                              <v-text-field
                                v-model="newCategory"
                                label="Category name"
                                ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="close"
                          >
                          {{$t('Cancel')}}
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="addNewCategory"
                          >
                          {{$t('Save')}}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px" persistent>
                    <v-card>
                      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                        <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <!-- <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                  >
                  mdi-pencil
                  </v-icon> -->
                <v-icon
                  class="mr-2"
                  small
                  @click="deleteItem(item)"
                  >
                  mdi-delete
                </v-icon>
                <v-icon
                  small
                  class="mr-8"
                  :color = isItemActive(item)
                  @click="getCategoryImages(item)"
                  >mdi-eye
                </v-icon>
                <v-icon
                v-if="isItemActive(item) === 'green'"
                  :color = isItemActive(item)
                  medium
                  @click="openFileUploadDialogue(item)"
                    >
                  mdi-arrow-up-bold-box-outline
                </v-icon>
              </template>
            </v-data-table>
          </template>
        </v-col>
        <v-col>
          <template>
            <v-row style="height:650px;overflow:scroll">
              <v-col
                v-for="n in images"
                :key="n.id"
                class="d-flex child-flex"
                cols="4"
                >
                 <v-hover v-slot="{ hover }"   open-delay="200">
                <v-img
                  :src="`${baseUrl}${n.url}`"
                  :lazy-src="`${baseUrl}${n.url}`"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  :height = "150"
                  :width="300"
                  >
                  <v-overlay absolute v-if="hover">
                      <v-btn color="red" @click="deleteImage(n)">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-overlay>
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                      >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                        ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                </v-hover>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-container>
</template>
<script>

import {  GET_SELLING_PIC_CATEGORY ,
  ADD_SELLING_PIC_CATEGORY,
  DELETE_SELLING_PIC_CATEGORY,
  GET_SELLING_PICS,
  ADD_SELLING_PICS,
  DELETE_SELLING_PIC
} from "../queries/core";

export default {
   apollo: {
    getSellingPicCategory: {
      query: GET_SELLING_PIC_CATEGORY,
      result({ data: { getSellingPicCategory } }) {
        console.log(getSellingPicCategory,"getSellingPicCategory")
        this.categories = getSellingPicCategory
        this.loadingTableData = false
      },
    },
    // $subscribe :{
    //   onlineModsSubscription:{
    //     query: ACTIVE_ONLINE_MODS,
    //     result({ data: { onlineModsSubscription } }){
    //       this.$store.commit("setOnlineMods", onlineModsSubscription.online_mods);
    //       this.setCurrentStatus()
    //     }
    //   }
    // }
  },
  data: () => ({
     tab: null,
        items: [
          'web', 'shopping', 'videos', 'images', 'news',
        ],
        text: 'df.',
    uploadingFiles:false,
    loadingTableData:true,
    dialog: false,
    fileDialog:false,
    dialogDelete: false,
    todeleteItem:null,
    files:[],
    baseUrl:"https://flirttool.com/",
    headers: [
      {
        text: 'Category',
        align: 'start',
        sortable: false,
        value: 'category.category',
      },
      { text: 'Images', value: 'imageCount' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    categories:[],
    newCategory:'',
    activeCategory:null,
    images:[]
  }),

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    },
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    },
  },

  created () {
    this.initialize()
  },

  methods: {
    async addNewCategory(){
      if(this.newCategory.length < 2) return alert('Name should have at least 2 chars')

      const { data, errors } = await this.$apollo.mutate({
        mutation: ADD_SELLING_PIC_CATEGORY,
        variables: {
          category:this.newCategory
        },
    });
      if (!errors) {
        this.dialog = false
        this.newCategory=''
        this.$apollo.queries.getSellingPicCategory.refetch();
        console.log(data,"datadata")
      }
    },

    async getCategoryImages(category) {
      this.loadingTableData  =true
      const { data, errors } = await this.$apollo.query({
            query: GET_SELLING_PICS,
            variables: {
              category:category.category.id
            },
            fetchPolicy: 'no-cache'
        });
      this.loadingTableData  = false
      if (!errors) {
        let images = data.getSellingImages
        this.activeCategory = category
        this.images = images
      }
    },
    async openFileUploadDialogue(item) {
      await this.getCategoryImages(item)
      this.fileDialog = true
    },
    isItemActive(item) {
      if(!this.activeCategory) return 'grey'
      let color = item.category.id == this.activeCategory.category.id ? 'green' : 'grey'
      return color
    },
    async uploadFiles(){
      if(!this.files.length) return alert('Please add files')
      this.uploadingFiles = true;
      const { data, errors } = await this.$apollo.mutate({
        mutation: ADD_SELLING_PICS,
        variables: {
          category:Number(this.activeCategory.category.id),
          files:this.files
        },
       });
       this.uploadingFiles = false
      if (!errors) {
        console.log(data)
        data.addSellingImage.forEach(img => {
           this.images.push(img)
        });
           this.activeCategory.imageCount = this.activeCategory.imageCount +  data.addSellingImage.length

        // this.images.push(data.addSellingImage)
        this.fileDialog = false
        this.files = []
      }
      // this.fileDialog = false
    },
    async deleteImage(image){
      console.log(image)
       const { data, errors } = await this.$apollo.mutate({
        mutation: DELETE_SELLING_PIC,
        variables: {
          id:Number(image.id)
        },
       });
      if (!errors) {
       console.log(data)
        this.images = this.images.filter(x => x.id != image.id)
        this.activeCategory.imageCount = this.images.length
      }
    },
   initialize () {
      this.desserts = [
        {
          category: 'XXX',
          imageCount: 159,
        }
      ]
    },

   

    deleteItem (item) {
      this.todeleteItem = item
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      const { data, errors } = await this.$apollo.mutate({
          mutation: DELETE_SELLING_PIC_CATEGORY,
          variables: {
            id:Number(this.todeleteItem.category.id)
          },
      });
      if (!errors) {
        this.dialog = false
        this.todeleteItem = null
        this.images = []
        this.$apollo.queries.getSellingPicCategory.refetch();
        console.log(data,"datadata")
      }
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem)
      } else {
        this.desserts.push(this.editedItem)
      }
      this.close()
    },
  },
}
</script>
<style scoped>
.col{
  padding: 12px !important;
}
</style>
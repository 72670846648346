<template>
  <div>
    <div class="text-center">
      <v-dialog
        fullscreen
      :scrim="false"
      transition="dialog-bottom-transition"
        v-if="activeCategory"
        v-model="updateMediaBlockedCountries"
        width="800"
      >
      <v-card>
        <v-card-text>
          <v-col cols="12">
            <v-combobox
              v-model="blocked_in"
              :items="countries"
              label="Blocked Countries"
              multiple
              chips
            >
            </v-combobox>
            Video Blocked in following regions
            <v-list lines="three">
              <v-list-item
                v-for="item in blocked_in"
                :key="item"
              >
              <img style="width:50px;;margin:2px" :src="locations.find(l => l.country == item).flag">
                <b>{{ item }}</b>
                <a style="margin-left:40px;" href="#" @click.prevent="blocked_in = blocked_in.filter(sc => sc != item)">Remove</a>
              </v-list-item>
            </v-list>
          </v-col>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn
              :loading="loading"
              color="primary"
              @click="() => (updateMediaBlockedCountries = false)"
              text
              v-if="!uploadingFiles"
            >
              <p class="mr-8">close</p>
            </v-btn>
            <v-btn :loading="loading" color="primary" text @click="updateBlockedCountries">
              <p v-if="!uploadingFiles">Update Regions</p>
            </v-btn>
          </v-card-actions>
      
      </v-card>
      </v-dialog>
      <v-dialog
        persistent
        v-if="activeCategory"
        v-model="updateMediaDialog"
        width="800"
      >
        <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="red lighten-2"
                dark
                v-bind="attrs"
                v-on="on"
                >
                Click Me
              </v-btn>
            </template> -->
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            <!-- Add files to {{ activeCategory.category.category}} -->
          </v-card-title>
          <v-card-text>
            <template>
              <v-text-field
                type="text"
                v-model="title"
                label="Title"
                prepend-icon="fas fa-list"
                outlined
              ></v-text-field>
              <br />

              <v-text-field
                type="text"
                v-model="description"
                label="Description"
                prepend-icon="fas fa-list"
                outlined
              ></v-text-field>
              <br />
              <br />
              <v-text-field
                type="number"
                v-model="cost"
                label="Media cost"
                prepend-icon="fas fa-list"
                outlined
              ></v-text-field>
              <br />

              <v-switch
                prepend-icon="fas fa-list"
                v-model="is_paid"
                label="Is media paid"
              ></v-switch>
              <br />
              <br />

              <v-select
                prepend-icon="fas fa-list"
                v-model="mediaCategory"
                label="Media Category"
                :items="['Gay', 'Trans', 'Black', 'Straight', 'Asia', 'Couple']"
                variant="solo"
              ></v-select>
              <br />
              <br />

              <v-text-field
                type="text"
                v-model="tags"
                label="Tags add comma seperated"
                prepend-icon="fas fa-list"
                outlined
              ></v-text-field>
              <br />
              <v-file-input
                v-model="files"
                color="deep-purple accent-4"
                counter
                label="Media Preview"
                placeholder="Select your file"
                prepend-icon="mdi-paperclip"
                outlined
                :show-size="1000"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    v-if="index < 2"
                    color="deep-purple accent-4"
                    dark
                    label
                    small
                  >
                    {{ text }}
                  </v-chip>

                  <span
                    v-else-if="index === 2"
                    class="text-overline grey--text text--darken-3 mx-2"
                  >
                    +{{ files.length - 2 }} File(s)
                  </span>
                </template>
              </v-file-input>
              <v-progress-linear
                v-if="uploadingFiles"
                v-model="uploadProgress"
                color="blue-grey"
                height="25"
              >
                <template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
              <br />
              <v-btn @click="updatePreviewUrl" color="primary">
                <p class="mr-8">Add/Update preview url</p>
              </v-btn>
              <br /><br />
              <!-- <img :src="getURL(preview_url)"> -->
              <video
                style="height: 300px; width: 100%"
                autoplay=""
                loop=""
                muted=""
                preload="none"
              >
                <source :src="getURL(preview_url)" type="video/mp4" />
              </video>
              <br /><br />
              <br />
              <v-file-input
                v-model="thumbFile"
                color="deep-purple accent-4"
                counter
                label="Media Thunmnail"
                placeholder="Select your file"
                prepend-icon="mdi-paperclip"
                outlined
                :show-size="1000"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    v-if="index < 2"
                    color="deep-purple accent-4"
                    dark
                    label
                    small
                  >
                    {{ text }}
                  </v-chip>

                  <span
                    v-else-if="index === 2"
                    class="text-overline grey--text text--darken-3 mx-2"
                  >
                    +{{ files.length - 2 }} File(s)
                  </span>
                </template>
              </v-file-input>
              <v-btn @click="updateMediaThumbnail" color="primary">
                <p class="mr-8">Add/Update media thumbnail</p>
              </v-btn>
              <br /><br />
              <center>
                <img
                  style="width: 78%; display: block"
                  :src="getURL(thumbnail)"
                />
              </center>
              <br /><br />
              <v-btn @click="updateBlockedCountries" color="yellow">
                <p class="mr-8">Update Blocked Countries</p>
              </v-btn>
              <br /><br />
              <v-file-input
                v-model="potraitFile"
                color="deep-purple accent-4"
                counter
                label="Media Potrait (Mazu Productions)"
                placeholder="Select your file"
                prepend-icon="mdi-paperclip"
                outlined
                :show-size="1000"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    v-if="index < 2"
                    color="deep-purple accent-4"
                    dark
                    label
                    small
                  >
                    {{ text }}
                  </v-chip>

                  <span
                    v-else-if="index === 2"
                    class="text-overline grey--text text--darken-3 mx-2"
                  >
                    +{{ files.length - 2 }} File(s)
                  </span>
                </template>
              </v-file-input>
              <v-btn @click="updateMediaPotrait" color="primary">
                <p class="mr-8">Add/Update media potrait</p>
              </v-btn>
              <br /><br />
              <center>
                <img
                  style="width: 78%; display: block"
                  :src="getURL(potrait)"
                />
              </center>
              <br><br>
              <v-btn @click="toggleNewVideo" color="primary">
                <p v-if="isnew == true" class="mr-8">Remove from new videos</p>
                <p v-if="isnew == false" class="mr-8">Add to new Videos</p>
              </v-btn>
              <br />
              <br />
              <v-btn @click="toggleMostViewed" color="secondary">
                <p v-if="mostViewed == true" class="mr-8">
                  Remove from new most viewed
                </p>
                <p v-if="mostViewed == false" class="mr-8">
                  Add to most viewed
                </p>
              </v-btn>
            </template>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              :loading="loading"
              color="primary"
              @click="() => (updateMediaDialog = false)"
              text
              v-if="!uploadingFiles"
            >
              <p class="mr-8">close</p>
            </v-btn>
            <v-btn :loading="loading" color="primary" text @click="updateMedia">
              <p v-if="!uploadingFiles">Update Media</p>
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-img
      v-if="mediaType == 'image'"
      :src="cloudUrl"
      :lazy-src="cloudUrl"
      aspect-ratio="1"
      class="grey lighten-2"
      :height="150"
      :width="300"
    >
      <v-overlay absolute v-if="hover">
        <v-btn color="red" @click="deleteImage(media)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-btn
          style="margin-left: 5px"
          color="yellow"
          @click="updateMediaDialog = true"
        >
          <v-icon>mdi-update</v-icon>
        </v-btn>
      </v-overlay>
      <template v-slot:placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <div v-else>
      <v-badge
        v-if="mostViewed == true"
        style="left: 37%"
        content="Most viewed"
        floating
      >
        <v-icon icon="mdi-vuetify" size="x-large"></v-icon>
      </v-badge>
      <v-badge
        v-if="isnew == true"
        style="left: 10%"
        content="New Video"
        floating
      >
        <v-icon icon="mdi-vuetify" size="x-large"></v-icon>
      </v-badge>
      <video v-if="cloudUrl" width="320" height="240" controls>
        <source :src="cloudUrl" type="video/mp4" />
        <source :src="cloudUrl" type="video/ogg" />
        Your browser does not support the video tag.
      </video>
      <div class="edit_hover_class" style="text-align: center">
        <a @click.prevent="deleteImage(media)" href="#">Delete Video</a>
        <a
          style="margin-left: 5px"
          @click.prevent="updateMediaDialog = true"
          href="#"
          >Update Video</a
        >
        <a
          style="margin-left: 10px"
          @click.prevent="updateMediaBlockedCountries = true"
          href="#"
          >Blocked Regions</a
        >
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import {LOCATIONS} from '../../utils/helper.js'
const PRODUCTION = true;
import {
  UPDTAE_COMMUMITY_MEDIA,
  TOGGLE_NEW_VIDEO,
  TOGGLE_MOST_VIEWED,
  GET_CLOUD_URL,
  GET_CLOUD_PUT_URL,
  UPDATE_PREVIEW_URL,
  UPDATE_MEDIA_THUMBNAIL,
  UPDATE_MEDIA_POTRAIT,
  UPDATE_MEDIA_BLOCKED_REGIONS
} from "@/queries/core.js";
export default {
  props: ["media", "hover"],
  created() {
    this.mediaType = this.media.format.split("/")[0];
    this.title = this.media.title ? this.media.title : "";
    this.description = this.media.description ? this.media.description : "";
    this.tags = this.media.tags.map((t) => t.name).join(",");
    this.cost = this.media.cost;
    this.is_paid = this.media.is_paid;
    this.preview_url = this.media.preview_url;
    this.isnew = this.media.new;
    this.mostViewed = this.media.mostViewed;
    if (this.media.mediaCategory) {
      this.mediaCategory = this.media.mediaCategory;
    } else {
      this.mediaCategory = "Trans";
    }
    if(this.media.blocked_in) {
      this.blocked_in = this.media.blocked_in.split(",").map((countryCode)=>{
          return this.locations.find(l => l.code == countryCode).country
      })
    }
    this.mediaCloudName = this.media.mediaCloudName;
    this.cloudUrl = this.media.cloudUrl;
    this.thumbnail = this.media.thumbnail;
    this.potrait = this.media.potrait;
    this.setCloudUrl();
  },
  data() {
    return {
      blocked_in:[],
      selectedCountries:[LOCATIONS[0].country,LOCATIONS[1].country],
      countries: LOCATIONS.map(l => l.country),
      locations:LOCATIONS,
      loading: false,
      uploadProgress: 0,
      baseUrl: "https://flirttool.com/",
      mediaType: "",
      activeCategory: true,
      updateMediaDialog: false,
      updateMediaBlockedCountries:false,
      uploadingFiles: false,
      cost: 0,
      is_paid: true,
      files: null,
      title: "",
      description: "",
      tags: "",
      preview_url: "",
      isnew: false,
      mostViewed: false,
      mediaCategory: "Gay",
      mediaCloudName: null,
      cloudUrl: null,
      thumbnail: null,
      potrait: null,
      thumbFile: null,
      potraitFile: null,
    };
  },
  methods: {
    async updateBlockedCountries() {
     try {
       let countries = this.blocked_in.map((country)=>{
         return this.locations.find(l => l.country == country).code
       })
        const { data, errors } = await this.$apollo.mutate({
          mutation: UPDATE_MEDIA_BLOCKED_REGIONS,
          variables: {
            media: this.media.id,
            blocked_in: countries.join(","),
          },
        });
        this.updateMedia();
        console.log(data);
      } catch (e) {
        console.log(e);
        alert("There was an error");
      }
    },
    async updatePreviewUrl() {
      if (!this.files) return alert("Please select a video file");
      let { filename } = await this.uploadToCloud(this.files);
      try {
        const { data, errors } = await this.$apollo.mutate({
          mutation: UPDATE_PREVIEW_URL,
          variables: {
            media: this.media.id,
            previewUrl: filename,
          },
        });
        this.preview_url = filename;
        this.files = null;
        this.uploadProgress = 0;
        this.updateMedia();
        console.log(data);
      } catch (e) {
        console.log(e);
        alert("There was an error");
      }
    },
    async updateMediaThumbnail() {
      if (!this.thumbFile) return alert("Please select a image file");
      let { filename } = await this.uploadToCloud(this.thumbFile);
      try {
        const { data, errors } = await this.$apollo.mutate({
          mutation: UPDATE_MEDIA_THUMBNAIL,
          variables: {
            media: this.media.id,
            thumbnail: filename,
          },
        });
        this.thumbnail = filename;
        this.thumbFile = null;
        this.uploadProgress = 0;
        this.updateMedia();
        console.log(data);
      } catch (e) {
        console.log(e);
        alert("There was an error");
      }
    },
    async updateMediaPotrait() {
       if (!this.potraitFile) return alert("Please select a image file");
      let { filename } = await this.uploadToCloud(this.potraitFile);
      try {
        const { data, errors } = await this.$apollo.mutate({
          mutation: UPDATE_MEDIA_POTRAIT,
          variables: {
            media: this.media.id,
            potrait: filename,
          },
        });
        this.potrait = filename;
        this.potraitFile = null;
        this.uploadProgress = 0;
        this.updateMedia();
        console.log(data);
      } catch (e) {
        console.log(e);
        alert("There was an error");
      }
    },
    async setCloudUrl() {
      try {
        const { data, errors } = await this.$apollo.query({
          query: GET_CLOUD_URL,
          variables: {
            filename: this.mediaCloudName,
          },
        });
        this.cloudUrl = data.getCloudUrl;
      } catch (e) {
        console.log(e);
      }
    },
    getURL(url) {
      return `https://storage.googleapis.com/mazuproductions/${url}`;
    },
    deleteImage(media) {
      this.$emit("deleteImage", media);
    },
    async toggleNewVideo() {
      try {
        const { data, errors } = await this.$apollo.mutate({
          mutation: TOGGLE_NEW_VIDEO,
          variables: {
            media: this.media.id,
          },
        });
        this.isnew = data.toggleNewVideo.value;
      } catch (e) {
        alert("There was an error");
      }
    },
    async toggleMostViewed() {
      try {
        const { data, errors } = await this.$apollo.mutate({
          mutation: TOGGLE_MOST_VIEWED,
          variables: {
            media: this.media.id,
          },
        });
        this.mostViewed = data.toggleMostViewed.value;
      } catch (e) {
        alert("There was an error");
      }
    },
    async updateMedia() {
      try {
        this.loading = true;
        let tags = this.tags.split(",");
        const { data, errors } = await this.$apollo.mutate({
          mutation: UPDTAE_COMMUMITY_MEDIA,
          variables: {
            input: {
              media: this.media.id,
              title: this.title,
              description: this.description,
              cost: Number(this.cost),
              is_paid: this.is_paid,
              tags: tags,
              mediaCategory: this.mediaCategory,
            },
          },
        });
        this.loading = false;
        if (data) {
          console.log(data);
          let updatedmedia = data.updateCommunityMedia;
          this.preview_url = updatedmedia.preview_url;
          this.files = null;
          console.log(this.preview_url);
          this.updateMediaDialog = false;
          // this.$forceUpdate();
          this.$emit("mediaUpdate");
        }
      } catch (e) {
        alert("there was some error");
        this.loading = false;
      }
    },
    async uploadToCloud(file) {
      try {
        this.uploadingFiles = true;
        let mimeType = file.type;
        let name = this.makeId(5) + "_" + file.name;
        console.log(file);
        console.log(name);
        const { data, errors } = await this.$apollo.query({
          query: GET_CLOUD_PUT_URL,
          variables: {
            filename: name,
            mimetype: mimeType,
            bucket: "mazuproductions",
          },
        });
        let putUrl = data.getCloudPutUrl;
        return new Promise((resolve, reject) => {
          axios
            .request({
              method: "PUT",
              url: putUrl,
              data: file,
              // headers_list:['Content-Type:video/mp4'],
              headers: {
                "Content-Type": mimeType,
              },
              onUploadProgress: (p) => {
                console.log(p);
                console.log(Math.ceil((p.loaded / p.total) * 100));
                this.uploadProgress = Math.ceil((p.loaded / p.total) * 100);
                console.log(this.uploadProgress);
                // document.getElementById('progress').innerHTML = `${Math.ceil(p.progress * 100)}  %`
                //this.setState({
                //fileprogress: p.loaded / p.total
                //})
              },
            })
            .then((data) => {
              console.log(data);
              resolve({
                filename: name,
                mimetype: mimeType,
              });
            })
            .catch((e) => {
              this.uploadingFiles = false;
              this.uploadProgress = 0;
              reject(e);
            });
        });
      } catch (e) {
        console.log(e);
      }
    },
    makeId(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },
  },
};
</script>

<template>
  <v-container fluid>
     <v-overlay v-model="overlay"></v-overlay>
    <v-app-bar flat :height="100">
      <!-- from date selector -->
      <v-avatar tile :size="60" class="mx-2">
        <v-img :src="require('@/assets/filter.png')"> </v-img>
      </v-avatar>
      <span v-if="$store.state.authP.accountType == 'model'" >
        {{$t('WelcometotheModelgallery')}}<br>
        Please make a gallery and add your videos/Pictures here
      </span>
    </v-app-bar>
    <template>
      <v-dialog
        v-if="activeCategory"
        v-model="galleryUpdateDialog"
        max-width="500px"
        persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
            
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">Update Gallery</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    prepend-icon="mdi-SD"
                    outlined
                    v-model="activeCategory.name"
                    label="Gallery name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <img
                style="width: 91%; margin-left: 34px; margin-bottom: 11px"
                :src="`${baseUrl}${activeCategory.poster}`"
              />
              <v-file-input
                v-model="galleryPoster"
                color="deep-purple accent-4"
                counter
                label="Gallery Poster"
                placeholder="Select your file"
                prepend-icon="mdi-paperclip"
                outlined
                :show-size="1000"
              >
                <template v-slot:selection="{ index, text }">
                  <v-chip
                    v-if="index < 2"
                    color="deep-purple accent-4"
                    dark
                    label
                    small
                  >
                    {{ text }}
                  </v-chip>

                  <span
                    v-else-if="index === 2"
                    class="text-overline grey--text text--darken-3 mx-2"
                  >
                    +{{ files.length - 2 }} File(s)
                  </span>
                </template>
              </v-file-input>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="galleryUpdateDialog = false"
            >
              {{$t("Cancel")}}
            </v-btn>
            <v-btn color="blue darken-1" text @click="updateGallery">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="text-center">
        <v-dialog
          persistent
          v-if="activeCategory"
          v-model="fileDialog"
          width="800"
        >
          <!-- <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="red lighten-2"
                dark
                v-bind="attrs"
                v-on="on"
                >
                Click Me
              </v-btn>
            </template> -->
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              <!-- Add files to {{ activeCategory.category.category}} -->
            </v-card-title>
            <v-card-text>
              <template>
                <br />
                <v-text-field
                  type="number"
                  v-model="cost"
                  label="Media cost"
                  prepend-icon="fas fa-list"
                  outlined
                ></v-text-field>
                <br />

                <v-switch
                  prepend-icon="fas fa-list"
                  v-model="is_paid"
                  label="Is media paid"
                ></v-switch>
                <br />

                <v-file-input
                  v-model="files"
                  color="deep-purple accent-4"
                  counter
                  label="Gallery media"
                  placeholder="Select your file"
                  prepend-icon="mdi-paperclip"
                  outlined
                  :show-size="1000"
                >
                  <template v-slot:selection="{ index, text }">
                    <v-chip
                      v-if="index < 2"
                      color="deep-purple accent-4"
                      dark
                      label
                      small
                    >
                      {{ text }}
                    </v-chip>

                    <span
                      v-else-if="index === 2"
                      class="text-overline grey--text text--darken-3 mx-2"
                    >
                      +{{ files.length - 2 }} File(s)
                    </span>
                  </template>
                </v-file-input>
              </template>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="primary"
                @click="() => (fileDialog = false)"
                text
                v-if="!uploadingFiles"
              >
                <p class="mr-8">close</p>
              </v-btn>
              <v-btn color="primary" text @click="uploadFiles">
                <p v-if="!uploadingFiles">Upload</p>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-btn>
            </v-card-actions>
             <v-progress-linear
             v-if="uploadingFiles"
              v-model="uploadProgress"
              color="blue-grey"
              height="25"
            >
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) }}%</strong>
            </template>
            </v-progress-linear>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <v-spacer></v-spacer>
    <br />
    <!-- stop of filter button group -->
    <v-row>
      <v-col cols="6" dense fixed app>
        <template>
          <v-data-table
            :headers="headers"
            :items="galleries"
            :loading="loadingTableData"
            fixed-header
            sort-by="calories"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  <span v-if="$store.state.authP.accountType == 'model'">
                    Model
                  </span>
                  <span v-else>
                    {{$t('Community')}}
                  </span> {{$t('Gallery')}}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      New {{$t('Gallery')}}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">New {{$t('Gallery')}}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              prepend-icon="mdi-SD"
                              outlined
                              v-model="newGallery"
                              label="Gallery name"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-file-input
                          v-model="galleryPoster"
                          color="deep-purple accent-4"
                          counter
                          label="Gallery Poster"
                          placeholder="Select your file"
                          prepend-icon="mdi-paperclip"
                          outlined
                          :show-size="1000"
                        >
                          <template v-slot:selection="{ index, text }">
                            <v-chip
                              v-if="index < 2"
                              color="deep-purple accent-4"
                              dark
                              label
                              small
                            >
                              {{ text }}
                            </v-chip>

                            <span
                              v-else-if="index === 2"
                              class="text-overline grey--text text--darken-3 mx-2"
                            >
                              +{{ files.length - 2 }} File(s)
                            </span>
                          </template>
                        </v-file-input>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close">
                        {{$t('Cancel')}}
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="addNewGallery">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px" persistent>
                  <v-card>
                    <v-card-title class="text-h5"
                      >Are you sure you want to delete this item?</v-card-title
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete"
                        >Cancel</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                        >OK</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <!-- <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                  >
                  mdi-pencil
                  </v-icon> -->
              <v-icon class="mr-2" small @click="deleteItem(item)">
                mdi-delete
              </v-icon>
              <v-icon
                small
                class="mr-8"
                :color="isItemActive(item)"
                @click="getGalleryImages(item)"
                >mdi-eye
              </v-icon>
              <v-icon
                v-if="isItemActive(item) === 'green'"
                :color="isItemActive(item)"
                medium
                class="mr-8"
                @click="openFileUploadDialogue(item)"
              >
                mdi-arrow-up-bold-box-outline
              </v-icon>
              <v-icon
                v-if="isItemActive(item) === 'green'"
                :color="isItemActive(item)"
                medium
                @click="openGalleryUpdateDialog(item)"
              >
                mdi mdi-update
              </v-icon>
            </template>
          </v-data-table>
        </template>
      </v-col>
      <v-col>
        <template>
          <v-row style="height: 650px; overflow: scroll">
            <v-col
              v-for="n in images"
              :key="n.id"
              class="d-flex child-flex"
              cols="4"
            >
              <v-hover v-slot="{ hover }">
                <GetMedia
                  :media="n"
                  :hover="hover"
                  @deleteImage="deleteImage"
                  @mediaUpdate="mediaUpdate"
                />
              
              </v-hover>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/* eslint-disable */
import GetMedia from "./media/getMedia.vue";
import {
  // ADD_SELLING_PIC_CATEGORY,
  ADD_COMMUMITY_GALLERY,
  DELETE_COMMUNITY_GALLERY,
  // DELETE_SELLING_PIC_CATEGORY,
  // GET_SELLING_PICS,
  // ADD_SELLING_PICS,
  // DELETE_SELLING_PIC,
  UPDTAE_COMMUMITY_GALLERY,
  DELETE_COMMUNITY_MEDIA,
  ADD_COMMUNITY_GALLERY_MEDIA,
  GET_COMMUNITY_GALLERIES,
  GET_GALLERY_MEDIA,
  GET_CLOUD_PUT_URL
} from "../queries/core";
import axios from 'axios';

export default {
  components: {
    GetMedia,
  },
  apollo: {
    getCommunityGalleries: {
      query: GET_COMMUNITY_GALLERIES,
      variables() {
        return {
          user:this.$store.state.authP.accountType == "model" ? this.$store.state.modId : null
        };
      },
      result({ data: { getCommunityGalleries } }) {
        console.log(getCommunityGalleries, "getCommunityGalleries");
        this.galleries = getCommunityGalleries;
        this.loadingTableData = false;
      },
    },
  },
  data: () => ({
    uploadProgress:null,
    overlay:false,
    tab: null,
    items: ["web", "shopping", "videos", "images", "news"],
    text: "df.",
    uploadingFiles: false,
    loadingTableData: true,
    dialog: false,
    fileDialog: false,
    dialogDelete: false,
    todeleteItem: null,
    files: [],
    galleryPoster: null,
    cost: 0,
    is_paid: true,
    baseUrl: "https://flirttool.com/",
    headers: [
      {
        text: "Category",
        align: "start",
        sortable: false,
        value: "name",
      },
      // { text: 'Images', value: 'imageCount' },
      { text:"Actions", value: "actions", sortable: false },
    ],
    categories: [],
    newGallery: "",
    activeCategory: null,
    images: [],
    galleryUpdateDialog: false,
    //gallery
    galleries: [],
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    let password = prompt("Only Admins are allowed to add media. To continue please provide password", "");

    if (password != 'pop1234') {
      window.location.reload()
    }
    this.initialize();
  },

  methods: {
    async addNewGallery() {
      if (this.newGallery.length < 2)
        return alert("Name should have at least 2 chars");
      if (!this.galleryPoster) return alert("Please provide a gallery poster");
      const { errors } = await this.$apollo.mutate({
        mutation: ADD_COMMUMITY_GALLERY,
        variables: {
          name: this.newGallery,
          file: this.galleryPoster,
        },
      });
      if (!errors) {
        this.dialog = false;
        this.newGallery = "";
        this.galleryPoster = null
        this.$apollo.queries.getCommunityGalleries.refetch();
      }
    },
    async updateGallery() {
      if (this.activeCategory.name.length < 2)
        return alert("Name should have at least 2 chars");
      if (!this.galleryPoster) return alert("Please provide a gallery poster");
      const { errors } = await this.$apollo.mutate({
        mutation: UPDTAE_COMMUMITY_GALLERY,
        variables: {
          id:this.activeCategory.id,
          name: this.activeCategory.name,
          file: this.galleryPoster,
        },
      });
      if (!errors) {
        this.dialog = false;
        this.galleryUpdateDialog = false
        this.newGallery = "";
        this.galleryPoster = null
        this.$apollo.queries.getCommunityGalleries.refetch();
      }
    },

    async getGalleryImages(gallery) {
      this.loadingTableData = true;
        this.images = []
        const { data, errors } = await this.$apollo.query({
        query: GET_GALLERY_MEDIA,
        variables: {
          gallery: gallery.id,
        },
        fetchPolicy: "no-cache",
      });
      this.loadingTableData = false;
      if (!errors) {
        console.log(data.getGalleryMedia);
        let galleryMedia = data.getGalleryMedia;
        this.activeCategory = gallery;
        this.images = galleryMedia.media;
      }
    },
    async openFileUploadDialogue(item) {
      await this.getGalleryImages(item);
      this.fileDialog = true;
    },
    mediaUpdate() {
      this.getGalleryImages(this.activeCategory)
    },
    openGalleryUpdateDialog() {
      this.galleryUpdateDialog = true;
    },
    isItemActive(item) {
      if (!this.activeCategory) return "grey";
      let color = item.id == this.activeCategory.id ? "green" : "grey";
      return color;
    },
    makeId(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      return result;
    },
    async uploadToCloud(file) {
      try {
        this.uploadingFiles = true;
        let mimeType = file.type
        let name = this.makeId(5)+'_'+ file.name
        console.log(file);
        console.log(name)
        const { data, errors } = await this.$apollo.query({
        query: GET_CLOUD_PUT_URL,
        variables: {
          filename: name,
          mimetype: mimeType,
        },
      });
      let putUrl = data.getCloudPutUrl
        return new Promise((resolve,reject)=>{
            axios.request({
              method: "PUT", 
              url: putUrl, 
              data: file, 
              // headers_list:['Content-Type:video/mp4'],
              headers:{
                'Content-Type':mimeType
              },
              onUploadProgress: (p) => {
                console.log(p); 
                console.log(Math.ceil(p.loaded/p.total * 100))
                this.uploadProgress = Math.ceil(p.loaded/p.total * 100)
                console.log(this.uploadProgress)
                // document.getElementById('progress').innerHTML = `${Math.ceil(p.progress * 100)}  %`
                //this.setState({
                    //fileprogress: p.loaded / p.total
                //})
              }
            }).then (data => {
              console.log(data)
              this.uploadingFiles = false
                resolve({
                  filename:name,
                  mimetype:mimeType
                })
            }).catch((e)=>{
              this.uploadingFiles = false
              this.uploadProgress = 0
              reject(e)
            })
        })
      }catch(e) {
        console.log(e)
      }
    },
    async uploadFiles() {
    try {
      console.log(this.files);
      let {filename,mimetype} = await this.uploadToCloud(this.files)
      // if(!this.files.length) return alert('Please add files')
      this.uploadingFiles = true;
      const { data, errors } = await this.$apollo.mutate({
        mutation: ADD_COMMUNITY_GALLERY_MEDIA,
        variables: {
          gallery: Number(this.activeCategory.id),
          cost: Number(this.cost),
          is_paid: this.is_paid,
          mediaCloudName:filename,
          mimetype:mimetype
          // file: this.files,
        },
      });
      this.uploadingFiles = false;
      if (!errors) {
        this.images.push(data.addCommunityMedia);
        this.mediaUpdate()
        this.fileDialog = false;
        this.files = [];
        this.cost = 0;
        this.uploadProgress = 0
      }
    }catch(e) {
      alert(e)
      console.log(e)
    }
  },
    async deleteImage(image) {
      console.log(image);
      this.overlay = true
      const { data, errors } = await this.$apollo.mutate({
        mutation: DELETE_COMMUNITY_MEDIA,
        variables: {
          id: Number(image.id),
        },
      });
      this.overlay = false

      if (!errors) {
        console.log(data);
        this.images = this.images.filter((x) => x.id != image.id);
      }
    },
    initialize() {
      this.desserts = [
        {
          category: "XXX",
          imageCount: 159,
        },
      ];
    },

    deleteItem(item) {
      this.todeleteItem = item;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      const { data, errors } = await this.$apollo.mutate({
        mutation: DELETE_COMMUNITY_GALLERY,
        variables: {
          id: Number(this.todeleteItem.id),
        },
      });
      if (!errors) {
        this.dialog = false;
        this.todeleteItem = null;
        this.images = [];
        this.$apollo.queries.getCommunityGalleries.refetch();
        console.log(data, "datadata");
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>
<style scoped>
.col {
  padding: 12px !important;
}
</style>

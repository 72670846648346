<template>
  <div v-if="renderComponent">
    <v-list-item
      style="text-decoration: none"
      link
      dark
      :to="item.to"
      v-for="(item, i) in items"
      :ripple="false"
      :key="i"
    >
      <v-col>
        <v-row dense :class="{ 'mx-2': drawer }">
          <v-icon :size="25" v-text="item.icon"></v-icon>
          <h6
            v-if="drawer"
            style="font-size: 16"
            class="px-2 pt-3 font-weight-regular"
          >
            {{ item.text }}
          </h6>
        </v-row>
      </v-col>
    </v-list-item>
  </div>
</template>

<script>
/* eslint-disable */
import { PANEL_TABS_SETTINGS } from "../queries/core";
// import { getTabs, allRoutes } from "../utils/helper";
export default {
  props: ["drawer"],
  asyncComputed: {
    async items() {
      let accountType = this.$store.state.authP.accountType;
      try {
        let tabSettings = await this.getTabsSettings();
        let userTabs = this.getTabs(tabSettings, accountType);
        return userTabs;
      } catch (e) {
        if (accountType == "admin") {
          return this.allRoutes;
        } else {
          alert(e);
          return [];
        }
      }
    },
  },
  computed: {
    allRoutes: {
      get: function () {
        return [
          {
            icon: "mdi-crop-square",
            text: this.$t("Dashboard"),
            to: "/dashboard/",
            textKey: "Dashboard",
          },
          {
            icon: "mdi-chat",
            text: this.$t("ChatModeration"),
            to: "/chat-page/",
            textKey: "Chat Moderation",
          },
          {
            icon: "mdi-chart-bar",
            text: this.$t("AgentActivity"),
            to: "/agent-chat-analysis/",
            textKey: "Agent Activity",
          },
          {
            icon: "mdi-chart-pie",
            text: this.$t("ActivityAnalysis"),
            to: "/chat-analysis/",
            textKey: "Activity Analysis",
          },
          {
            icon: "mdi-file-document-multiple",
            text: this.$t("MessageStatistics"),
            to: "/message-statistics/",
            textKey: "Message Statistics",
          },
          {
            icon: "mdi-access-point-check",
            text: this.$t("LiveMonitoring"),
            to: "/livemonitoring/",
            textKey: "Live Monitoring",
          },
          // {
          //   icon: "mdi-check-all",
          //   text: this.$t("AgentOverview"),
          //   to: "/agentOverview/",
          //   textKey: "Agent Overview",
          // },
          {
            icon: "mdi-account",
            text: this.$t("Moderators"),
            to: "/moderators/",
            textKey: "Moderators",
          },
          {
            icon: "mdi-account-star",
            text: this.$t("FakeAccounts"),
            to: "/fake-accounts/",
            textKey: "Fake Accounts",
          },
          {
            icon: "mdi-account-check",
            text: this.$t("Promotion"),
            to: "/reach-out/",
            textKey: "Promotion",
          },
          {
            icon: "mdi-video",
            text: this.$t("Events"),
            to: "/events",
            textKey: "Dashboard",
          },
          {
            icon: "mdi-bank",
            text: this.$t("Finance"),
            to: "/finance/",
            textKey: "Finance",
          },
          // {
          //   icon: "mdi-speaker",
          //   text: this.$t("Affilates"),
          //   to: "/affilates/",
          //   textKey: "Affilates",
          // },
          {
            // icon: "mdi-heart",
            text: this.$t("MessageReported"),
            to: "/mod-reports/",
            textKey: "Message Reported",
          },
          {
            icon: "mdi-heart",
            text: this.$t("PanicRoom"),
            to: "/panicroom/",
            textKey: "Panic Room",
          },
          {
            icon: "mdi-gift",
            text: this.$t("Packages"),
            to: "/packages/",
            textKey: "Packages",
          },
          {
            icon: "mdi-ticket",
            text: this.$t("Coupons"),
            to: "/coupons/",
            textKey: "Coupons",
          },
          {
            icon: "mdi-chart-line",
            text: this.$t("Statistics"),
            to: "/statistics/",
            textKey: "Statistics",
          },
          {
            icon: "mdi-heart",
            text: this.$t("SellingImages"),
            to: "/selling-images/",
            textKey: "Selling Images",
          },
          {
            icon: "mdi-memory",
            text: this.$t("Settings"),
            to: "/settings/",
            textKey: "Settings",
          },
        ];
      },
    },
  },
  data() {
    return {
      renderComponent: true,
    };
  },
  async created() {},
  watch: {
    "$store.state.lang": function () {
     this.forceRender()
    //  this.$forceUpdate()
    },
  },
  methods: {
    async forceRender() {
     window.location.reload()
    },
    getTabs(tabSettings, accountType) {
      let tabSettingKey;
      let routes = [];
      if (accountType == "admin") {
        // tabSettingKey = 'moderatorTabs'

        return this.allRoutes;
      }
      if(accountType == 'model') {
        return this.allRoutes.filter(r => r.textKey == 'Selling Images')
      }
      if (accountType == "agent") {
        tabSettingKey = "moderatorTabs";
      }
      if (accountType == "sub_admin") {
        tabSettingKey = "subadminTabs";
      }
      if (accountType == "tl") {
        tabSettingKey = "teamleaderTabs";
      }
      let allowedRoutes = tabSettings[tabSettingKey];
      if (!allowedRoutes) return [];
      allowedRoutes = JSON.parse(allowedRoutes);
      routes = this.allRoutes.filter((r) => allowedRoutes.includes(r.textKey));
      return routes;
    },
    async getTabsSettings() {
      try {
        const { data } = await this.$apollo.query({
          query: PANEL_TABS_SETTINGS,
          fetchPolicy: "no-cache",
        });
        if (data.getPanelTabSettings) {
          let tabSettings = data.getPanelTabSettings;
          return tabSettings;
        }
      } catch (e) {
        throw Error(
          "Tab settings not found, Please ask admin to add functions"
        );
      }
    },
  },
};
</script>

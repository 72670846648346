<template>
  <v-app>
    <!-- start of new fake  -->
    <v-dialog v-model="newFakePop" max-width="690">
      <v-card>
        <v-row class="mx-2">
          <v-card-title class="text-h5 px-3 pt-10" style="padding: 0px">
            {{$t('AddnewModeratedAccount')}}
          </v-card-title>
          <v-card-text style="padding: 0px" class="px-3"
            >{{$t('Enterinformationhere')}}</v-card-text
          >
        </v-row>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <!-- end of new fake popup -->
    <v-dialog v-model="newModDialog" max-width="690">
      <v-card>
        <v-row class="mx-2">
          <v-card-title class="text-h5 px-3 pt-10" style="padding: 0px">
            {{$t('AddnewModeratedAccount')}}
          </v-card-title>
          <v-card-text style="padding: 0px" class="px-3"
            >{{$t('Enterinformationhere')}}</v-card-text
          >
        </v-row>
        <v-divider></v-divider>

        <v-form v-model="valid" ref="form">
          <v-col>
            <div class="mb-2">
              <v-col style="padding: 0px">
                <v-text-field
                  v-model="pin"
                  dense
                  outlined
                  placeholder="Enter Pin"
                  :rules="pinRules"
                >
                </v-text-field>
              </v-col>
              <v-col style="padding: 0px">
                <v-text-field
                  outlined
                  :rules="passRules"
                  v-model="mPpassword"
                  placeholder="Enter password"
                >
                </v-text-field>
              </v-col>
              <v-col style="padding: 0px">
                <v-select
                  outlined
                  :items="modTypes"
                  :rules="typeRules"
                  v-model="modType"
                >
                </v-select>
              </v-col>
            </div>
            <v-btn block @click="saveNewPin" class="mb-5">{{$t('Save')}}</v-btn>
          </v-col>
        </v-form>
      </v-card>
    </v-dialog>
    <!-- start of poke dialog -->
    <v-dialog v-model="showConfirmDialogSPP" persistent max-width="690">
      <v-card>
        <v-overlay absolute v-if="loadingMsave">
          <v-progress-circular
            indeterminate
            :size="60"
            color="white"
          ></v-progress-circular>
        </v-overlay>
        <v-card-title class="text-h5 px-3 pt-4" style="padding: 0px">
          {{$t('SendMassPoke')}} ?
        </v-card-title>
        <v-card-text style="padding: 0px" class="px-3"
          >{{$t('YourPOKESwillgoouttotheseusers')}}</v-card-text
        >
        <v-divider></v-divider>
        <v-col>
          <div class="mb-2">
            <v-chip-group active-class="primary--text" column>
              <v-chip
                color="blue darken-1"
                dark
                v-for="tag in allSelected"
                :key="tag.id"
              >
                @<span class="px-1">{{ tag.username }}</span>
              </v-chip>
            </v-chip-group>
          </div>
          <!-- start of autocomplete  -->
          <v-toolbar flat dark color="blue">
            <v-toolbar-title>Select Fake</v-toolbar-title>
            <v-autocomplete
              v-model="selectL"
              :loading="loadingAc"
              :items="searchTargetUser"
              :search-input.sync="search"
              item-text="username"
              item-value="username"
              cache-items
              class="mx-4"
              flat
              hide-no-data
              hide-selected
              hide-details
              label="Select fake user to send with."
              solo-inverted
            >
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  color="blue-grey"
                  class="white--text"
                  v-on="on"
                >
                  <v-icon left> mdi-account </v-icon>
                </v-chip>
                <span v-if="item.base_profile">
                  {{ item.base_profile.firstName }}
                  {{ item.base_profile.lastName }}
                </span>
                <span v-else>{{ item.username }}</span>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-avatar
                  color="indigo"
                  class="text-h5 font-weight-light white--text"
                >
                  <v-img
                    :src="'https://flirttool.com/media/' + item.profilePic"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-list-item-avatar>
                <v-list-item-content v-if="item.base_profile">
                  {{ item.base_profile.firstName }}
                  {{ item.base_profile.lastName }}
                </v-list-item-content>
                <v-list-item-title v-text="item.username"></v-list-item-title>
              </template>
            </v-autocomplete>
          </v-toolbar>
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeConfirmDialogSPP()">
            Close
          </v-btn>
          <v-btn
            dark
            class="font-weight-bold"
            color="pink darken-1"
            @click="sendMassPoke"
            :loading="loadingMassLikes"
            ><span class="px-2"> Send Poke </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end of poke dialog -->
    <v-dialog v-model="showConfirmDialogSPL" persistent max-width="690">
      <v-card>
        <v-overlay absolute v-if="loadingMsave">
          <v-progress-circular
            indeterminate
            :size="60"
            color="white"
          ></v-progress-circular>
        </v-overlay>
        <v-card-title class="text-h5 px-3 pt-4" style="padding: 0px">
          {{$t('SendMassLikes')}} ?
        </v-card-title>
        <v-card-text style="padding: 0px" class="px-3"
          >{{$t('YourLIKESwillgoouttotheseusers')}}</v-card-text
        >
        <v-divider></v-divider>
        <v-col>
          <div class="mb-2">
            <v-chip-group active-class="primary--text" column>
              <v-chip
                color="blue darken-1"
                dark
                v-for="tag in allSelected"
                :key="tag.id"
              >
                @<span class="px-1">{{ tag.username }}</span>
              </v-chip>
            </v-chip-group>
          </div>
          <!-- start of autocomplete  -->
          <v-toolbar flat dark color="blue">
            <v-toolbar-title>Select Fake</v-toolbar-title>
            <v-autocomplete
              v-model="selectL"
              :loading="loadingAc"
              :items="searchTargetUser"
              :search-input.sync="searchF"
              item-text="username"
              item-value="username"
              cache-items
              class="mx-4"
              flat
              hide-no-data
              hide-selected
              hide-details
              label="Select fake user to send with."
              solo-inverted
            >
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  color="blue-grey"
                  class="white--text"
                  v-on="on"
                >
                  <v-icon left> mdi-account </v-icon>
                </v-chip>
                <span v-if="item.base_profile">
                  {{ item.base_profile.firstName }}
                  {{ item.base_profile.lastName }}
                </span>
                <span v-else>{{ item.username }}</span>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-avatar
                  color="indigo"
                  class="text-h5 font-weight-light white--text"
                >
                  <v-img
                    :src="'https://flirttool.com/media/' + item.profilePic"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <!-- {{ item }} -->
                  <!-- {{ item.name.charAt(0) }} -->
                </v-list-item-avatar>
                <v-list-item-content v-if="item.base_profile">
                  {{ item.base_profile.firstName }}
                  {{ item.base_profile.lastName }}
                </v-list-item-content>
                <v-list-item-title v-text="item.username"></v-list-item-title>
              </template>
            </v-autocomplete>
          </v-toolbar>
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeConfirmDialogSPL">
            {{$t('Close')}}
          </v-btn>
          <v-btn
            dark
            class="font-weight-bold"
            color="pink darken-1"
            @click="sendMassLikes"
            :loading="loadingMassLikes"
            ><span class="px-2">
              Send <v-icon class="px-2">mdi-heart</v-icon>
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

       <v-dialog v-model="showOtherOptionsDialog" persistent max-width="690">
      <v-card>
        <v-overlay absolute v-if="loadingMsave">
          <v-progress-circular
            indeterminate
            :size="60"
            color="white"
          ></v-progress-circular>
        </v-overlay>
        <v-card-title class="text-h5 px-3 pt-4" style="padding: 0px">
          DMI Operations
        </v-card-title>
        <v-card-text style="padding: 0px" class="px-3"
          >DMI Operations</v-card-text
        >
        <v-divider></v-divider>
        <v-col>
            <v-btn
            dark
            class="font-weight-bold"
            color="pink darken-1"
            @click="deletePools"
            ><span class="px-2"> Delete Pools </span>
          </v-btn>
        </v-col>
       <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeOtherOptionsDialog">
            {{$t('Close')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    

    <v-dialog
      v-model="showConfirmDialogSPM"
      persistent
      max-width="690"
      fullscreen
    >
      <v-card>
        <v-overlay absolute v-if="loadingMsave">
          <v-progress-circular
            indeterminate
            :size="60"
            color="white"
          ></v-progress-circular>
        </v-overlay>
        <v-card-title class="text-h5 px-3 pt-4" style="padding: 0px">
          {{$t('SendMassSpam')}} ?
        </v-card-title>
        <v-card-text style="padding: 0px" class="px-3"
          >{{$t('Yourmessagewillgoouttotheseusers')}}</v-card-text
        >
        <v-divider></v-divider>
        <v-col>
          <div class="mb-2">
            <v-chip-group active-class="primary--text" column>
              <v-chip
                color="blue darken-1"
                dark
                v-for="tag in allSelected"
                :key="tag.id"
              >
                @<span class="px-1">{{ tag.username }}</span>
              </v-chip>
            </v-chip-group>
          </div>
          <!-- start of autocomplete  -->
          <v-toolbar flat dark color="blue">
            <v-toolbar-title>Select Fake</v-toolbar-title>
            <v-autocomplete
              v-model="select"
              :loading="loadingAc"
              :items="searchTargetUser"
              :search-input.sync="searchF"
              item-text="username"
              item-value="username"
              cache-items
              class="mx-4"
              flat
              hide-no-data
              hide-selected
              hide-details
              label="Select fake user to send with."
              solo-inverted
            >
              <template v-slot:selection="{ attr, on, item, selected }">
                <v-chip
                  v-bind="attr"
                  :input-value="selected"
                  color="blue-grey"
                  class="white--text"
                  v-on="on"
                >
                  <v-avatar left>
                    <v-img :src="getURL(item.profilePic)"></v-img>
                  </v-avatar>
                </v-chip>
                <span v-if="item.base_profile">
                  {{ item.base_profile.firstName }}
                  {{ item.base_profile.lastName }}
                </span>
                <span v-else>
                  {{ item.username }}
                </span>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-avatar
                  color="indigo"
                  class="text-h5 font-weight-light white--text"
                >
                  <v-img
                    :src="'https://flirttool.com/media/' + item.profilePic"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                  <!-- {{ item }} -->
                  <!-- {{ item.name.charAt(0) }} -->
                </v-list-item-avatar>
                <v-list-item-content v-if="item.base_profile">
                  {{ item.base_profile.firstName }}
                  {{ item.base_profile.lastName }}
                </v-list-item-content>
                <v-list-item-title v-text="item.username"></v-list-item-title>
              </template>
            </v-autocomplete>
          </v-toolbar>
          <!-- end of autocompelte -->
          <b-form-textarea
            class="mt-3"
            v-model="message"
            style="border-radius: 0px"
            id="textarea-large"
            size="lg"
            placeholder="Enter message here"
          ></b-form-textarea>
        </v-col>
        <v-card-actions>
          <v-spacer></v-spacer>
          <input
            accept="image/*"
            type="file"
            class="d-none"
            ref="fileup"
            @change="picUploaded"
          />
          <img style="height:200px" ref="toUploadImage" src=""/>
          <v-btn
            :loading="loadingMassSend"
            icon
            large
            outlined
            class="mx-2"
            @click="$refs.fileup.click()"
            color="blue"
            ><v-icon>mdi-camera</v-icon>
          </v-btn>
          <v-btn color="blue darken-1" text @click="closeConfirmDialogSPM">
            Close
          </v-btn>
          <v-btn
            outlined
            color="blue darken-2"
            @click="sendMassSpam"
            :loading="loadingMassSend"
          >
            {{$t('SendSpam')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showConfirmDialog" persistent max-width="690" fullscreen>
      <v-card>
        <v-row class="mx-2">
          <v-card-title class="text-h5 px-3 pt-10" style="padding: 0px">
            {{$t('SendMassEmail')}} ?
          </v-card-title>
          <v-card-text style="padding: 0px" class="px-3"
            >{{$t('Emailmessageswillgoouttotheseusers')}}</v-card-text
          >
        </v-row>
        <v-divider></v-divider>

        <v-col>
          <div class="mb-2">
            <v-chip-group active-class="primary--text" column>
              <v-chip
                color="blue darken-1"
                dark
                v-for="tag in allSelected"
                :key="tag.id"
              >
                {{ tag.email }}
              </v-chip>
            </v-chip-group>
            <v-row class="mx-5 mb-3">
              <v-spacer></v-spacer>
              <v-card class="mx-2" outlined>
                <v-btn
                  color="blue darken-3 mx-4"
                  text
                  @click="closeConfirmDialog"
                >
                  {{$t('Close')}}
                </v-btn>
                <v-btn
                  dark
                  color="blue darken-3 mx-4 my-4"
                  @click="sendMassEmail"
                  :loading="loadingMassSend"
                >
                  {{$t('SendEmail')}}
                </v-btn>
              </v-card>
            </v-row>
          </div>
          <b-form-input
            style="border-radius: 0px"
            class="mb-3"
            v-model="subject"
            placeholder="Email Subject"
          ></b-form-input>
          <center v-if="!(type == 'chat')">
            <Editor
              v-model="content"
              :image-provider="imageProvider"
              width="100%"
            />
          </center>
        </v-col>
      </v-card>
    </v-dialog>
    <action-drawer
      @drawerClosed="openDrawer = false"
      :user="selectedUser"
      :drawer="openDrawer"
      :type="actionType"
    ></action-drawer>
    <div id="app">
      <v-row dense>
        <div class="mt-2">
          <v-row dense class="mt-5 mx-5">
            <v-avatar tile :size="60" class="mt-3 ml-2">
              <v-img :src="require('@/assets/chat.png')" />
            </v-avatar>
            <h2 class="px-3 pt-2 font-weight-bold">
              <span
                >{{$t('Promotion')}}
                <h5>{{$t('Sendmessageandreachouttousershere')}}</h5>
              </span>
            </h2>
          </v-row>
        </div>
      </v-row>
      <v-row dense class="mx-3 mt-2">
        <v-icon class="mx-2" :size="30">mdi-magnify</v-icon>
        <b-form-input
          v-model="searched"
          style="max-width: 40%"
          class="searchField mt-7"
          placeholder="Search for any username, email, ..."
          @keypress.enter="searchUsers"
        ></b-form-input>

        <v-btn
          @click="searchUsers"
          class="mt-7 mx-2"
          color="blue darken-2"
          depressed
          outlined
          :loading="loadingSearch"
          >{{$t('Search')}}</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          @click="newFakePop = true"
          class="mt-5 mx-2"
          outlined
          color="error"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-btn
          @click="reload"
          :loading="loadingData"
          class="mt-5 mx-2"
          outlined
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-col lg="2">
          <v-select
            v-model="genderFilter"
            outlined
            dense
            item-text="label"
            item-value="value"
            @change="filterSelected('gender')"
            class="mt-4"
            placeholder="Gender Filter"
            :items="gender"
          ></v-select>
        </v-col>
         <v-col lg="2">
          <v-select
            v-model="prefrenceFilter"
            outlined
            dense
            item-text="label"
            item-value="value"
            @change="filterSelected('pref')"
            class="mt-4"
            placeholder="Prefrence Filter"
            :items="pref"
          ></v-select>
        </v-col>

        <!-- separate 2 selectors -->
        <v-col lg="2">
          <v-select
            v-model="selectedAction"
            outlined
            dense
            @change="actionSelected"
            class="mt-4 mx-3"
            placeholder="Choose Action"
            :items="actionItems"
          ></v-select>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-data-table
        :options.sync="options"
        :server-items-length="totalCount"
        :loading="loadingData"
        :items="users"
        :items-per-page="10"
        :headers="headers"
        show-select
        fixed-header
        class="elevation-0 mt-3 mx-2"
        :footer-props="{
          prevIcon: 'mdi-arrow-collapse-left',
          nextIcon: 'mdi-arrow-collapse-right',
        }"
        v-model="allSelected"
      >
        <template v-slot:[`item.location`]="{ item }">
          <v-chip v-if="getCountryDetails(item.tempLocationDetail) === 'None'">
            <h6 class="pt-3">{{$t('Unknown')}}</h6>
          </v-chip>
          <div v-else>
            <v-row class="my-2">
              <v-avatar size="30" tile>
                <v-img
                  :src="
                    getCountryDetails(item.tempLocationDetail).location
                      .country_flag
                  "
                />
              </v-avatar>
              <h5 class="px-2 pt-2">
                {{ getCountryDetails(item.tempLocationDetail).country_name }}
              </h5>
            </v-row>
          </div>
        </template>
        <template v-slot:[`item.fullName`]="{ item }">
          <p v-if="item.base_profile">
            {{ item.base_profile.firstName }}

            {{ item.base_profile.lastName }}
          </p>
          <p v-else>{{$t('NOTSET')}}</p>
        </template>

        <template v-slot:[`item.profileSetupStep`]="{ item }">
          <v-icon
            v-text="
              item.profileSetupStep >= 2
                ? 'mdi-check-circle'
                : 'mdi-close-circle'
            "
            :size="30"
            class="font-weight-bold"
            :color="item.profileSetupStep >= 2 ? 'green' : 'error'"
          >
          </v-icon>
          {{item.profileSetupStep}}
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ parseDate(item.createdAt) }}
        </template>

        <template v-slot:[`item.gender`]="{ item }">
          <div v-if="item.looks">
            <v-chip
              v-if="item.looks.gender === 'male'"
              color="blue darken-2"
              dark
            >
              <h6 class="pt-2">
                <span class="pt-5"> {{item.looks.gender}} </span>
              </h6>
            </v-chip>
            <v-chip
              v-else-if="item.looks.gender === 'female'"
              color="red darken-1"
              dark
            >
              <h6 class="pt-2">
                <span> {{item.looks.gender}} </span>
              </h6>
            </v-chip>
            <v-chip v-else color="yellow darken-2" dark>
              <h6 class="pt-2">
                <span class="pt-5"> {{item.looks.gender}} </span>
              </h6>
            </v-chip>
          </div>
        </template>
        <template v-slot:[`item.preference`]="{ item }">
          <div v-if="item.looks">
            <v-chip
              color="blue darken-2"
              dark
            >
              <h6 class="pt-2">
                <span class="pt-5"> {{item.looks.preference}} </span>
              </h6>
            </v-chip>
          </div>
        </template>

        <template v-slot:[`item.lastseen`]="{ item }">
          <v-img
            :src="getURL(item.profilePic)"
            style="width: 40px; height: 40px; border-radius: 10px"
            class="my-2"
          ></v-img>
        </template>
        <template v-slot:[`item.profile`]="{ item }">
          <v-btn outlined @click="toUser(item.username)">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.action`]="{ item }">
          <v-btn @click="popUsr(item)" dark color="red" icon
            ><v-icon>mdi-delete</v-icon></v-btn
          >
          <v-btn
            icon
            @click="activateAcc(item)"
            class="mx-2"
            color="blue"
            outlined
            style="border-radius: 0px"
            ><v-icon>mdi-check</v-icon></v-btn
          >
        </template>
      </v-data-table>
      <v-container class="d-none">
        <center>
          <v-col lg="10">
            <v-pagination
              dark
              color="rgb(42 142 208)"
              v-model="options.page"
              :total-visible="15"
              :length="totalCount"
            ></v-pagination>
          </v-col>
        </center>
      </v-container>
    </div>
  </v-app>
</template>
<script>
import axios from "axios";
import actionDrawer from "../components/actionDrawer.vue";
import Editor from "@baoshishu/vue-editor";
import {
  ACTIVATE_ACC,
  GEN_MOD,
  REMOVE_ACCOUNT,
  SEARCH_TARGET,
  SEARCH_USR,
  SEND_MASS_LIKE,
  SEND_NASS_POKE,
  SEND_SPAM_MESSAGE,
  USERS_PAGINATED,
  DELETE_POOLS
} from "../queries/core";

const PRODUCTION = true;
export default {
  components: { actionDrawer, Editor },
  data() {
    return {
      filterType:null,
      genderFilter:null,
      prefrenceFilter:null,
      page: 1,
      pageSize: 5,
      totalCount: 0,
      users: [],
      searchF: "",
      newFakePop: false,
      loading: false,
      loadingSearch:false,
      openDrawer: false,
      loadingMassLikes: false,
      loadingMsave: false,
      openEmailDrawer: false,
      valid: false,
      selectL: "",
      fileupload: null,
      selectedAction: "",
      searched: "",
      authKey: "",
      options: {
        page: 1,
        itemsPerPage: 10,
      },
      loadingAct: false,
      type: "",
      selectedFilter: "all",
      actionType: "",
      selectedUser: {},
      imageProvider: "",
      loadingData: false,
      currentStamp: Math.floor(new Date().getTime() / 1000),
      headers: [
        {
          text: this.$t('Location'),
          align: "start",
          sortable: false,
          value: "location",
        },
        {
          text: this.$t('FullName'),
          align: "start",
          sortable: false,
          value: "fullName",
        },
        {
          text: this.$t('Active'),
          align: "start",
          sortable: false,
          value: "profileSetupStep",
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: "Setup Step",
          align: "start",
          sortable: false,
          value: "email",
        },
        {
          text: this.$t('Username'),
          align: "start",
          sortable: false,
          value: "username",
        },
        {
          text: this.$t('CreatedAt'),
          align: "start",
          sortable: true,
          value: "createdAt",
        },
        {
          text: this.$t('Actions'),
          align: "center",
          sortable: false,
          value: "action",
        },
        {
          text: this.$t('Profile'),
          align: "center",
          sortable: false,
          value: "profile",
        },
        {
          text: this.$t('Gender'),
          align: "start",
          sortable: false,
          value: "gender",
        },
        {
          text: this.$t('Preference'),
          align: "start",
          sortable: false,
          value: "preference",
        },
        // {
        // 	text: this.$t('SendMessage'),
        // 	align: "center",
        // 	sortable: false,
        // 	value: "action",
        // },
      ],
      mPpassword: "",
      pinRules: [
        (v) => !!v || "Pin is required",
        (v) => (v && v.length <= 25) || "pin must be less than 5 characters",
      ],
      typeRules: [(v) => !!v || "Type is required"],
      passRules: [
        (v) => !!v || "Password is required",
        (v) => (v && v.length >= 8) || "Name must be atleast 8 characters",
      ],
      searchTargetUser: [],
      message: "",
      newModDialog: false,
      modTypes: ["admin", "agent"],
      modType: "",
      loadingAc: false,
      subject: "",
      filterItems: [
        { label: "Online Users", value: "online" },
        // { label: "Offline Users", value: "offline" },
        { label: "All users", value: "all" },
        { label: "Male Users", value: "male" },
        { label: "Female Users", value: "female" },
        { label: "Gay Users", value: "gay" },
        { label: "Lesbian Users", value: "lesbian" },
        { label: "Transgender Users", value: "transgender" },
        { label: "All Active Users", value: "active" },
        { label: "Inactive Users", value: "inactive" },
      ],
      gender:[
        { label: "Male Users", value: "male" },
        { label: "Female Users", value: "female" },
        { label: "Transgender Users", value: "trans" },
        { label: "Other", value: "other" },
      ],
      pref:[
        { label: "Gay", value: "gay" },
        { label: "Straight", value: "straight" },
        { label: "Lesbian", value: "lesbain" },
        { label: "Transman", value: "transman" },
        { label: "Transwomen", value: "transwomen" },
        { label: "Pan", value: "pan" },
        { label: "Non-Binary", value: "nonbinary" },
        { label: "Other", value: "other" },
        { label: "Bisexual", value: "bisexual" },
      ],
      actionItems: [
        "Send mass E-mail",
        "Send mass spam",
        "Send Likes",
        "Send Poke",
        "DMI Operations"
      ],
      group: null,
      items: [],
      pin: "",
      search: "",
      selectLx: "",
      select: null,
      allSelected: [],
      loadingMassSend: false,
      showConfirmDialog: false,
      showConfirmDialogSPP: false,
      showConfirmDialogSPM: false,
      showConfirmDialogSPL: false,
      showOtherOptionsDialog:false,
      content: ` <p><img src="https://flirttool.com/upload/bennerimg.png" alt=""></p><h1>WELCOME TO FLIRTTOOL</h1><p>Love Knows No Borders</p><h3><span style="color: #e75480">TITLE SUBJECT</span></h3><p><span style="font-size: 18px">Here is the main message that needs to be here <br></span></p><p><img src="https://flirttool.com/themes/default/massets/images/logo/logo.png"></p><h2><span style="font-size: 14px"><span style="color: rgb(235, 47, 150)"><strong><a href="https://www.flirttool.com/" rel="noopener noreferrer nofollow">WWW.FLIRTTOOL.COM</a></strong></span></span></h2><p><span style="font-size: 14px"><span style="color: rgb(47, 84, 235)"><a href="https://web.facebook.com/flirttool" rel="noopener noreferrer nofollow">Facebook</a></span></span><span style="font-size: 12px"><a href="https://web.facebook.com/flirttool" rel="noopener noreferrer nofollow"><u> </u></a><strong>| </strong><span style="color: rgb(235, 47, 150)"><strong><a href="https://www.instagram.com/flirttool/" rel="noopener noreferrer nofollow">Instagram</a></strong></span><strong> | </strong></span><span style="font-size: 14px"><span style="color: rgb(245, 34, 45)"><strong><a href="https://www.youtube.com/channel/UC9xQrnNCDf75e2yaXsbVSWA" rel="noopener noreferrer nofollow">YouTube</a></strong></span></span><span style="font-size: 12px"><strong> | </strong></span><span style="font-size: 14px"><span style="color: rgb(47, 84, 235)"><strong><a href="https://twitter.com/flirttool" rel="noopener noreferrer nofollow">Twitter</a></strong></span></span></p><p><br></p><p><img src="https://flirttool.com/upload/Footer-.png" alt=""></p>`,
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchUsers();
      },
    },
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
    searchF(val) {
      val && val !== this.select && this.querySelections(val);
    },
  },
  created() {
    this.fetchUsers();
    this.$apollo.queries.searchTargetUser.refetch();
  },
  apollo: {
    getAllUsersPaginated: {
      query: USERS_PAGINATED,
      variables() {
        let input = {
          page: this.options.page,
          pageSize: this.options.itemsPerPage,
        }
        if(this.filterType == 'gender') {
          input.filterKey = 'gender'
          input.filter = this.genderFilter
        } 
        if(this.filterType == 'pref') {
          input.filterKey = 'pref'
          input.filter = this.prefrenceFilter
        } 
        if(this.filterType == 'both') {
          input.filterKey = 'both'
          input.filter = [this.genderFilter,this.prefrenceFilter].join(",")
        }
        return {
          input: input,
        };
      },
      result({
        data: {
          getAllUsersPaginated: { total, data },
        },
      }) {
        this.totalCount = total;
        this.users = data;
        this.loadingData = false;
      },
    },
  },
  methods: {
    picUploaded(e) {
      this.fileupload = e.target.files[0];
      console.log(this.$refs.toUploadImage)
      var file = e.target.files[0]
    if (file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload =  (evt) => {
          this.$refs.toUploadImage.src = evt.target.result
          console.log(evt.target.result)
        }
      }
      // this.loadingMassSend = true;
      // this.$apollo
      //   .mutate({
      //     mutation: SEND_SPAM_MESSAGE,
      //     variables: {
      //       input: {
      //         moderator: this.select,
      //         targets: this.allSelected.map((e) => e.username),
      //         body: "",
      //       },
      //       file: e.target.files[0],deletePout succesfully", "success");
      //   });
    },
    addNewFake() {},
     deletePools(){
      this.$toast.warning(`Click here to confirm !!! `,  { 
        position: "top-center",
        closeButton:false,
        maxToasts:1,
        onClick: async () => {
          let {data,errors} = await this.$apollo.mutate({
              mutation: DELETE_POOLS,
            })
            if(data.deleteNormalPools){
              this.showToaster("Pools Cleared Successfully !!", "success");
            }
            if(errors){
               this.showToaster("There was an error !!", "success");
            }
          } 
        })
    },
    parseDate(date) {
      const dtObj = new Date(parseInt(date));
      return `${dtObj.toLocaleDateString()} | ${dtObj.toLocaleTimeString()}`;
    },
    filterSelected(filterType) {
      if(this.prefrenceFilter && this.genderFilter){ 
        this.filterType = 'both'
      }else {
        this.filterType = filterType
      }
      console.log(filterType)
      this.fetchUsers();
      // console.log(this.selectedFilter);
    },
    reload() {
      this.fetchUsers();
    },
    querySelections(v) {
      console.log(v, "KK");
      this.selectLx = v;
      this.$apollo
        .query({
          query: SEARCH_TARGET,
          variables: {
            input: v,
          },
        })
        .then(({ data: { searchTargetUser } }) => {
          this.searchTargetUser = searchTargetUser;
        });
    },
    isJsonString(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },

    getCountryDetails(obj) {
      if (!obj) {
        return "None";
      }
      const response = obj.includes("<!DOCTYPE html>")
        ? "None"
        : JSON.parse(obj);
      return response;
    },
    sendMessage(usr) {
      this.actionType = "chat";
      this.openDrawer = true;
      this.selectedUser = usr;
    },
    sendEmail(usr) {
      this.openDrawer = true;
      this.selectedUser = usr;
      this.actionType = "email";
    },
    showToaster(message, type = "info") {
      const data = {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      };
      switch (type) {
        case "info":
          this.$toast.info(message, data);
          break;
        case "success":
          this.$toast.success(message, data);
          break;
        case "warning":
          this.$toast.warning(message, data);
          break;
        case "error":
          this.$toast.error(message, data);
          break;

        default:
          this.$toast.info(message, data);
          break;
      }
    },

    fetchUsers() {
      this.loadingData = true;
      this.$apollo.queries.getAllUsersPaginated.refetch();
    },
    actionSelected() {
      if(this.selectedAction == 'DMI Operations'){
        this.selectedAction = null;
        this.showOtherOptionsDialog = true
        return 
      }
      if (this.allSelected.length == 0) {
        this.selectedAction = null;
        this.showToaster("Select users first", "error");
      } else {
        if (this.selectedAction == "Send mass spam") {
          this.showConfirmDialogSPM = true;
        } else if (this.selectedAction == "Send Likes") {
          this.showConfirmDialogSPL = true;
        } else if (this.selectedAction == "Send Poke") {
          this.showConfirmDialogSPP = true;
        } else {
          this.showConfirmDialog = true;
        }
      }
      // console.warn(this.allSelected);
    },
    closeConfirmDialogSPP() {
      this.showConfirmDialogSPP = false;
      this.selectedAction = null;
    },
    closeConfirmDialogSPL() {
      this.showConfirmDialogSPL = false;
      this.selectedAction = null;
    },
    closeConfirmDialogSPM() {
      this.showConfirmDialogSPM = false;
      this.selectedAction = null;
    },
    closeConfirmDialog() {
      this.showConfirmDialog = false;
      this.selectedAction = null;
    },
    closeOtherOptionsDialog() {
      this.showOtherOptionsDialog = false
    },
    searchUsers() {
      this.loadingSearch = true;
      this.$apollo
        .query({
          query: SEARCH_USR,
          variables: {
            input: this.searched,
          },
        })
        .then(({ data: { searchAllUser } }) => {
          this.users = searchAllUser;
          // this.items = data.users;
          // console.warn(this.items);
          //   console.warn(data.users);
        })
        .catch((e) => {
          this.showToaster("Error searching for user.", "error");
          console.error(e);
        })
        .finally(() => {
          this.loadingSearch = false;
          this.searched = "";
        });
    },
    sendMassPoke() {
      let allEmails = [];
      this.loadingMassLikes = true;
      this.allSelected.forEach((e) => {
        allEmails.push(e.username);
      });
      this.$apollo
        .mutate({
          mutation: SEND_NASS_POKE,
          variables: {
            input: {
              moderator: this.selectL,
              targets: allEmails,
            },
          },
        })
        .finally(() => {
          this.closeConfirmDialogSPP();
          this.showToaster("Poke sent out succesfully", "success");
          this.loadingMassLikes = false;
        });
    },
    sendMassLikes() {
      let allEmails = [];
      this.loadingMassLikes = true;
      this.allSelected.forEach((e) => {
        allEmails.push(e.username);
      });
      console.warn(
        {
          targets: allEmails,
          from: this.selectL,
        },
        "SS"
      );
      this.$apollo
        .mutate({
          mutation: SEND_MASS_LIKE,
          variables: {
            input: {
              moderator: this.selectL,
              targets: allEmails,
            },
          },
        })
        .finally(() => {
          this.loadingMassLikes = false;
          this.closeConfirmDialogSPL();
          this.showToaster("Like sent out succesfully", "success");
        });
    },
    saveNewPin() {
      if (this.$refs.form.validate()) {
        this.$apollo
          .mutate({
            mutation: GEN_MOD,
            variables: {
              input: {
                pin: this.pin,
                password: this.mPpassword,
                modType: this.modType,
              },
            },
          })
          .then(() => {
            this.newModDialog = false;
            this.showToaster("Pin Added succesfully !!!", "success");
          })
          .catch(() => {
            this.showToaster("Error creating PIN", "error");
          });
      }
    },
    sendMassSpam() {
      this.loadingMassSend = true;
      this.$apollo
        .mutate({
          mutation: SEND_SPAM_MESSAGE,
          variables: {
            file: this.fileupload,
            input: {
              moderator: this.select,
              targets: this.allSelected.map((e) => e.username),
              body: this.message,
            },
          },
        })
        .finally(() => {
          this.selectedAction = null;
          this.loadingMassSend = false;
          this.showConfirmDialogSPM = false;
          this.allSelected = []
          this.showToaster("Spam message sent out succesfully", "success");
        });
    },
    popUsr(item) {
      const cnf = confirm(
        `Are you sure you would like to remove ${item.username} ?`
      );
      if (cnf) {
        this.$apollo
          .mutate({
            mutation: REMOVE_ACCOUNT,
            variables: {
              usr: JSON.stringify(item.id),
            },
          })
          .finally(() => {
            this.showToaster("user removed succesfully", "success");
            this.fetchUsers();
            this.$apollo.queries.searchTargetUser.refetch();
          });
      }
      console.warn(item, "item");
    },
    activateAcc(item) {
      this.loadingAct = true;
      this.$apollo
        .mutate({
          mutation: ACTIVATE_ACC,
          variables: {
            email: item.email,
          },
        })
        .then(() => {
          this.showToaster("Account Verified Successfully", "success");
          this.loadingAct = false;
          this.fetchUsers();
        })
        .catch((e) => {
          console.error(e);
          this.loadingAct = false;
        });
    },
    getURL(url) {
      const domain = PRODUCTION
        ? "https://flirttool.com/media/" + url
        : "http://" + location.hostname + ":4000/media/" + url;
      // const domain = "https://testflirttool.com/media/" + url;
      return domain;
    },
    toUser(username) {
      window.open(`https://${this.$store.state.domain}/p/${username}`);
    },
    sendMassEmail() {
      let allEmails = [];
      this.loadingMassSend = true;
      this.allSelected.forEach((e) => {
        allEmails.push({ email: e.email, user: e.id });
      });
      axios
        .post(
          "https://flirttool.com/aj/moderation/send_mass_email",
          {
            targets: allEmails,
            subject: this.subject,
            message: this.content,
          },
          {
            headers: { Authorization: this.authKey },
          }
        )
        .then(({ data }) => {
          if (data) {
            this.showConfirmDialog = false;
            this.showToaster(data.message, "success");
          }
        })
        .finally(() => {
          this.selectedAction = null;
          this.loadingMassSend = false;
        });
    },
  },
};
</script>
<style>
.v-data-footer__icons-before {
  margin-left: 20px;
}
.v-data-footer {
  margin-top: 20px;
}
.searchField {
  width: 50%;
}
</style>
import gql from "graphql-tag";

export const GET_SELLING_PIC_CATEGORY = gql`
  query getSellingPicCategory {
    getSellingPicCategory {
      category {
        id
        category
      }
      imageCount
    }
  }
`;

export const GET_COMMUNITY_GALLERIES = gql`
  query getCommunityGalleries($user: Float) {
    getCommunityGalleries(user: $user) {
      id
      name
      poster
    }
  }
`;

export const GET_POOL_INFORMATION = gql`
  query getPoolsInformation {
    getPoolsInformation {
      id
      locked_mod {
        id
        pin
      }
      m_one {
        username
        language
      }
      m_two {
        username
        language
      }
    }
  }
`;
export const RELEASE_MODERATOR_POOL = gql`
  mutation releaseModeratorPool {
    releaseModeratorPool
  }
`;
export const GET_CLOUD_PUT_URL = gql`
  query getCloudPutUrl(
    $filename: String!
    $mimetype: String!
    $bucket: String
  ) {
    getCloudPutUrl(filename: $filename, mimetype: $mimetype, bucket: $bucket)
  }
`;

export const GET_CLOUD_URL = gql`
  query getCloudUrl($filename: String!) {
    getCloudUrl(filename: $filename)
  }
`;
//getCloudPutUrl

export const GET_GALLERY_MEDIA = gql`
  query getGalleryMedia($gallery: Float!) {
    getGalleryMedia(gallery: $gallery) {
      id
      name
      media {
        id
        title
        is_paid
        description
        tags {
          name
        }
        new
        blocked_in
        mostViewed
        preview_url
        thumbnail
        potrait
        url
        cost
        thumbnail
        potrait
        format
        mediaCategory
        mediaCloudName
      }
    }
  }
`;

export const ADD_COMMUMITY_GALLERY = gql`
  mutation createCommunityGallery($name: String!, $file: Upload!) {
    createCommunityGallery(name: $name, file: $file) {
      id
      name
      poster
    }
  }
`;

export const UPDTAE_COMMUMITY_GALLERY = gql`
  mutation updateCommunityGallery($id: Float!, $name: String!, $file: Upload!) {
    updateCommunityGallery(id: $id, name: $name, file: $file)
  }
`;
export const ACK_ADMIN_WARNING = gql`
  mutation ackowledgeAdminWarning {
    ackowledgeAdminWarning
  }
`;

export const UPDTAE_COMMUMITY_MEDIA = gql`
  mutation updateCommunityMedia($input: UpdateCommunityMediaType!) {
    updateCommunityMedia(input: $input) {
      id
      title
      is_paid
      description
      tags {
        name
      }
      preview_url
      url
      cost
      format
      mediaCategory
      mediaCloudName
      thumbnail
      potrait
    }
  }
`;

export const UPDATE_PREVIEW_URL = gql`
  mutation updatePreviewUrl($media: Float!, $previewUrl: String!) {
    updatePreviewUrl(media: $media, previewUrl: $previewUrl) {
      id
      title
      is_paid
      description
      tags {
        name
      }
      preview_url
      url
      cost
      format
      mediaCategory
      mediaCloudName
      thumbnail
      potrait
    }
  }
`;

export const UPDATE_MEDIA_THUMBNAIL = gql`
  mutation updateMediaThumbnail($media: Float!, $thumbnail: String!) {
    updateMediaThumbnail(media: $media, thumbnail: $thumbnail) {
      id
      title
      is_paid
      description
      tags {
        name
      }
      preview_url
      url
      cost
      format
      mediaCategory
      mediaCloudName
      thumbnail
      potrait
    }
  }
`;

export const UPDATE_MEDIA_POTRAIT = gql`
  mutation updateMediaPotrait($media: Float!, $potrait: String!) {
    updateMediaPotrait(media: $media, potrait: $potrait) {
      id
      title
      is_paid
      description
      tags {
        name
      }
      preview_url
      url
      cost
      format
      mediaCategory
      mediaCloudName
      thumbnail
      potrait
    }
  }
`;

export const UPDATE_MEDIA_BLOCKED_REGIONS = gql`
  mutation updateBlockedRegions($media: Float!, $blocked_in: String!) {
    updateBlockedRegions(media: $media, blocked_in: $blocked_in) {
      id
      title
      is_paid
      description
      tags {
        name
      }
      preview_url
      url
      cost
      format
      blocked_in
      mediaCategory
      mediaCloudName
      thumbnail
      potrait
    }
  }
`;

// export const ADD_COMMUNITY_GALLERY_MEDIA = gql`
//   mutation addCommunityMedia(
//     $gallery: Float!
//     $cost: Float!
//     $is_paid: Boolean!
//     $mediaCloudName:String!
//   ) {
//     addCommunityMedia(
//       gallery: $gallery
//       cost: $cost
//       is_paid: $is_paid
//       mediaCloudName:$mediaCloudName
//     ) {
//       id
//       url
//       cost
//       format
//     }
//   }
// `;

export const ADD_COMMUNITY_GALLERY_MEDIA = gql`
  mutation addCommunityMedia(
    $gallery: Float!
    $cost: Float!
    $is_paid: Boolean!
    $mediaCloudName: String!
    $mimetype: String!
  ) {
    addCommunityMedia(
      gallery: $gallery
      cost: $cost
      is_paid: $is_paid
      mediaCloudName: $mediaCloudName
      mimetype: $mimetype
    ) {
      id
      url
      cost
      format
      mediaCloudName
      format
      is_paid
      cloudUrl
    }
  }
`;
export const GET_SELLING_PICS = gql`
  query getSellingImages($category: Float!) {
    getSellingImages(category: $category) {
      id
      url
    }
  }
`;
export const DELETE_SELLING_PIC = gql`
  mutation deleteSellingPic($id: Float!) {
    deleteSellingPic(id: $id)
  }
`;

export const DELETE_COMMUNITY_MEDIA = gql`
  mutation deleteCommunityMedia($id: Float!) {
    deleteCommunityMedia(id: $id)
  }
`;
export const ADD_SELLING_PIC_CATEGORY = gql`
  mutation addSellingPicCategory($category: String!) {
    addSellingPicCategory(category: $category) {
      id
      category
    }
  }
`;

export const ADD_SELLING_PICS = gql`
  mutation addSellingImage($category: Float!, $files: [Upload!]!) {
    addSellingImage(category: $category, files: $files) {
      id
      url
    }
  }
`;

export const DELETE_SELLING_PIC_CATEGORY = gql`
  mutation deleteSellingPicCategory($id: Float!) {
    deleteSellingPicCategory(id: $id)
  }
`;

export const DELETE_COMMUNITY_GALLERY = gql`
  mutation deleteCommunityGallery($id: Float!) {
    deleteCommunityGallery(id: $id)
  }
`;

export const MOD_QUERY = gql`
  mutation modLogin(
    $password: String!
    $pin: String!
    $datingCommunity: String!
  ) {
    modLogin(
      password: $password
      pin: $pin
      datingCommunity: $datingCommunity
    ) {
      token
      domain
      moderator {
        id
        active
        status
        pin
        accountType
        warningAck
      }
    }
  }
`;

export const MODEL_MOD_QUERY = gql`
  mutation modelModLogin(
    $password: String!
    $username: String!
    $datingCommunity: String!
  ) {
    modelModLogin(
      password: $password
      username: $username
      datingCommunity: $datingCommunity
    ) {
      token
      domain
      user {
        id
        username
      }
    }
  }
`;

export const FETCH_CONVO_QUEUE = gql`
  query fetchConvQueue($pool: String!, $cnv: String!) {
    fetchConvQueue(pool: $pool, cnv: $cnv) {
      conversation {
        id
        ModJobTranslations{
          en
          ge
          tur
          fr
          po
          sp
          it
          pot
          sch
          dan
          fini
          seb
          russ
          jap
          kor
          thai
          ung
          inr
        }
        
        ModWSTranslations{
          en
          ge
          tur
          fr
          po
          sp
          it
          pot
          sch
          dan
          fini
          seb
          russ
          jap
          kor
          thai
          ung
          inr
        }
        ModRSTranslations{
          en
          ge
          tur
          fr
          po
          sp
          it
          pot
          sch
          dan
          fini
          seb
          russ
          jap
          kor
          thai
          ung
          inr
        }
        ModSFTranslations{
          en
          ge
          tur
          fr
          po
          sp
          it
          pot
          sch
          dan
          fini
          seb
          russ
          jap
          kor
          thai
          ung
          inr
        }
        ModPRTranslations{
          en
          ge
          tur
          fr
          po
          sp
          it
          pot
          sch
          dan
          fini
          seb
          russ
          jap
          kor
          thai
          ung
          inr
        }
        ModIPTranslations{
          en
          ge
          tur
          fr
          po
          sp
          it
          pot
          sch
          dan
          fini
          seb
          russ
          jap
          kor
          thai
          ung
          inr
        }
        ModFPPTranslations{
          en
          ge
          tur
          fr
          po
          sp
          it
          pot
          sch
          dan
          fini
          seb
          russ
          jap
          kor
          thai
          ung
          inr
        }
        TargetJobTranslations {
          en
          ge
          tur
          fr
          po
          sp
          it
          pot
          sch
          dan
          fini
          seb
          russ
          jap
          kor
          thai
          ung
          inr
        }
        TargetWSTranslations {
          en
          ge
          tur
          fr
          po
          sp
          it
          pot
          sch
          dan
          fini
          seb
          russ
          jap
          kor
          thai
          ung
          inr
        }
        TargetRSTranslations {
          en
          ge
          tur
          fr
          po
          sp
          it
          pot
          sch
          dan
          fini
          seb
          russ
          jap
          kor
          thai
          ung
          inr
        }
        TargetSFTranslations {
          en
          ge
          tur
          fr
          po
          sp
          it
          pot
          sch
          dan
          fini
          seb
          russ
          jap
          kor
          thai
          ung
          inr
        }
        TargetPRTranslations {
          en
          ge
          tur
          fr
          po
          sp
          it
          pot
          sch
          dan
          fini
          seb
          russ
          jap
          kor
          thai
          ung
          inr
        }
        TargetIPTranslations {
          en
          ge
          tur
          fr
          po
          sp
          it
          pot
          sch
          dan
          fini
          seb
          russ
          jap
          kor
          thai
          ung
          inr
        }
        TargetFPPTranslations {
          en
          ge
          tur
          fr
          po
          sp
          it
          pot
          sch
          dan
          fini
          seb
          russ
          jap
          kor
          thai
          ung
          inr
        }
        m_one {
          id
          username
          usrType
          email
          base_profile {
            id
            firstName
            lastName
          }
          profilePic
          createdAt
          looks {
            id
            ethnicity
            body_type
            gender
            preference
            height
            hair_color
          }
          personality {
            id
            character
            children
            friends
            pets
          }
          lifeStyle {
            id
            live_with
            car
            religion
            smoke
            drinking
            travel
          }
          tempLocationDetail
          user_basic {
            bio
            location
            relationship
            workstatus
            education_level
          }
          credits
        }
        mod_notes
        target_notes
        m_two {
          id
          username
          usrType
          email
          base_profile {
            id
            firstName
            lastName
          }
          profilePic
          createdAt
          looks {
            id
            ethnicity
            body_type
            gender
            preference
            height
            hair_color
          }
          personality {
            id
            character
            children
            friends
            pets
          }
          lifeStyle {
            id
            live_with
            car
            religion
            smoke
            drinking
            travel
          }
          tempLocationDetail
          credits
        }
      }
      mapping {
        id
        location
      }
    }
  }
`;

export const FETCH_ALL_MESSAGES = gql`
  query allMessagesMod($conv: Float!) {
    allMessagesMod(conv: $conv) {
      messages {
        id
        status
        body
        translations {
          en
          ge
          tur
          fr
          po
          sp
          it
          pot
          sch
          dan
          fini
          seb
          russ
          jap
          kor
          thai
          ung
          inr
        }
        conversation {
          id
        }
        sender {
          id
          username
        }
        attachment_type
        attachment_url
        message_type
        modReported
        paid
        createdAt
        createdAt
        mod {
          id
          pin
        }
      }
    }
  }
`;

export const SEND_MODED_MESSAGE = gql`
  mutation sendModMessage($input: ModChatMessageInput!, $file: Upload) {
    sendModMessage(input: $input, file: $file) {
      id
      status
      body
      mod {
        id
        pin
      }
      attachment_url
      message_type
      paid
      modReported
      sender {
        id
        username
        profilePic
        base_profile {
          id
          firstName
          lastName
        }
        createdAt
      }
      createdAt
    }
  }
`;

export const REPORT_MOD_MESSAGE = gql`
  mutation reportModMessage(
    $comment: String!
    $message: String!
    $reportedAgainst: String!
  ) {
    reportModMessage(
      comment: $comment
      message: $message
      reportedAgainst: $reportedAgainst
    )
  }
`;

export const MOD_MESASGE_ADDED = gql`
  subscription {
    modMessageAdded {
      id
      m_one {
        id
        username
        usrType
        email
        base_profile {
          id
          firstName
          lastName
        }
        profilePic
        createdAt
      }
      mod_notes
      target_notes
      m_two {
        id
        username
        usrType
        email
        base_profile {
          id
          firstName
          lastName
        }
        profilePic
        createdAt
      }
    }
  }
`;

export const ACTIVE_ONLINE_MODS = gql`
  subscription {
    onlineModsSubscription {
      online_mods
    }
  }
`;
export const LOGOUT = gql`
  mutation {
    logout
  }
`;

export const ONLINE_MODS = gql`
  query {
    onlineMods {
      id
      status
      active
      pin
      accountType
    }
  }
`;

export const TRANSFER_DIALOG = gql`
  mutation transferDialog($input: DialogTransferInput!) {
    transferDialog(input: $input)
  }
`;

export const AGENT_MOD_MESSAGES = gql`
  query agentModMessages($input: ModActivityFilterInput!) {
    agentModMessages(input: $input) {
      outs
      ins
      total
      charLen
      poolsCount
      vipPoolCount
    }
  }
`;

export const CHANGE_ONLINE_MOD_STATUS = gql`
  query changeOnlineModStatus($status: String!) {
    changeOnlineModStatus(status: $status)
  }
`;

export const POP_FAKE_PICS = gql`
  mutation popFakeAccountProfilePhotos($pic: String!) {
    popFakeAccountProfilePhotos(pic: $pic)
  }
`;
export const UPDATE_FAKE_PROFILE_PHOTOS = gql`
  mutation addFakeAccountProfilePhotos($file: Upload!, $user: String!) {
    addFakeAccountProfilePhotos(file: $file, user: $user)
  }
`;

export const UPDATE_FAKE_PROFILE_PIC = gql`
  mutation updateFakeAccountProfilePic($file: Upload!, $user: String!) {
    updateFakeAccountProfilePic(file: $file, user: $user)
  }
`;

export const SET_USER_STATUS = gql`
  mutation setUserStatus($id: String!, $active: Boolean!) {
    setUserStatus(id: $id, active: $active)
  }
`;

export const FAKE_ACCOUNTS = gql`
  query fakeAccounts($input: PaginationInput!) {
    fakeAccounts(input: $input) {
      page
      total
      total_pages
      data {
        id
        active
        tempLocationDetail
        profileSetupStep
        username
        email
        usrType
        photos {
          id
          image
          unsafe
        }
        looks {
          id
          gender
          ethnicity
          body_type
          preference
          hair_color
        }
        personality {
          character
          children
          children
          friends
          pets
        }
        lifeStyle {
          id
          live_with
          car
          religion
          smoke
          travel
          drinking
        }
        base_profile {
          id
          phone
          firstName
          lastName
        }
        user_basic {
          bio
          relationship
          workstatus
          education_level
        }

        profilePic
        createdAt
      }
    }
  }
`;

export const USERS_PAGINATED = gql`
  query getAllUsersPaginated($input: PaginationInput!) {
    getAllUsersPaginated(input: $input) {
      page
      total
      total_pages
      data {
        id
        active
        tempLocationDetail
        profileSetupStep
        username
        email
        usrType
        looks {
          id
          gender
          preference
        }
        base_profile {
          id
          phone
          firstName
          lastName
        }
        profilePic
        createdAt
      }
    }
  }
`;

export const ADD_REPORT = gql`
  mutation addModReport($input: ModReportInput!) {
    addModReport(input: $input) {
      id
      comment
    }
  }
`;

export const ADD_REPORT_ACTION = gql`
  mutation addReportWarning(
    $reportId: String!
    $actionType: String!
    $warnText: String
    $blockHours: String
  ) {
    addReportWarning(
      reportId: $reportId
      actionType: $actionType
      warnText: $warnText
      blockHours: $blockHours
    )
  }
`;

export const ADMIN_MOD_ACTIONS = gql`
  mutation adminModeratorActions(
    $reportId: String!
    $actionType: String!
    $comment: String
  ) {
    adminModeratorActions(
      reportId: $reportId
      actionType: $actionType
      comment: $comment
    )
  }
`;
export const GET_ALL_MOD_REPORTS = gql`
  query modReports {
    modReports {
      id
      moderator {
        id
        pin
      }
      user {
        id
        username
        unblockTimestamp
        warnedByAdmin
        warningAck
      }
      comment
      reason
      warningAcknowledged
      warnText
      reportActionType
    }
  }
`;
export const UNBLOCK_USER = gql`
  mutation unblockUser($reportId: String!) {
    unblockUser(reportId: $reportId)
  }
`;
export const GET_ALL_REPORTED_MESSAGES = gql`
  query getAllReportedMessages {
    getAllReportedMessages {
      id
      warnedShotNo
      resolved
      adminAction
      reportedBy {
        pin
        warnedByAdmin
      }
      reportedAgainst {
        id
        pin
        warnedByAdmin
        warningAck
      }
      message {
        body
      }
      comment
    }
  }
`;
//modReports
export const UPDATE_NOTE = gql`
  mutation updateProfileNote($input: ProfileNoteUpdate!) {
    updateProfileNote(input: $input)
  }
`;

export const UPDATE_IDRIS_NOTES = gql`
  mutation updateProfileNoteIdris($input: ProfileNoteUpdateIdris!) {
    updateProfileNoteIdris(input: $input)
  }
`;

export const GEN_MOD = gql`
  mutation generate_mod($input: NewModerationInput!) {
    generate_mod(input: $input) {
      token
    }
  }
`;

export const UPDATE_MODERATOR = gql`
  mutation updateModerator($id: String!, $role: String, $password: String) {
    updateModerator(id: $id, role: $role, password: $password)
  }
`;

export const TRANSACTIONS_STAT = gql`
  query {
    transactionData {
      total
      today
      thisWeek
      transactions {
        id
        amount
        paymentSource
        transactionName
        transactionStatus
        transactionId
        package {
          id
          packageName
        }
        user {
          id
          username
          base_profile {
            id
            firstName
            lastName
          }
        }
        createdAt
      }
    }
  }
`;

export const ALL_PACKAGES = gql`
  query {
    all_packagesMod {
      id
      packageId
      packageName
      createdAt
      cover
      packageDesc
      creditValue
      timeValue
      packageType
      price
      euroPrice
      poundPrice
      tryPrice
    }
  }
`;

export const ADD_PACKAGE = gql`
  mutation create_package($input: PackageInput!, $file: Upload!) {
    create_package(input: $input, file: $file) {
      id
      packageName
    }
  }
`;

export const REMOVE_PACKAGE = gql`
  mutation removePackage($input: String!) {
    removePackage(input: $input) {
      id
    }
  }
`;

export const UPDATE_PACKAGE = gql`
  mutation updatePackage($input: PackageUpdateInput!, $file: Upload!) {
    updatePackage(input: $input, file: $file) {
      id
    }
  }
`;
export const GET_POOL_COUNT = gql`
  query getPoolInfo {
    getPoolInfo {
      id
      pool_type
      locked_mod {
        id
      }
    }
  }
`;
export const AGENT_ANALYTICS = gql`
  query allAgentsAnalytics($input: ModActivityFilterInput!) {
    allAgentsAnalytics(input: $input) {
      outs
      ins
      spams
      allSpamsArray{
        created
      }
      total
      earnings
      charLen
      mod {
        id
        pin
        status
        active
        languages
        accountType
        parentMod {
          pin
          accountType
        }
        createdAt
      }
      allInsArray {
        created
      }
      allOutsArray {
        created
      }
    }
  }
`;

export const SEARCH_TARGET = gql`
  query searchTargetUser($input: String!) {
    searchTargetUser(input: $input) {
      id
      username
      profilePic
      usrType
      base_profile {
        firstName
        lastName
      }
    }
  }
`;
export const GET_ALL_GIFTS = gql`
  query {
    gifts {
      id
      asset
      cost
    }
  }
`;
export const DELETE_POOLS = gql`
  mutation deleteNormalPools {
    deleteNormalPools
  }
`;

export const UPDATE_MOD_LANG = gql`
  mutation updateModLang($languages:String!,$mod:Float!) {
    updateModLang(languages:$languages,mod:$mod)
  }
`;

export const GET_DAILY_LIVE_MESSAGES = gql`
  query {
    getDailyConversationsLive {
      body
      convo
      fresh
      m_one {
        username
        profilePic
        base_profile {
          firstName
          lastName
        }
      }
      moderator {
        id
        pin
      }
      m_two {
        username
        profilePic
        base_profile {
          firstName
          lastName
        }
      }
      messageStamp
    }
  }
`;

export const SEND_NASS_POKE = gql`
  mutation sendSpamPokes($input: SpamPokeLikeInput!) {
    sendSpamPokes(input: $input)
  }
`;

export const SEND_MASS_LIKE = gql`
  mutation sendSpamLikes($input: SpamPokeLikeInput!) {
    sendSpamLikes(input: $input)
  }
`;

export const SEND_SPAM_MESSAGE = gql`
  mutation sendSpamMessage($input: MessageSpamInput!, $file: Upload) {
    sendSpamMessage(input: $input, file: $file)
  }
`;

export const NEW_OUT_CAPTURED = gql`
  subscription {
    newOutCaptured {
      body
      convo
      fresh
      m_one {
        username
        profilePic
        base_profile {
          firstName
          lastName
        }
      }
      moderator {
        id
        pin
      }
      m_two {
        username
        profilePic
        base_profile {
          firstName
          lastName
        }
      }
      messageStamp
    }
  }
`;

export const COUPONS = gql`
  query {
    coupons {
      id
      coupon
      discount
      used
      moderator {
        id
        pin
      }
      package {
        id
        packageName
      }
      active
      description
      createdAt
      couponType
    }
  }
`;
export const POP_MASS_CPN = gql`
  mutation popMassCoupon($input: MassCouponInput!) {
    popMassCoupon(input: $input)
  }
`;
export const ADD_COUPON = gql`
  mutation createCoupon($input: NewCouponInput!) {
    createCoupon(input: $input)
  }
`;

export const POP_CPN = gql`
  mutation popCoupon($input: String!) {
    popCoupon(input: $input)
  }
`;

export const UPDATE_CPN_STAT = gql`
  mutation updateCouponStatus($stat: Boolean!, $input: String!) {
    updateCouponStatus(stat: $stat, input: $input)
  }
`;
export const UPDATE_MASS_CPN = gql`
  mutation updateMassStat($input: MassCouponUpdateInput!) {
    updateMassStat(input: $input)
  }
`;

export const SET_IKM_STAT = gql`
  mutation setIkm($affilate: Float!, $stat: Boolean!) {
    setIkm(affilate: $affilate, stat: $stat)
  }
`;

export const SET_IKM_SUPER = gql`
  mutation setIkmSupervisor($affilate: Float!, $owner: String!) {
    setIkmSupervisor(affilate: $affilate, owner: $owner)
  }
`;

export const SET_IKM_SUPERVISOR = gql`
  mutation setIkmSupervisor($affilate: Float!, $owner: String!) {
    setIkmSupervisor(affilate: $affilate, owner: $owner)
  }
`;

export const AFFILATES = gql`
  {
    affilates {
      id
      fullName
      phone
      email
      ikm
      affilateId
      targetGroups
      supervisor
      address {
        id
        country
        state
        zip
        street
      }
      owned_by {
        id
        fullName
      }
      createdAt
      targetAgeStart
      targetAgeEnd
      bankAccount
      paymentMethod
      affilatePic
      creditPercent
      serviceTypes
    }
  }
`;

export const ADD_AFFILATE = gql`
  mutation createAffilate($input: NewAffilateInput!) {
    createAffilate(input: $input) {
      id
      affilateId
    }
  }
`;

export const UPDATE_AFFILATE = gql`
  mutation updateAffilate($input: NewAffilateInput!) {
    updateAffilate(input: $input)
  }
`;

export const REMOVE_AFFL = gql`
  mutation removeAffilate($input: String!) {
    removeAffilate(input: $input)
  }
`;

export const REMOVE_ACCOUNT = gql`
  mutation removeUser($usr: String!) {
    removeUser(usr: $usr)
  }
`;

export const POP_MOD = gql`
  mutation popMod($input: Float!) {
    popMod(input: $input)
  }
`;

export const UPDATE_STAT = gql`
  mutation updateStatus($mod: Float!, $stat: Boolean!) {
    updateStatus(mod: $mod, stat: $stat)
  }
`;

export const SET_SUPERVISOR_STAT = gql`
  mutation setSupervisor($stat: Boolean!, $affilate: Float!) {
    setSupervisor(stat: $stat, affilate: $affilate)
  }
`;
export const SEARCH_USR = gql`
  query searchAllUser($input: String!) {
    searchAllUser(input: $input) {
      id
      active
      tempLocationDetail
      profileSetupStep
      username
      email
      usrType
      photos {
        id
        image
        unsafe
      }
      looks {
        id
        gender
        ethnicity
        body_type
        preference
        hair_color
      }
      personality {
        character
        children
        children
        friends
        pets
      }
      lifeStyle {
        id
        live_with
        car
        religion
        smoke
        travel
        drinking
      }
      base_profile {
        id
        phone
        firstName
        lastName
      }
      user_basic {
        bio
        relationship
        workstatus
        education_level
      }

      profilePic
      createdAt
    }
  }
`;

export const ACTIVATE_ACC = gql`
  mutation verifyWithEmail($email: String!) {
    verifyWithEmail(email: $email)
  }
`;

export const GET_EVENTS = gql`
  query {
    flirttoolEvents {
      id
      packageId
      name
      detail
      cover
      access_link
      eventDate
      createdAt
      price
    }
  }
`;

export const POP_EVENT = gql`
  mutation popEvent($event: Float!) {
    popEvent(event: $event)
  }
`;

export const CREATE_EVENT = gql`
  mutation createEvent($input: EventInput!, $file: Upload!) {
    createEvent(input: $input, file: $file) {
      id
      access_link
      packageId
      name
      cover
      eventDate
      detail
      price
      createdAt
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation updateEvent($input: EventInput!, $event: Float!) {
    updateEvent(input: $input, event: $event)
  }
`;

export const PROFILE_DETAIL_SETUP_TYPES = gql`
  query {
    profileDetailSetupTypes {
      workStatus
      relationShipStatus
      educationStatus
    }
  }
`;

export const LOOKS_PERSONALITY_TYPES = gql`
  query {
    looksAndPersonalitySetupTypes {
      ethnicity
      bodyType
      hairColor
      character
      children
      friends
      pets
      gender
    }
  }
`;

export const LIFESTYLE_TYPES = gql`
  query {
    lifeStyleAndFavouritesSetupTypes {
      livingWith
      car
      religion
      smoke
      drink
      travel
    }
  }
`;

export const UPDATE_FAKE_ACCOUNT = gql`
  mutation updateFakeAccount($input: FakeAccountUpdateInput!, $user: String!) {
    updateFakeAccount(input: $input, user: $user)
  }
`;
// @Arg("username") username: string,
// @Arg("email") email: string,
// @Arg("password") password: string,
// @Arg("firstName") firstName: string,
// @Arg("lastName") lastName: string,
export const ADD_FAKE_ACCOUNT = gql`
  mutation addFakeAccount(
    $username: String!
    $email: String!
    $password: String!
    $firstName: String!
    $lastName: String!
  ) {
    addFakeAccount(
      username: $username
      email: $email
      password: $password
      firstName: $firstName
      lastName: $lastName
    ) {
      username
    }
  }
`;

export const TOGGLE_NEW_VIDEO = gql`
  mutation toggleNewVideo($media: Float!) {
    toggleNewVideo(media: $media) {
      value
    }
  }
`;

export const TOGGLE_MOST_VIEWED = gql`
  mutation toggleMostViewed($media: Float!) {
    toggleMostViewed(media: $media) {
      value
    }
  }
`;

export const ADD_GIFT = gql`
  mutation addGift($name: String!, $cost: Float!, $file: Upload!) {
    addGift(name: $name, cost: $cost, file: $file) {
      id
      name
      asset
      cost
    }
  }
`;

export const UPDATE_GIFT = gql`
  mutation updateGift($gift: Float!, $name: String!, $cost: Float!) {
    updateGift(gift: $gift, name: $name, cost: $cost)
  }
`;

export const SAVE_PANEL_TAB_SETTINGS = gql`
  mutation savePanelTabSettings($role: String!, $tabs: String!) {
    savePanelTabSettings(role: $role, tabs: $tabs)
  }
`;
export const PANEL_TABS_SETTINGS = gql`
  query getPanelTabSettings {
    getPanelTabSettings {
      id
      moderatorTabs
      subadminTabs
      teamleaderTabs
    }
  }
`;
export const DELETE_GIFT = gql`
  mutation popGift($gift: Float!) {
    popGift(gift: $gift)
  }
`;

export const ALL_GIFTS = gql`
  query {
    gifts {
      id
      name
      asset
      cost
    }
  }
`;

export const GET_MODEL_DATA = gql`
  query getModelData {
    getModelData {
      id
      active
      tempLocationDetail
      profileSetupStep
      username
      email
      usrType
      photos {
        id
        image
        unsafe
      }
      looks {
        id
        gender
        ethnicity
        body_type
        preference
        hair_color
      }
      personality {
        character
        children
        children
        friends
        pets
      }
      lifeStyle {
        id
        live_with
        car
        religion
        smoke
        travel
        drinking
      }
      base_profile {
        id
        phone
        firstName
        lastName
      }
      user_basic {
        bio
        relationship
        workstatus
        education_level
      }

      profilePic
      createdAt
    }
  }
`;

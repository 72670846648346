<template>
  <v-col lg="7" cols="12" style="padding: 0px" data-app>
    <v-dialog v-model="reportDialog" scrollable max-width="500px">
      <v-card>
        <v-form ref="form" v-model="reportFormValid" lazy-validation>
          <div class="mx-3">
            <v-card-title style="padding: 0px" class="pt-5 pb-2"
              >{{$t('Reportauser')}}</v-card-title
            >
            <v-select
              outlined
              class="mt-2"
              label="Reason for your report"
              v-model="reportData.reason"
              :items="reportReasons"
              :rules="[(v) => !!v || 'Reason is required']"
              filled
            >
            </v-select>
            <v-textarea
              v-model="reportData.comment"
              :rules="[(v) => !!v || 'Comment is required']"
              label="Enter your comment here"
              outlined
              filled
            >
            </v-textarea>
          </div>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mb-2 mx-2"
            :ripple="false"
            @click="reportDialog = false"
            large
            text
            >{{$t('Cancel')}}</v-btn
          >
          <v-btn
            class="mb-2"
            @click="validate"
            large
            :ripple="false"
            color="rgb(255 116 79)"
            dark
            >SendReport</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="container">
      <div v-if="conversationId == null" class="chat-head">
        <v-toolbar :height="$vuetify.breakpoint.smAndUp ? 77 : 60" flat>
          <v-btn
            @click="$router.push('/')"
            icon
            v-if="$vuetify.breakpoint.mobile"
          >
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn icon large class="mx-2">
            <!-- <v-icon> mdi-heart-box </v-icon> -->
            <NudesSelect
             @sendNudeImage="sendNudeImage"
             :bus="bus"
            />
          </v-btn>
          <v-hover v-slot:default="{ hover }" v-if="$store.state.noDialogs">
            <v-btn
              :loading="$store.state.loadedTargetProfileStatus == 'loading'"
              large
              :outlined="hover"
              rounded
              elevation="5"
              class="font-weight-bold"
              color="rgb(193 185 18)"
              @click="fetchConvQueue"
            >
              Refresh <v-icon class="px-2">mdi-reload</v-icon>
            </v-btn>
          </v-hover>
          <v-btn
            v-else
            class="font-weight-bold"
            color="rgb(193 185 18)"
            :ripple="false"
            rounded
            depressed
            :loading="$store.state.loadedTargetProfileStatus == 'loading'"
            @click="getNextDialog"
            style="text-decoration: none"
            :disabled="$store.state.noDialogs"
            :dark="!$store.state.noDialogs"
          >
            {{$t('SKIP')}}
            <v-icon class="px-1">mdi-skip-next</v-icon>
          </v-btn>
          <v-btn
            class="font-weight-bold ml-3"
            color="#5681d4"
            :ripple="false"
            rounded
            depressed
            @click="runTransfer"
            style="text-decoration: none"
            :disabled="$store.state.noDialogs"
            :dark="!$store.state.noDialogs"
          >
            {{$t('Transfer')}}
            <v-icon class="px-1">mdi-shuffle-variant</v-icon>
          </v-btn>
          <v-btn
            class="font-weight-bold ml-3"
            color="rgb(255 116 79)"
            :ripple="false"
            rounded
            depressed
            @click="reportDialog = true"
            :dark="!$store.state.noDialogs"
            :disabled="$store.state.noDialogs"
            style="text-decoration: none"
          >
            {{$t('report')}}
            <v-icon class="px-2">mdi-sign-caution</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
      </div>
      <!-- <div class="col chat-body"> -->
      <v-sheet
        flat
        style="border-radius: 0px"
        id="chatBody"
        class="col chat-body"
        :color="$vuetify.theme.dark ? '#131212' : ''"
        v-on:scroll.native="handleScroll"
      >
        <!-- new start -->
        <div
          v-if="
            $store.state.loadedTargetProfileStatus == 'loaded' &&
            !$store.state.noDialogs && isLoaded
          "
        >
          <div class="mt-1 mx-2 mb-5" v-for="(val, idx) in messages" :key="idx">
            <v-col v-if="val.sender.id == $store.state.profileData.id">
              <div class="message pt-3 mb-2">
                <div class="messageFrom">
                  [{{ val.mod ? val.mod.pin : "NO MOD" }}]<br />
                  {{ parseDate(val.createdAt).stmp }} |
                  {{ parseDate(val.createdAt).time }}
                  <v-icon size="12">mdi-pen</v-icon>
                </div>
                <div v-if="val.attachment_url && val.message_type =='d'">
                  <img :src="getURL(val.attachment_url)" />
                  <v-col style="padding: 0px" class="pt-1 text-left">
                    {{ val.body }}
                  </v-col>
                </div>
                <p v-else-if="val.message_type =='d' && val.attachment_url == null">
                  <br />
                  <v-col style="padding: 0px" class="pt-1 text-left">
                    {{ val.body }}
                  </v-col>
                </p>
                <div v-else-if="val.message_type =='sn'">
                  <img :src="getURL(val.attachment_url)" />
                  <v-col style="padding: 0px" class="pt-1 text-left">
                    {{ val.body }}
                  </v-col>
                </div>
              </div>
            </v-col>
            <v-col v-else>
              <v-spacer class="my-2"></v-spacer>
              <div class="message-rec mx-2 mb-2">
                <div class="messageFr">
                  {{ parseDate(val.createdAt).stmp }} |
                  {{ parseDate(val.createdAt).time }}
                  <v-icon size="6">mdi-pen</v-icon>
                </div>
                <br />
                <div v-if="val.attachment_url && val.message_type =='d'">
                  <img
                    style="max-width: 100%"
                    :src="getURL(val.attachment_url)"
                  />
                </div>
                <p v-else-if="val.message_type =='d' && val.attachment_url == null">
                  <span> </span>
                  <v-col style="padding: 0px" class="text-left">
                    {{ val.body }}
                  </v-col>
                </p>
              </div>
            </v-col>
          </div>
        </div>
      </v-sheet>
      <!-- <div class="w-100 d-none d-md-block"></div> -->
      <v-card
        flat
        class="col chat-foot"
        :color="$vuetify.theme.dark ? '' : 'rgb(249 249 249)'"
      >
       
      </v-card>
    </div>
  </v-col>
</template>

<script>
import axios from "axios";
import {
  ADD_REPORT,
  FETCH_ALL_MESSAGES,
  FETCH_CONVO_QUEUE,
  MOD_MESASGE_ADDED,
  SEND_MODED_MESSAGE,
  TRANSFER_DIALOG,
} from "../queries/core";
import NudesSelect from '../components/nudesSelect.vue'
import Vue from 'vue'

const PRODUCTION = true;

export default {
  props:{
    conversationId:null
  },
  components: {
    NudesSelect
  },
  created(){
      this.$store.commit("loadTargetProfile", "loading");
  },
  data: () => ({
    isLoaded:false,
    fupload: null,
    dialogm1: "",
    dialog: false,
    adminMessage: "",
    reportFormValid: false,
    showDialog: true,
    fab: !false,
    chatMess: "",
    avatarColor: "",
    loadingSend: false,
    loadMessages: false,
    roomId: "",
    messages: [],
    //   rooms: ["newroom", "newrooms", "music"],
    changedRoom: "",
    curUsr: "amen",
    toastNots: {
      title: "Message for admins",
      body: "Hey admins you can all fuck yourself",
      type: "success",
      defaultTime: true,
      url: "https://google.com",
    },
    loadingMessages: false,
    loadingAgents: false,
    last_message: 0,
    randomState: {},
    authKey: "",
    allOnlineAgents: [
      {
        value: "amen",
        label: "DMAmen",
      },
      {
        value: "james",
        label: "DMJames",
      },
      {
        value: "jack",
        label: "DMJack",
      },
    ],
    reportDialog: false,
    reportData: {
      comment: "",
      reason: "",
    },
    reportReasons: [
      "Taboo or vulgar   language",
      "Pornographic content in profile",
      "Signs of child abuse",
      "Extremity and violent",
    ],
    bus: new Vue(),
    message_type:'d',
    attachment:''
  }),
  apollo: {
    allMessagesMod: {
      query: FETCH_ALL_MESSAGES,
      variables() {
        return {
          conv: this.$store.state.curConv,
        };
      },
      result({ data }) {
        this.$store.commit("loadTargetProfile", "loaded");
        console.log(data, "wait");
        this.messages = data.allMessagesMod.messages;
        console.log("wait");
        // this.$store.commit("loadTargetProfile", "loaded");
        setTimeout(() => {
          let chatBody = this.$el.querySelector("#chatBody");
          chatBody.scrollTo({
            top: chatBody.scrollHeight,
            left: 0,
            behavior: "smooth",
          });
          // this.scrollDown(false);
        }, 100);
      },
    },
    fetchConvQueue: {
      query: FETCH_CONVO_QUEUE,
      variables() {
        return {
          cnv: this.getConvoType(),
          pool: this.$store.state.queryFromPools,
        };
      },
      result({ data: { fetchConvQueue } }) {
        const sender = fetchConvQueue.conversation.m_one;
        const reciever = fetchConvQueue.conversation.m_two;
        if (reciever.usrType != "nt") {
          this.$store.commit("setTargetData", reciever);
        } else {
          this.$store.commit("setProfileData", reciever);
        }
        if (sender.usrType != "nt") {
          this.$store.commit("setTargetData", sender);
        } else {
          this.$store.commit("setProfileData", sender);
        }
        this.$store.commit("setCurConv", fetchConvQueue.conversation.id);
        // set empty dialog status and refetch messages
        this.$store.commit("setNoDialogs", false);
        // update main target notes
        this.$store.commit(
          "setTargetDesc",
          fetchConvQueue.conversation.target_notes
        );
        // update main profile notes
        this.$store.commit(
          "setProfileDesc",
          fetchConvQueue.conversation.mod_notes
        );
        console.log(fetchConvQueue.conversation.id, "Bish");
        this.$store.commit("loadTargetProfile", "loaded");
         this.$emit("loaded",true)
         this.isLoaded = true
        this.fetchMessaes();
      },
    },
    $subscribe: {
      // listen when a new dialog comes to this moderator and update status
      // TODO: play notificaiton sound at this moment
      modMessageAdded: {
        query: MOD_MESASGE_ADDED,
        result({ data: { modMessageAdded } }) {
          const sender = modMessageAdded.m_one;
          const reciever = modMessageAdded.m_two;
          if (reciever.usrType != "nt") {
            this.$store.commit("setTargetData", reciever);
          } else {
            this.$store.commit("setProfileData", reciever);
          }
          if (sender.usrType != "nt") {
            this.$store.commit("setTargetData", sender);
          } else {
            this.$store.commit("setProfileData", sender);
          }
          this.$store.commit("setCurConv", modMessageAdded.id);
          // set empty dialog status and refetch messages
          this.$store.commit("setNoDialogs", false);
          // update main target notes
          this.$store.commit("setTargetDesc", modMessageAdded.target_notes);
          // update main profile notes
          this.$store.commit("setProfileDesc", modMessageAdded.mod_notes);
          console.log(modMessageAdded.id, "Bish");
          this.fetchMessaes();
          this.playSound();
          this.$store.commit(
            "saveMessageSent",
            Math.round(Math.random() * 200)
          );
          // change notification state
          // console.log(data, "The message that's added")
          // this.$store.commit('addNotification', data.notificationAdded)
        },
        error: (e) => {
          console.error(e, "what an error");
        },
      },
    },
  },
  mounted() {
    this.$apollo.queries.fetchConvQueue.refetch();
  },
  computed: {
    getQueryFromPools() {
      return this.$store.getters.getQueryFromPools;
    },
    targetStatus() {
      return this.$store.getters.targetStatus;
    },
    requestedSetStatus() {
      return this.$store.getters.requestedSetStatus;
    },
  },
  watch: {
    getQueryFromPools() {
      this.$apollo.queries.fetchConvQueue.refetch();
    },
    targetStatus(value) {
      // alert("test")
      console.log(value, this.$store.state.noDialogs, "CHECK HERE");
      if (!this.$store.state.noDialogs) {
        this.fetchMessaes();
      }
    },
    requestedSetStatus(value) {
      console.warn(value);
      this.$apollo.queries.fetchConvQueue.refetch();
    },
  },
  methods: {
    getConvoType(){
      return this.conversationId ? this.conversationId : 'random'
    },
    uploaded(e) {
      this.fupload = e.target.files[0];
    },
    getURL(url) {
      const domain = PRODUCTION
        ? "https://flirttool.com/media/" + url
        : "http://" + location.hostname + ":8080/media/" + url;
      return domain;
    },

    parseDate(date) {
      const dObj = new Date(parseInt(date));
      return {
        time: dObj.toLocaleTimeString(),
        stmp: dObj.toLocaleDateString(),
      };
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.reportUser();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    playSound() {
      var data = {
        soundurl:
          "https://assets.mixkit.co/sfx/preview/mixkit-cartoon-door-melodic-bell-110.mp3",
      };
      var audio = new Audio(data.soundurl);
      audio.play();
    },
    showNewMessageNotification(message) {
      this.playSound();
      this.showToaster(message, "success");
      setTimeout(() => {
        this.$apollo.queries.fetchConvQueue.refetch();
      }, 2000);
    },
    pollModeratorNotifications() {
      const url = "https://flirttool.com/aj/moderation/moderatorNotification";
      axios
        .get(url, {
          headers: {
            Authorization: this.authKey,
          },
        })
        .then(({ data }) => {
          // const currentNots = this.$store.state.totalAssignedNotifications;
          // const currentNotsPoll =
          //   this.$store.state.totalAssignedPoolNotifications;
          // console.warn(
          //   data.pool_count,
          //   this.$store.state.totalAssignedPoolNotifications,
          //   "WATCH POLL"
          // );
          // console.warn(
          //   data.count,
          //   this.$store.state.totalAssignedNotifications,
          //   "WATCH COUNT"
          // );
          if (
            data.pool_count > this.$store.state.totalAssignedPoolNotifications
          ) {
            this.showNewMessageNotification("You have a new message");
            // if user is not in pool then switch them up
            this.$store.commit("setRequestedSet", "vip");
          } else if (
            data.count > this.$store.state.totalAssignedNotifications
          ) {
            this.showNewMessageNotification("You have a new message");
            // alert("new message");
          }
          this.$store.commit("setTotalAssignedNotifications", data.count);
          this.$store.commit(
            "setTotalAssignedPoolNotifications",
            data.pool_count
          );

          // console.log(data.count);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    replaceAllBackSlash(targetStr) {
      var index = targetStr.indexOf("\\");
      while (index >= 0) {
        targetStr = targetStr.replace("\\", "");
        index = targetStr.indexOf("\\");
      }
      return targetStr;
    },
    // process client transfer
    processTransfer() {
      this.$apollo
        .mutate({
          mutation: TRANSFER_DIALOG,
          variables: {
            input: {
              agent: this.dialogm1,
              convo: this.$store.state.curConv,
            },
          },
        })
        .then(() => {
          this.dialog = false;
          this.showToaster("client transferred succesfully", "success");
          this.$apollo.queries.fetchConvQueue.refetch();
        })
        .catch((e) => {
          console.error(e);
        });
    },
    getTime(stamp) {
      const date = new Date(stamp);
      return date.toLocaleTimeString().replace(/:\d+ /, " ");
    },
    getDate(stamp) {
      const date = new Date(stamp);
      return date.toLocaleDateString();
    },
    // create a new moderator account
    newModerator() {
      const url = "https://flirttool.com/aj/moderation/generate_moderator_pin";
      axios
        .post(url, {
          username: "NewAdmminAmenabe",
          password: "#Hallelujah",
          c_password: "#Hallelujah",
        })
        .then(({ data }) => {
          console.log(data);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    // test moderator login
    testLogin() {
      axios
        .post("https://flirttool.com/aj/moderation/moderator_login", {
          username: "JACKIEamenabe",
          password: "#Hallelujah",
        })
        .then(({ data }) => {
          console.log(data);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    // test func
    testJwt() {
      const url = `https://flirttool.com/aj/moderation/jwt_test`;
      axios
        .post(
          url,
          {},
          {
            headers: {
              Authorization: this.authKey,
            },
          }
        )
        .then(({ data }) => {
          this.$store.commit("setCurrentAgent", data.user);
        })
        .catch((e) => {
          console.error("error getting data", e);
        });
    },
    showToaster(message, type = "info") {
      const data = {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      };
      switch (type) {
        case "info":
          this.$toast.info(message, data);
          break;
        case "success":
          this.$toast.success(message, data);
          break;
        case "warning":
          this.$toast.warning(message, data);
          break;
        case "error":
          this.$toast.error(message, data);
          break;

        default:
          this.$toast.info(message, data);
          break;
      }
    },
    triggerFunc() {
      console.log("HEY NIGGGGAA");
    },
    getOnlineAgents() {
      this.loadingAgents = true;
      this.$apollo.queries.onlineMods
        .refetch()
        .catch((e) => {
          console.log(e);
        })
        .finally(() => {
          this.loadingAgents = false;
        });
    },
    getNewChatMessage() {
      console.warn(this.messages.length, this.messages, "LOOK ON THIS SIDE RN");
      this.last_message = this.messages[this.messages.length - 1].id;
      const fuid = this.$store.state.profileData.id;
      const selectedTarget = this.$store.state.targetData.id;
      axios
        .get(
          `https://flirttool.com/aj/moderation/get_chat_messages?uid=${fuid}&target=${selectedTarget}&last=${this.last_message}`
        )
        .then(({ data }) => {
          if (data.message == "") {
            console.warn("no new messages");
          } else {
            this.messages.push(data.message);
            this.last_message = this.messages[this.messages.length - 1].id;
            setTimeout(() => {
              let chatBody = this.$el.querySelector("#chatBody");
              chatBody.scrollTo({
                top: chatBody.scrollHeight,
                left: 0,
                behavior: "smooth",
              });

              // this.scrollDown(false);
            }, 100);
          }
          console.log(data);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    fetchMessaes() {
      console.log("dawg");
      console.warn(
        this.$store.state.targetData,
        this.$store.state.targetData,
        "TARGET"
      );
      console.warn(
        this.$store.state.profileData,
        this.$store.state.profileData,
        "PROFILE"
      );
      // this.loadingMessages = true;
      // const fuId = this.$store.state.profileData.id;
      console.warn("check there1222222222222222222222");
      console.warn(this.$store.state.profileData, "PROFILE DATA");
      const selectedTarget = this.$store.state.targetData.id;
      console.log(selectedTarget, "sheesh");
      this.$store.commit("loadTargetProfile", "loading");
      this.$apollo.queries.allMessagesMod.refetch();
    },
    sendMessage() {
      if (this.chatMess.length >= 2) {
        this.pushMessage();
      } else {
        this.showToaster("you need to write atleast 2 characters", "error");
      }
    },
    randomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    reportUser() {
      const targetId = this.$store.state.targetData.id;
      // const url = "https://flirttool.com/aj/moderation/report_user";
      this.reportData["user"] = targetId;
      console.log(this.reportData, "Report Data");
      this.$apollo
        .mutate({
          mutation: ADD_REPORT,
          variables: {
            input: {
              user: targetId,
              reason: this.reportData.reason,
              comment: this.reportData.comment,
            },
          },
        })
        .then(({ data }) => {
          console.log(data);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.reportDialog = false;
          this.$apollo.queries.fetchConvQueue.refetch();
        });
    },
     sendNudeImage(messageData) {
      this.message_type = 'sn'
      this.chatMess = messageData.message
      this.attachment = messageData.url.split('media/')[1]
      this.pushMessage(()=>{
        this.bus.$emit('messageSent',true);
      })
    },
     pushMessage(callback = '') {
      const fuId = this.$store.state.profileData.id;
      const selectedTarget = this.$store.state.targetData.id;
      console.warn(selectedTarget, "Selected Target", fuId);
      this.loadingSend = true;
      this.$apollo
        .mutate({
          mutation: SEND_MODED_MESSAGE,
          variables: {
            input: {
              body: this.chatMess,
              user: fuId,
              target: selectedTarget,
              attachment: this.attachment,
              message_type:this.message_type
            },
            file: this.fupload,
          },
        })
        .then(({ data: { sendModMessage } }) => {
          console.log(sendModMessage, "Message has been sent");
          this.messages.push(sendModMessage);
          this.chatMess = "";
          this.message_type = 'd'
          this.attachment = "";
          this.fupload = null
          this.loadingSend = false;
          if(callback && typeof callback == 'function') callback()
          setTimeout(() => {
            let chatBody = this.$el.querySelector("#chatBody");
            chatBody.scrollTo({
              top: chatBody.scrollHeight,
              left: 0,
              behavior: "smooth",
            });
            // reload once the message is sents
          }, 100);
          
        })
        .catch((e) => {
          console.error(e, "Error sending message");
          this.loadingSend = false;
        });
      setTimeout(() => {
        // this.$apollo.queries.fetchConvQueue.refetch();
        this.$store.commit("saveMessageSent", Math.round(Math.random() * 200));
      }, 500);
    },
    getNextDialog() {
      this.conversationId = 'random'
      this.$store.commit("loadTargetProfile", "loading");
      this.$apollo.queries.fetchConvQueue.refetch();
    },
    backToOldPos() {
      let oldPos = localStorage.getItem("lastPos");
      let chatBody = this.$el.querySelector("#chatBody");
      chatBody.scrollTo({
        top: parseInt(oldPos),
        left: 0,
        behavior: "smooth",
      });
    },
    handleScroll(e) {
      // console.warn(e.target.scrollTop);
      localStorage.setItem("lastPos", e.target.scrollTop);
      // console.warn(e)
    },
    scrollDown(fast) {
      let chatBody = this.$el.querySelector("#chatBody");
      chatBody.scrollTo({
        top: chatBody.scrollHeight,
        left: 0,
        behavior: fast ? "smooth" : "auto",
      });
      // chatBody.scrollTop = chatBody.scrollHeight;
    },
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
    runTransfer() {
      this.getOnlineAgents();
      this.dialog = true;
    },
    toogleDialogEmoji() {
      console.log("Toogle!");
      this.showDialog = !this.showDialog;
    },
    onSelectEmoji(emoji) {
      this.chatMess += " " + emoji.data;
      // Optional
      // this.toogleDialogEmoji();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/chat.scss";
.messageFr {
  float: left;
  background-color: transparent;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  display: block;
  // margin-bottom: 5px;
  clear: both;
  // padding: 6px 12px;
  font-size: 10px;
  border-radius: 15px;
  line-height: 1.4;
  // max-width: 70%;
  outline: none !important;
  box-sizing: border-box;
  border-top-right-radius: 15px;
}
.messageFrom {
  float: right;
  background-color: transparent;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  display: block;
  // margin-bottom: 5px;
  clear: both;
  padding: 0 0 5px 0px;
  // padding: 6px 12px;
  text-align: start;
  font-size: 10px;
  border-radius: 15px;
  line-height: 1.4;
  // max-width: 70%;
  outline: none !important;
  box-sizing: border-box;
  border-top-right-radius: 15px;
}
.message {
  float: right;
  background-color: #a33596;
  color: #fff;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  display: block;
  // margin-bottom: 5px;
  clear: both;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 15px;
  line-height: 1.4;
  max-width: 70%;
  outline: none !important;
  box-sizing: border-box;
  border-top-right-radius: 15px;
}
.message-rec {
  float: left;
  background-color: #4a4545;
  color: #fff;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  display: block;
  // margin-bottom: 1.5px;
  clear: both;
  padding-top: 10px;
  padding: 10px;
  font-size: 16px;
  border-radius: 15px;
  line-height: 1.4;
  max-width: 70%;
  outline: none !important;
  box-sizing: border-box;
  border-top-right-radius: 15px;
}
</style>

import Vue from 'vue'
import VueApollo from 'vue-apollo'
import { setContext } from 'apollo-link-context'
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'
import { split } from 'apollo-boost'
import { getMainDefinition } from 'apollo-utilities'
import { WebSocketLink } from 'apollo-link-ws'
import store from './store'
import { SubscriptionClient } from 'subscriptions-transport-ws'
// Install the vue plugin
Vue.use(VueApollo)
const production = true;

// Name of the localStorage item
const AUTH_TOKEN = 'Bearer'

// Http endpoint
const httpEndpoint = production ? 'https://api.flirttool.com/graphql' : 'http://localhost:8080/graphql'

// Config

const authLink = setContext(async(_, { headers }) => {
    // Use your async token function here:
    const token = store.state.flirtok ? `Bearer ${JSON.parse(localStorage.getItem('vuex')).flirtok}` : ''
        // Return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token || '',
            'Community-Domain': store.state.domain 
        }
    }
})

const wsClient = new SubscriptionClient(production ? 'wss://api.flirttool.com/graphql' : 'ws://' + window.location.hostname + ':8080/subscriptions', {
    reconnect: true,
    connectionParams: () => {
        return { communityDomain: store.state.domain, Authorization: store.state.flirtok ? `Bearer ${JSON.parse(localStorage.getItem('vuex')).flirtok}` : '' };
    },
})

const wsLink = new WebSocketLink(wsClient);

const link = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind == "OperationDefinition" &&
            definition.operation == "subscription"
    },
    wsLink,
    authLink
);
const defaultOptions = {
    // You can use `https` for secure connection (recommended in production)
    httpEndpoint,
    // You can use `wss` for secure connection (recommended in production)
    // Use `null` to disable subscriptions
    // wsEndpoint: production ? 'wss://testflirttool.com/subscriptions' : 'ws://' + window.location.hostname + ':4000/subscriptions',
    // LocalStorage token
    tokenName: AUTH_TOKEN,
    // Enable Automatic Query persisting with Apollo Engine
    persisting: false,
    // Use websockets for everything (no HTTP)
    // You need to pass a `wsEndpoint` for this to work
    websocketsOnly: false,
    // Is being rendered on the server?
    ssr: false,

    // Override default apollo link
    // note: don't override httpLink here, specify httpLink options in the
    // httpLinkOptions property of defaultOptions.
    // link: myLink
    link: link,

    // Override default cache
    // cache: myCache

    // Override the way the Authorization header is set
    // getAuth: (tokenName) => { }

    // Additional ApolloClient options
    // apollo: { ... }

    // Client local data (see apollo-link-state)
    // clientState: { resolvers: { ... }, defaults: { ... } }
}

// Create apollo client
export const { apolloClient } = createApolloClient({
        ...defaultOptions,
        // ...options
    })
    // apolloClient.wsClient = wsClient

// Call this in the Vue app file
export function createProvider() {
    // Create vue apollo provider
    const apolloProvider = new VueApollo({
        defaultClient: apolloClient,
        defaultOptions: {
            $query: {
                fetchPolicy: "network-only"
                    // fetchPolicy: 'cache-and-network',
            }
        },
        errorHandler(error) {
            // eslint-disable-next-line no-console
            console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
        }
    })

    return apolloProvider
}

// Manually call this when user log in
export async function onLogin(apolloClient) {
    console.log("Ayee ", apolloClient)
    apolloClient.cache.data.clear();
    if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
    try {
        await apolloClient.queryManager.fetchQueryRejectFns;
        await apolloClient.resetStore()
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log('%cError on cache reset (login)', 'color: orange;', e.message)
    }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
    store.commit('setUserData', {})
    store.commit('setFlirtTok', null)
    if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
    try {
        await apolloClient.resetStore()
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
    }
}

export function disconnectSocket() {
    wsClient.unsubscribeAll()
    wsClient.close()
}

export function reconnectSocket(){
    wsClient.connect();
}
<template>
  <div id="app">
    <PageLoader
    v-if="loading"
    />
    <v-container fluid>
      <v-app-bar flat >
        <!-- from date selector -->
        <v-avatar tile :size="60" class="mx-2">
          <v-img :src="require('@/assets/filter.png')"> </v-img>
        </v-avatar>
        <!-- end of end selector -->
      </v-app-bar>
      <!-- start of filter button group -->
      <v-col cols="12"  class="my-2">
        <v-btn-toggle
          active-class="activeFilterBtn"
          v-model="text"
          color="blue darken-4"
          mandatory
        >
          <v-btn
            :ripple="false"
            v-for="(fil, idx) in filters"
            :key="idx"
            :value="fil.value"
            large
            v-text="fil.text"
            @click="setFilter(fil)"
          ></v-btn>
        </v-btn-toggle>
        
        <v-btn
          large
          style="margin-left:10px;"
          v-text="`Message show type (${messageShowType})`"
          @click="changeMessageShowType()"
        ></v-btn>
         <v-btn
          large
          style="margin-left:10px;"
          v-text="'Export CSV'"
          @click="exportData('csv')"
        ></v-btn>
        <v-btn
          large
          style="margin-left:10px;"
          v-text="'Export xls'"
          @click="exportData('xls')"
        ></v-btn>
      
      </v-col>
      <v-spacer></v-spacer>
      <!-- stop of filter button group -->
      <template v-if="messageShowType == 'out'">
        <v-data-table
          :headers="headers"
          :items="items"
          class="dataCard"
        >
          <template v-slot:[`item.calories`]="{ item }">
            <v-chip :color="getColor(item.calories)" dark>
              {{ item.calories }}
            </v-chip>
          </template>
        </v-data-table>
      </template>
      <template v-if="messageShowType == 'in'">
        <v-data-table
          :headers="headers2"
          :items="items"
          class="dataCard"
        >
          <template v-slot:[`item.calories`]="{ item }">
            <v-chip :color="getColor(item.calories)" dark>
              {{ item.calories }}
            </v-chip>
          </template>
        </v-data-table>
      </template>
      <template v-if="messageShowType == 'spam'">
        <v-data-table
          :headers="headers3"
          :items="items"
          class="dataCard"
        >
        </v-data-table>
      </template>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable */
import {
  AGENT_ANALYTICS,
} from "../queries/core";
import PageLoader from '@/components/PageLoader.vue'
import exportFromJSON from 'export-from-json'
export default {
  components: {
    PageLoader
  },
  data() {
    return {
      items:[],
      loading:false,
      analyticsFilter: "today",
      messageShowType:'out',
      dateStart: new Date().toISOString().substr(0, 10),
      dateEnd: new Date().toISOString().substr(0, 10),
      menuEnd: false,
      menuStart: false,
      headers: [
        { text: "Moderator", value: "mod.pin" },
        { text: "Messages recieved (INs)", value: "ins" },
        { text: "Messages Sent (OUTs)", value: "outs" },
        { text: "Messages Spams", value: "spams" },
         { text: "0 out", value: "outHourObject.0" },
        { text: "1 out", value: "outHourObject.1" },
        { text: "2 out", value: "outHourObject.2" },
        { text: "3 out", value: "outHourObject.3" },
        { text: "4 out", value: "outHourObject.4" },
        { text: "5 out", value: "outHourObject.5" },
        { text: "6 out", value: "outHourObject.6" },
        { text: "7 out", value: "outHourObject.7" },
        { text: "8 out", value: "outHourObject.8" },
        { text: "9 out", value: "outHourObject.9" },
        { text: "10 out", value: "outHourObject.10" },
        { text: "11 out", value: "outHourObject.11" },
        { text: "12 out", value: "outHourObject.12" },
        { text: "13 out", value: "outHourObject.13" },
        { text: "14 out", value: "outHourObject.14" },
        { text: "15 out", value: "outHourObject.15" },
        { text: "16 out", value: "outHourObject.16" },
        { text: "17 out", value: "outHourObject.17" },
        { text: "18 out", value: "outHourObject.18" },
        { text: "19 out", value: "outHourObject.19" },
        { text: "20 out", value: "outHourObject.20" },
        { text: "21 out", value: "outHourObject.21" },
        { text: "22 out", value: "outHourObject.22" },
        { text: "23 out", value: "outHourObject.23" },
        { text: "23:59 out", value: "outHourObject.24" },
      ],
      headers2: [
        { text: "Moderator", value: "mod.pin" },
        { text: "Messages recieved (INs)", value: "ins" },
        { text: "Messages Sent (OUTs)", value: "outs" },
        { text: "Messages Spams", value: "spams" },
         { text: "0 ins", value: "inHourObject.0" },
        { text: "1 ins", value: "inHourObject.1" },
        { text: "2 ins", value: "inHourObject.2" },
        { text: "3 ins", value: "inHourObject.3" },
        { text: "4 ins", value: "inHourObject.4" },
        { text: "5 ins", value: "inHourObject.5" },
        { text: "6 ins", value: "inHourObject.6" },
        { text: "7 ins", value: "inHourObject.7" },
        { text: "8 ins", value: "inHourObject.8" },
        { text: "9 ins", value: "inHourObject.9" },
        { text: "10 ins", value: "inHourObject.10" },
        { text: "11 ins", value: "inHourObject.11" },
        { text: "12 ins", value: "inHourObject.12" },
        { text: "13 ins", value: "inHourObject.13" },
        { text: "14 ins", value: "inHourObject.14" },
        { text: "15 ins", value: "inHourObject.15" },
        { text: "16 ins", value: "inHourObject.16" },
        { text: "17 ins", value: "inHourObject.17" },
        { text: "18 ins", value: "inHourObject.18" },
        { text: "19 ins", value: "inHourObject.19" },
        { text: "20 ins", value: "inHourObject.20" },
        { text: "21 ins", value: "inHourObject.21" },
        { text: "22 ins", value: "inHourObject.22" },
        { text: "23 ins", value: "inHourObject.23" },
        { text: "23:59 ins", value: "inHourObject.24" },
      ],
      headers3: [
        { text: "Moderator", value: "mod.pin" },
        { text: "Messages recieved (INs)", value: "ins" },
        { text: "Messages Sent (OUTs)", value: "outs" },
        { text: "Messages Spams", value: "spams" },
         { text: "0 spm", value: "spamHourObject.0" },
        { text: "1 spm", value: "spamHourObject.1" },
        { text: "2 spm", value: "spamHourObject.2" },
        { text: "3 spm", value: "spamHourObject.3" },
        { text: "4 spm", value: "spamHourObject.4" },
        { text: "5 spm", value: "spamHourObject.5" },
        { text: "6 spm", value: "spamHourObject.6" },
        { text: "7 spm", value: "spamHourObject.7" },
        { text: "8 spm", value: "spamHourObject.8" },
        { text: "9 spm", value: "spamHourObject.9" },
        { text: "10 spm", value: "spamHourObject.10" },
        { text: "11 spm", value: "spamHourObject.11" },
        { text: "12 spm", value: "spamHourObject.12" },
        { text: "13 spm", value: "spamHourObject.13" },
        { text: "14 spm", value: "spamHourObject.14" },
        { text: "15 spm", value: "spamHourObject.15" },
        { text: "16 spm", value: "spamHourObject.16" },
        { text: "17 spm", value: "spamHourObject.17" },
        { text: "18 spm", value: "spamHourObject.18" },
        { text: "19 spm", value: "spamHourObject.19" },
        { text: "20 spm", value: "spamHourObject.20" },
        { text: "21 spm", value: "spamHourObject.21" },
        { text: "22 spm", value: "spamHourObject.22" },
        { text: "23 spm", value: "spamHourObject.23" },
        { text: "23:59 spm", value: "spamHourObject.24" },
      ],
      analysis_data: [
        {
          mod: 'eric',
          rank: 1,
          sent_m: 120,
          answers: 6,
          hourl: 6,
          answers_r: 0,
          message_r: 4,
          message_l: 100,
          operator: "DMamenabe",
        },
      ],
      filters: [
        { value: "today", text: "Today" },
        { value: "thisWeek", text: "This Week" },
        { value: "thisMonth", text: "This Month" },
      ],
      authKey: "",
    };
  },
  created() {
    const authKey = btoa(localStorage.au123);
    this.authKey = authKey;
    // this.$vuetify.theme.dark = true;
    this.fetchAnalysisData();
  },
  methods: {
    exportData(exportType) {
      let data = this.items.map((data)=>{
        let toReturnObject =   {
            MODERATOR : data.mod.pin,
            MESSAGES_RECEIVED : data.ins,
            MESSAGES_SENT : data.outs,
            TOTAL : data.total,
            // ...data.outHourObject
          }
          if(this.messageShowType == 'in') {
            Object.keys(data.inHourObject).forEach((hour)=>{
            toReturnObject[`IN-${hour}`] = data.inHourObject[hour]
            })
          }else {
            Object.keys(data.outHourObject).forEach((hour)=>{
            toReturnObject[`OUT-${hour}`] = data.outHourObject[hour]
            })
          }
          
          return toReturnObject
      }) 
      console.log(data)
      const fileName = 'Message Statistics'
      try { 
        exportFromJSON({ data, fileName, exportType })
      }catch(e) {
        console.log(e)
      }
    },
    sortObj(obj) {
      return Object.keys(obj).sort().reverse().reduce(function (result, key) {
        result[key] = obj[key];
        return result;
      }, {});
    },
    changeMessageShowType() {
      let values = ['in','out','spam']
      let currentShowType = this.messageShowType
      console.log(currentShowType)
      let index = values.findIndex(v => v == currentShowType)
      console.log(index)
      index++
      if(index > 2) index = 0
      this.messageShowType = values[index]

    },
    setFilter(fil) {
      this.analyticsFilter = fil.value
      this.fetchAnalysisData()
    },
    fetchAnalysisData() {
      this.loading = true
      this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: AGENT_ANALYTICS,
          variables: {
            input: {
              dateFilter: this.analyticsFilter,
            },
          },
        })
        .then(({ data: { allAgentsAnalytics } }) => {
          allAgentsAnalytics = allAgentsAnalytics.map((r)=>{
            let allOutsArray = r.allOutsArray
            let outHourObject = {}
            let allInsArray = r.allInsArray
            let inHourObject = {}
            let allSpamsArray = r.allSpamsArray
            let spamHourObject = {}
            Array(25).fill(0).forEach((val,index) => {
              inHourObject[index] = 0
              outHourObject[index] = 0
              spamHourObject[index] = 0
            })
            allOutsArray.forEach((messageInfo)=>{
              let time = new Date(Number(messageInfo.created)).getHours()
              if(outHourObject[time]) {
                outHourObject[time] = outHourObject[time] + 1
              }else {
                outHourObject[time] = 1
              }
            })
            allInsArray.forEach((messageInfo)=>{
              let time = new Date(Number(messageInfo.created)).getHours()
              if(inHourObject[time]) {
                inHourObject[time] = inHourObject[time] + 1
              }else {
                inHourObject[time] = 1
              }
            })
            allSpamsArray.forEach((messageInfo)=>{
              let time = new Date(Number(messageInfo.created)).getHours()
              if(spamHourObject[time]) {
                spamHourObject[time] = spamHourObject[time] + 1
              }else {
                spamHourObject[time] = 1
              }
            })
            
          return { ... r ,outHourObject,inHourObject,spamHourObject}
        })
          this.items = allAgentsAnalytics;
          console.log(this.items,"this.itemsthis.items")
        }).finally(()=>{
            this.loading = false
        });
    },
    getColor(calories) {
      if (calories > 400) return "red";
      else if (calories > 200) return "orange";
      else return "green";
    },
  },
};
</script>
<style scoped>
.dataCard {
  box-shadow: 0px 1px 1px -4px rgb(0 0 0 / 0%), 0px 1px 1px 2px rgb(0 0 0 / 0%),
    0px 5px 15px 14px rgb(0 0 0 / 2%) !important;
}
.activeFilterBtn {
  background: #0000;
}
</style>
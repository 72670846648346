<template>
  <v-app
    :style="{ background: $vuetify.theme.themes.light.background }"
    class="mx-2"
  >
    <v-dialog v-model="setOwner" max-width="500" style="height: 100%">
      <v-card height="250">
        <v-row class="mx-2 pt-2">
          <v-card-title
            v-if="selectedUAf"
            class="text-h5 px-3 pt-10"
            style="padding: 0px"
          >
            <v-icon size="30" class="pr-2" color="blue darken-2"
              >mdi-update</v-icon
            >{{$t('Change')}} {{ selectedUAf.fullName }}'s {{$t('Owner')}}
          </v-card-title>
        </v-row>
        <v-divider></v-divider>
        <v-form v-model="validowner" ref="form">
          <v-col>
            <v-text-field
              append-icon="mdi-account"
              v-model="owner"
              filled
              label="Enter Supervisor Affilate ID"
              :rules="[(v) => !!v || 'ID is required']"
            >
            </v-text-field>
          </v-col>
          <v-btn @click="updateOwner" class="mb-10 mx-3" color="green" dark
            >{{$t('SaveChanges')}}</v-btn
          >
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="updateDialog" max-width="800" persistent>
      <v-card>
        <v-row class="mx-2 pt-2">
          <v-card-title class="text-h5 px-3 pt-10" style="padding: 0px">
            <v-icon size="30" class="pr-2" color="blue darken-2"
              >mdi-update</v-icon
            >
            {{$t('UpdateAffilateData')}}
          </v-card-title>
          <v-card-text style="padding: 0px" class="px-5"
            >add new affilates/creators/influencers here</v-card-text
          >
        </v-row>
        <v-divider></v-divider>
        <v-form v-model="valid" ref="form">
          <v-row dense class="mx-2">
            <v-col>
              <v-text-field
                append-icon="mdi-account"
                v-model="fullName"
                filled
                label="Full Name"
                :rules="[(v) => !!v || 'Name is required']"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                append-icon="mdi-phone"
                v-model="phone"
                filled
                label="Phone number"
                :rules="[(v) => !!v || 'Phone is required']"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="mx-2">
            <v-col>
              <v-text-field
                v-model="email"
                filled
                append-icon="mdi-email"
                label="E-mail"
                :rules="[(v) => !!v || 'E-mail is required']"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="mx-2">
            <v-col>
              <v-text-field
                append-icon="mdi-flag"
                v-model="country"
                filled
                label="Country"
                :rules="[(v) => !!v || 'Country is required']"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                append-icon="mdi-flag"
                v-model="state"
                filled
                label="State"
                :rules="[(v) => !!v || 'State is required']"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="mx-2">
            <v-col>
              <v-text-field
                append-icon="mdi-map"
                v-model="street"
                filled
                label="Street Address"
                :rules="[(v) => !!v || 'Street is required']"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                append-icon="mdi-map"
                v-model="zip"
                filled
                label="Zip Code"
                :rules="[(v) => !!v || 'Street is required']"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-col>
            <v-text-field
              append-icon="mdi-bank"
              v-model="bankAccount"
              filled
              label="Bank Account"
              :rules="[(v) => !!v || 'Bank account is required']"
            >
            </v-text-field>
          </v-col>
          <v-row dense class="mx-2">
            <v-col>
              <v-text-field
                v-model="creditPercent"
                filled
                type="number"
                label="Credit Percent"
                :rules="[(v) => !!v || 'Street is required']"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="paymentMethod"
                :items="paymentMethods"
                filled
                multiple
                label="Payment Method"
                :rules="[(v) => !!v || 'Street is required']"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row dense class="mx-2">
            <v-col>
              <v-select
                v-model="serviceTypes"
                filled
                :items="[
                  { label: 'Video Content', val: 'vid' },
                  { label: 'Image Content', val: 'pic' },
                  { label: 'Blogs', val: 'blog' },
                ]"
                item-text="label"
                item-value="val"
                multiple
                label="Service Type"
                :rules="[(v) => !!v || 'Street is required']"
              >
              </v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="targetGroup"
                filled
                :items="[
                  { label: 'All', val: 'all' },
                  { label: 'Dating', val: 'dating' },
                  { label: 'Flirt', val: 'flirt' },
                  { label: 'Adult', val: 'adult' },
                  { label: 'LifeStyle', val: 'lifestyle' },
                  { label: 'Fashion', val: 'Fashion' },
                  { label: 'Make-Up', val: 'MakeUp' },
                  { label: '🌈 LGBTQ', val: 'LGBTQ' },
                  { label: 'Parties', val: 'Parties' },
                ]"
                item-text="label"
                item-value="val"
                multiple
                label="Target Groups"
                :rules="[(v) => !!v || 'Street is required']"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row dense class="mx-2">
            <v-col>
              <v-text-field
                v-model="targetAgeStart"
                filled
                type="number"
                label="Target age start"
                :rules="[(v) => !!v || 'Street is required']"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="targetAgeEnd"
                filled
                label="Target age end"
                type="number"
                :rules="[(v) => !!v || 'Street is required']"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions>
          <v-spacer />
          <v-btn
            large
            outlined
            color="error"
            :ripple="false"
            @click="updateDialog = false"
            >Cancel</v-btn
          >
          <v-btn :loading="loadingSave" large @click="saveUpdateChanges">
            {{$t('SaveChanges')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="newAffilateDialog" max-width="800" persistent>
      <v-card>
        <v-row class="mx-2 pt-2">
          <v-card-title class="text-h5 px-3 pt-10" style="padding: 0px">
            <v-icon size="30" class="pr-2" color="blue darken-2"
              >mdi-speaker</v-icon
            >
            {{$t('AddAffilate')}}
          </v-card-title>
          <v-card-text style="padding: 0px" class="px-5"
            >{{$t('addnewaffilite')}}</v-card-text
          >
        </v-row>
        <v-divider></v-divider>
        <v-form v-model="valid" ref="form">
          <v-row dense class="mx-2">
            <v-col>
              <v-text-field
                append-icon="mdi-account"
                v-model="fullName"
                filled
                label="Full Name"
                :rules="[(v) => !!v || 'Name is required']"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                append-icon="mdi-phone"
                v-model="phone"
                filled
                label="Phone number"
                :rules="[(v) => !!v || 'Phone is required']"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="mx-2">
            <v-col>
              <v-text-field
                v-model="email"
                filled
                append-icon="mdi-email"
                label="E-mail"
                :rules="[(v) => !!v || 'E-mail is required']"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="affAcc"
                filled
                type="number"
                append-icon="mdi-magnify"
                label="User Account"
                :rules="[(v) => !!v || 'Account is required']"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="mx-2">
            <v-col>
              <v-text-field
                append-icon="mdi-flag"
                v-model="country"
                filled
                label="Country"
                :rules="[(v) => !!v || 'Country is required']"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                append-icon="mdi-flag"
                v-model="state"
                filled
                label="State"
                :rules="[(v) => !!v || 'State is required']"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="mx-2">
            <v-col>
              <v-text-field
                append-icon="mdi-map"
                v-model="street"
                filled
                label="Street Address"
                :rules="[(v) => !!v || 'Street is required']"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                append-icon="mdi-map"
                v-model="zip"
                filled
                label="Zip Code"
                :rules="[(v) => !!v || 'Street is required']"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-col>
            <v-text-field
              append-icon="mdi-bank"
              v-model="bankAccount"
              filled
              label="Bank Account"
              :rules="[(v) => !!v || 'Bank account is required']"
            >
            </v-text-field>
          </v-col>
          <v-row dense class="mx-2">
            <v-col>
              <v-text-field
                v-model="creditPercent"
                filled
                type="number"
                label="Credit Percent"
                :rules="[(v) => !!v || 'Street is required']"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-select
                v-model="paymentMethod"
                :items="paymentMethods"
                filled
                multiple
                label="Payment Method"
                :rules="[(v) => !!v || 'Street is required']"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row dense class="mx-2">
            <v-col>
              <v-select
                v-model="serviceTypes"
                filled
                :items="[
                  { label: 'Video Content', val: 'vid' },
                  { label: 'Image Content', val: 'pic' },
                  { label: 'Blogs', val: 'blog' },
                ]"
                item-text="label"
                item-value="val"
                multiple
                label="Service Type"
                :rules="[(v) => !!v || 'Street is required']"
              >
              </v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="targetGroup"
                filled
                :items="[
                  { label: 'All', val: 'all' },
                  { label: 'Dating', val: 'dating' },
                  { label: 'Flirt', val: 'flirt' },
                  { label: 'Adult', val: 'adult' },
                  { label: 'LifeStyle', val: 'lifestyle' },
                  { label: 'Fashion', val: 'Fashion' },
                  { label: 'Make-Up', val: 'MakeUp' },
                  { label: '🌈 LGBTQ', val: 'LGBTQ' },
                  { label: 'Parties', val: 'Parties' },
                ]"
                item-text="label"
                item-value="val"
                multiple
                label="Target Groups"
                :rules="[(v) => !!v || 'Street is required']"
              >
              </v-select>
            </v-col>
          </v-row>

          <v-row dense class="mx-2">
            <v-col>
              <v-text-field
                v-model="targetAgeStart"
                filled
                type="number"
                label="Target age start"
                :rules="[(v) => !!v || 'Street is required']"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="targetAgeEnd"
                filled
                label="Target age end"
                type="number"
                :rules="[(v) => !!v || 'Street is required']"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions>
          <v-spacer />
          <v-btn
            large
            outlined
            color="error"
            :ripple="false"
            @click="newAffilateDialog = false"
            >Cancel</v-btn
          >
          <v-btn :loading="loadingSave" large @click="saveChanges">
            {{$t('SaveChanges')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container fluid class="mt-10">
      <v-col cols="12" md="12" style="padding: 0px" class="px-4 pb-5"
        ><h1 class="headline grey--text">{{$t('AffilatesManagement')}}</h1></v-col
      >
      <v-row dense class="mx-2">
        <v-btn class="primary my-4" dark @click="openAfDialog"
          >{{$t('AddAffilate')}}</v-btn
        >
        <v-btn
          outlined
          @click="$apollo.queries.affilates.refetch()"
          class="mt-4 mx-2"
        >
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="affilates"
        :search="search"
        :loading="loadingContent"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          <p class="pt-2">
            {{ parseDate(item.createdAt) }}
          </p>
        </template>
        <template v-slot:[`item.targetAge`]="{ item }">
          <p class="pt-2">{{ item.targetAgeStart }}-{{ item.targetAgeEnd }}</p>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-row>
            <v-btn icon color="blue mx-2 my-2 darken-3" @click="update(item)"
              ><v-icon>mdi-update</v-icon>
            </v-btn>
            <div class="mx-2"></div>
            <v-btn icon color="red mx-2 my-2 darken-3" @click="deleteP(item)"
              ><v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-row>
        </template>
        <template v-slot:[`item.link`]="{ item }">
          <v-row>
            <v-btn icon color="blue mx-2 my-2 darken-3" @click="copy(item)"
              ><v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </v-row>
        </template>
        <template v-slot:[`item.packageName`]="{ item }">
          <p class="pt-2">
            {{ item.packageName }}
          </p>
        </template>
        <template v-slot:[`item.owner`]="{ item }">
          <v-chip @click="setAowner(item)">
            <p class="pt-3">
              {{ item.owned_by ? item.owned_by.fullName : "-" }}
            </p>
          </v-chip>
        </template>
        <template v-slot:[`item.supervisor`]="{ item }">
          <v-switch
            @change="updateAfStat(item, item.supervisor || false)"
            v-model="item.supervisor"
            :value="item.supervisor"
          ></v-switch>
        </template>

        <template v-slot:[`item.ikm`]="{ item }">
          <v-switch
            @change="updateIkmStat(item, item.ikm || false)"
            v-model="item.ikm"
            :value="item.ikm"
          ></v-switch>
        </template>
        <template v-slot:[`item.user`]="{ item }">
          <p class="pt-2">
            {{ item.user.base_profile.firstName }}

            {{ item.user.base_profile.lastName }}
            <br />
            <a :href="'https://flirttool.com/@' + item.user.username">
              @{{ item.user.username }}
            </a>
          </p>
        </template>
      </v-data-table>
    </v-container>
  </v-app>
</template>
<script>
import {
  ADD_AFFILATE,
  AFFILATES,
  REMOVE_AFFL,
  SET_IKM_STAT,
  SET_IKM_SUPERVISOR,
  SET_SUPERVISOR_STAT,
  UPDATE_AFFILATE,
} from "../queries/core";
export default {
  data: () => ({
    text: "",
    updateDialog: false,
    validowner: false,
    setOwner: false,
    newAffilateDialog: false,
    headers: [
      {
        text: this.$t('FullName'),
        align: "start",
        filterable: false,
        value: "fullName",
      },
      { text: this.$t('AffilateId'), name: "affilateId", value: "affilateId" },
      { text: this.$t('Phone'), value: "phone" },
      { text: this.$t('Target') , value: "targetAge" },
      { text: "Email", name: "email", value: "email" },
      { text: this.$t('BankAccount'), name: "bankAccount", value: "bankAccount" },
      { text: this.$t('CreatedDate'), name: "createdAt", value: "createdAt" },
      { text: this.$t('CreditPercent'), name: "creditPercent", value: "creditPercent" },
      { text: this.$t('supervisor'), name: "supervisor", value: "supervisor" },
      { text: "IS IKM ?", name: "ikm", value: "ikm" },
      { text: this.$t('owner'), name: "owner", value: "owner" },
      {
        text: "Forward Link",
        name: "link",
        value: "link",
        align: "start",
      },
      { text: this.$t('Actions'), name: "actions", value: "actions" },
    ],
    search: "",
    affilates: [],
    loadingContent: false,
    fullName: "",
    email: "",
    phone: "",
    zip: null,
    affAcc: null,
    street: "",
    creditPercent: null,
    serviceType: [],
    paymentMethod: [],
    paymentMethods: ["bank", "paypal"],
    state: "",
    af: "",
    targetGroup: [],
    targetAgeStart: null,
    targetAgeEnd: null,
    serviceTypes: [],
    bankAccount: "",
    country: "",
    loadingSave: false,
    selectedUAf: null,
    owner: null,
  }),
  apollo: {
    affilates: {
      query: AFFILATES,
      fetchPolicy: "network-only",
    },
  },
  methods: {
    updateOwner() {
      if (!this.owner) {
        this.showToaster("Owner Id can't be empty !!", "error");
      }
      this.$apollo
        .mutate({
          mutation: SET_IKM_SUPERVISOR,
          variables: {
            affilate: this.selectedUAf.id,
            owner: this.owner,
          },
        })
        .then(({ data }) => {
          if (data) {
            this.showToaster(
              "Affilate Owner updated succesfully !!!",
              "success"
            );
            this.$apollo.queries.affilates.refetch();
          }
        })
        .catch((e) => {
          console.clear();
          console.log(e, "dawg");
          this.showToaster("Error updating owner please check ID", "error");
        });
    },
    setAowner(item) {
      this.setOwner = true;
      this.selectedUAf = item;
    },
    updateIkmStat(aff, stat) {
      this.$apollo
        .mutate({
          mutation: SET_IKM_STAT,
          variables: {
            affilate: aff.id,
            stat: stat,
          },
        })
        .then(() => {
          this.$apollo.queries.affilates.refetch();
          this.showToaster("Supervisor status succesfully !!!", "success");
        });
    },
    updateAfStat(aff, stat) {
      this.$apollo
        .mutate({
          mutation: SET_SUPERVISOR_STAT,
          variables: {
            affilate: aff.id,
            stat: stat,
          },
        })
        .then(() => {
          this.$apollo.queries.affilates.refetch();
          this.showToaster("Supervisor status succesfully !!!", "success");
        });
    },
    async copy(item) {
      const link = "https://flirttool.com/register?af=" + item.affilateId;
      await navigator.clipboard.writeText(link);
      this.showToaster("Link copied successfully !!!");
    },
    deleteP(item) {
      this.$apollo
        .mutate({
          mutation: REMOVE_AFFL,
          variables: {
            input: JSON.stringify(item.id),
          },
        })
        .then(() => {
          this.showToaster("Affilate removed successfully !!!", "success");
          this.$apollo.queries.affilates.refetch();
        })
        .catch(() => {
          this.showToaster("Error removing affilate", "error");
        });
    },
    update(item) {
      this.af = item.id;
      this.updateDialog = true;
      this.fullName = item.fullName;
      this.email = item.email;
      this.phone = item.phone;
      this.country = item.address.country;
      this.state = item.address.state;
      this.street = item.address.street;
      this.zip = item.address.zip;
      this.creditPercent = item.creditPercent;
      this.paymentMethod = item.paymentMethod;
      this.serviceTypes = item.serviceTypes;
      this.targetGroup = item.targetGroups;
      this.bankAccount = item.bankAccount;
      this.targetAgeEnd = item.targetAgeEnd;
      this.targetAgeStart = item.targetAgeStart;
    },
    openAfDialog() {
      this.newAffilateDialog = true;
      this.$refs.form.reset();
    },
    saveChanges() {
      if (this.$refs.form.validate()) {
        this.addAffilate();
      }
    },
    saveUpdateChanges() {
      if (this.$refs.form.validate()) {
        this.updateAffilate();
      }
    },
    showToaster(message, type = "info") {
      const data = {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      };
      switch (type) {
        case "info":
          this.$toast.info(message, data);
          break;
        case "success":
          this.$toast.success(message, data);
          break;
        case "warning":
          this.$toast.warning(message, data);
          break;
        case "error":
          this.$toast.error(message, data);
          break;

        default:
          this.$toast.info(message, data);
          break;
      }
    },
    updateAffilate() {
      this.$apollo
        .mutate({
          mutation: UPDATE_AFFILATE,
          variables: {
            input: {
              fullName: this.fullName,
              phone: this.phone,
              email: this.email,
              af: this.af,
              country: this.country,
              zip: parseInt(this.zip),
              creditPercent: parseInt(this.creditPercent),
              paymentMethod: this.paymentMethod,
              street: this.street,
              state: this.state,
              targetGroup: this.targetGroup,
              targetAgeStart: parseInt(this.targetAgeStart),
              targetAgeEnd: parseInt(this.targetAgeEnd),
              serviceTypes: this.serviceTypes,
              bankAccount: this.bankAccount,
            },
          },
        })
        .then(() => {
          this.updateDialog = false;
          this.showToaster("Affilate Updated Sccesfully", "success");
          this.$apollo.queries.affilates.refetch();
        })
        .catch((e) => {
          console.error("Error adding affilate: ", e);
        });
    },
    addAffilate() {
      this.$apollo
        .mutate({
          mutation: ADD_AFFILATE,
          variables: {
            input: {
              fullName: this.fullName,
              phone: this.phone,
              email: this.email,
              affAcc: this.affAcc,
              country: this.country,
              zip: parseInt(this.zip),
              creditPercent: parseInt(this.creditPercent),
              paymentMethod: this.paymentMethod,
              street: this.street,
              state: this.state,
              targetGroup: this.targetGroup,
              targetAgeStart: parseInt(this.targetAgeStart),
              targetAgeEnd: parseInt(this.targetAgeEnd),
              serviceTypes: this.serviceTypes,
              bankAccount: this.bankAccount,
            },
          },
        })
        .then(({ data }) => {
          this.newAffilateDialog = false;
          this.showToaster("Affilate Added Sccesfully", "success");
          this.$apollo.queries.affilates.refetch();
          console.warn(data);
        })
        .catch((e) => {
          console.error("Error adding affilate: ", e);
        });
    },
    parseDate(date) {
      return new Date(parseInt(date)).toDateString();
    },
  },
  created() {
    this.$apollo.queries.affilates.refetch();
  },
};
</script>
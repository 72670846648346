<template>
	<v-container fluid>
		<h2
			class="py-6 d-none"
			style="font-weight: 800; font-size: 35px; color: #515151"
		>
			<v-icon size="60">mdi-access-point-check</v-icon>
			Chat Live monitoring
		</h2>
		<!--second row-->
		<v-row v-if="getDailyConversationsLive.length != 0">
			<v-card outlined elevation="0" class="mt-0">
				<v-list two-line color="rgba(201, 228, 252, 0.3)">
					<template v-for="(item, index) in getDailyConversationsLive">
						<v-subheader :key="index" v-if="index == 0">
							Live messages today
						</v-subheader>
						<v-divider :key="index"></v-divider>
						<v-list-item :key="index">
							<v-list-item-avatar tile size="7%">
								<div>
									<v-badge
										v-if="item.fresh"
										bordered
										color="green"
										icon="mdi-lock"
										overlap
										class="mt-2 mx-2"
									>
										<img
											:src="getURL(item.m_one.profilePic)"
											style="height: 50%; width: 100%"
										/>
									</v-badge>
									<img
										v-else
										:src="getURL(item.m_one.profilePic)"
										style="height: 50%; width: 100%"
									/>
									<p
										class="pt-2"
										v-text="
											`${item.m_one.base_profile.firstName} ${item.m_one.base_profile.lastName}`
										"
									></p>
								</div>
							</v-list-item-avatar>
							<router-link :to="'/dialog-explorer/'+item.convo+''">
								<v-icon class="pr-3" size="35" color="green lighten-2"
									>mdi-arrow-left-right</v-icon
								>
							</router-link>
							<v-list-item-avatar tile size="7%">
								<div>
									<img
										:src="getURL(item.m_two.profilePic)"
										style="height: 50%; width: 100%"
									/>
									<p
										class="pt-2"
										v-text="
											`${item.m_two.base_profile.firstName} ${item.m_two.base_profile.lastName}`
										"
									></p>
								</div>
							</v-list-item-avatar>

							<v-list-item-content class="px-3">
								<p
									v-html="`${item.messageStamp} <b>(${item.moderator.pin})</b>`"
								></p>
								<v-list-item-title
									class="text--lg"
									v-html="item.body"
								></v-list-item-title>
								<v-list-item-subtitle
									v-html="item.subtitle"
								></v-list-item-subtitle>
							</v-list-item-content>
						</v-list-item>
					</template>
				</v-list>
			</v-card>
		</v-row>
		<v-col v-else>
			<PageLoader v-if="$apollo.loading"/>
		</v-col>
		<!--second row-->
	</v-container>
</template>

<script>
import { GET_DAILY_LIVE_MESSAGES, NEW_OUT_CAPTURED } from '../queries/core'
import PageLoader from '@/components/PageLoader.vue'
const PRODUCTION = true
export default {
	components:{ 
		PageLoader
	},
	apollo: {
		$subscribe: {
			newOutCaptured: {
				query: NEW_OUT_CAPTURED,
				result({ data: { newOutCaptured } }) {
					console.log(newOutCaptured)
					console.log("******************************")
					console.log(this.getDailyConversationsLive)
					console.log("******************************")
					// this.getDailyConversationsLive.splice(0, 0, newOutCaptured);
					this.getDailyConversationsLive.unshift(newOutCaptured)
					console.warn(newOutCaptured)
				}
			}
		},
		getDailyConversationsLive: {
			query: GET_DAILY_LIVE_MESSAGES
		}
	},
	methods: {
		getURL(url) {
			const domain = PRODUCTION ? "https://flirttool.com/media/" + url : "http://" + location.hostname + ":4000/media/" + url;
			// const domain = "https://testflirttool.com/media/" + url;
			return domain;
		}
	},
	created() {
		this.$apollo.getDailyConversationsLive.refetch()
	},
	data: () => ({
		getDailyConversationsLive: [],
		messages: [
			{
				avatar: 'https://avatars0.githubusercontent.com/u/9064066?v=4&s=460',
				name: 'Nilshan',
				title: 'John Leide',
				excerpt: 'Text message goes here',
				lorem: 'Text message goes here',
			},


		],

	}),
}
</script>